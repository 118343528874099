import React from "react"
import { Card, Table } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { ColumnsType } from "antd/lib/table"
import { IDeliveryCase } from "@domain/version2/DeliveryCase"
import { find } from "lodash"
import { formatDateFull } from "@util/Common"

export const DeliveryCase = () => {
    const listDeliveryCases = useAppSelector((state) => state.orderDetailVersion2.listDeliveryCases)
    const deliveryCaseReason = useAppSelector((state) => state.categoriesVersion2.deliveryCaseReason)
    const deliveryCaseStatus = useAppSelector((state) => state.categoriesVersion2.deliveryCaseStatus)
    const listAssignees = useAppSelector((state) => state.deliveryCase.deliveryCaseAssignees)

    const columns: ColumnsType<IDeliveryCase> = [
        {
            title: trans("delivery_case.code"),
            dataIndex: "code",
            key: "code",
            align: "left",
            width: "15%",
        },

        {
            title: trans("delivery_case.assignee"),
            dataIndex: "assignee",
            key: "assignee",
            align: "left",
            width: "15%",
            render: (assignee: string) => <p className="mb-0">{find(listAssignees, { username: assignee })?.name}</p>,
        },
        {
            title: trans("order_detail.reason"),
            dataIndex: "reason",
            key: "reason",
            width: "25%",
            render: (reason: string) => <p className="mb-0">{find(deliveryCaseReason, { code: reason })?.name}</p>,
        },
        {
            title: trans("order_detail.note"),
            dataIndex: "note",
            key: "note",
            width: "15%",
        },
        {
            title: trans("order_list.created_at"),
            dataIndex: "createdAt",
            align: "center",
            key: "createdAt",
            render: (value: string) => formatDateFull(value),
            width: "15%",
        },
        {
            title: trans("payment_statement.status"),
            dataIndex: "status",
            key: "status",
            width: "15%",
            render: (status: string) => <p className="mb-0">{find(deliveryCaseStatus, { code: status })?.name}</p>,
        },
    ]

    return (
        <div className="mt-3">
            <Card>
                <Table
                    scroll={{
                        x: true,
                    }}
                    columns={columns}
                    dataSource={listDeliveryCases || []}
                    loading={false}
                    locale={{
                        emptyText: trans("table.empty"),
                    }}
                />
            </Card>
        </div>
    )
}
