import { ICancelAssignment, ICancelAssignRequest, IHistoryUpdate } from "@domain/version2/CancelAssign"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class CancelAssignApi {
    static getCancelAssignment(): Promise<AxiosResponse<ICancelAssignment[]>> {
        return apiClient.get(`/cancel-assignments`)
    }
    static patchCancelAssignment(body: ICancelAssignRequest[]) {
        return apiClient.patch(`/cancel-assignments`, body)
    }
    static getHistoryCancelAssignment(params:{settingType: string, page: number, pageSize: number}): Promise<AxiosResponse<IHistoryUpdate[]>> {
        return apiClient.get(`/setting-histories`, {
            params,
        })
    }
}
