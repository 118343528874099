import { COUNTRY_CODE } from "@config/constant"
import { ICourier } from "@domain/version2/Country"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, resetDataArea, resetDataCity, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { trans } from "@resources/localization"
import { numberParser } from "@util/Common"
import { Col, DatePicker, Form, Input, Row, Select, Empty } from "antd"
import { find, isEmpty } from "lodash"
import moment from "moment"
import React from "react"
type Props = {
    formRef: any
}
export const AddressCreate: React.FC<Props> = ({ formRef }) => {
    const dispatch: any = useAppDispatch()
    const detailContact = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    const countries = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const provinces = useAppSelector((state) => state.countriesVersion2.provincesSuggest)
    const couriers = useAppSelector((state) => state.countriesVersion2.couriersSuggest)
    const districts = useAppSelector((state) => state.countriesVersion2?.districtsSuggest)
    const areas = useAppSelector((state) => state.countriesVersion2?.areasSuggest)
    const cities = useAppSelector((state) => state.countriesVersion2.citiesSuggest)
    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name="deliveryDate"
                    label={trans("contact-detail.delivery_at")}>
                    <DatePicker
                        placeholder={trans("contact-detail.delivery_pl")}
                        format="DD/MM/YYYY"
                        showTime={false}
                        allowClear={false}
                        disabledDate={(current) => current < moment().startOf("day")}
                    />
                </Form.Item>
            </Col>

            <Col span={24}>
                <p>
                    {trans("contact.country")}: {find(countries, { code: detailContact?.countryCode })?.name}
                </p>
            </Col>
            <Col span={24}>
                <Form.Item
                    name="courier"
                    label={trans("contact-detail.courier")}
                    rules={[
                        {
                            required: true,
                            message: trans("message.required"),
                        },
                    ]}>
                    <Select
                        placeholder={trans("contact-detail.validate_courier")}
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        optionFilterProp="children"
                        notFoundContent={<Empty />}
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        }}>
                        {couriers?.map((item: ICourier) => (
                            <Select.Option
                                key={item?.id}
                                value={item?.courierCode}>
                                {item?.courierName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    rules={
                        detailContact?.countryCode ===COUNTRY_CODE.MALAYSIA
                            ? [
                                  {
                                      required: true,
                                      message: trans("message.required"),
                                  },
                              ]
                            : []
                    }
                    label={trans("order.postal_code")}
                    name="postalCode">
                    <Input
                        onChange={(e) => {
                            formRef.setFieldsValue({
                                postalCode: e?.target?.value ? numberParser(e?.target?.value) : "",
                            })
                        }}
                        maxLength={detailContact?.countryCode ===COUNTRY_CODE.MALAYSIA ? 5 : 20}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label={trans("contact-detail.province")}
                    name="provinceCode"
                    rules={[
                        {
                            required: true,
                            message: trans("message.required"),
                        },
                    ]}>
                    <Select
                        placeholder={trans("order_list.district_placeholder")}
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        optionFilterProp="children"
                        notFoundContent={<Empty />}
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        }}
                        onChange={(e) => {
                            dispatch(resetDataDistrict([]))
                            dispatch(resetDataCity([]))
                            dispatch(resetDataArea([]))
                            formRef.setFieldsValue({
                                cityCode: "",
                                districtCode: "",
                                wardCode: "",
                            })
                            if (e) {
                                dispatch(getCitySuggest(e))
                            }
                        }}>
                        {provinces?.map((item) => (
                            <Select.Option
                                key={item?.id}
                                value={item?.code}>
                                {item?.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) => pre.provinceCode !== next.provinceCode}>
                    {({ getFieldValue }) => (
                        <Form.Item
                            label={detailContact?.countryCode === COUNTRY_CODE.VIETNAM ? trans("contact-detail.district") : trans("order_list.district")}
                            name="cityCode"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select
                                placeholder={
                                    detailContact?.countryCode === COUNTRY_CODE.VIETNAM
                                        ? trans("contact-detail.district_pl")
                                        : trans("order_list.city_placeholder")
                                }
                                showSearch
                                getPopupContainer={(trigger) => trigger.parentNode}
                                notFoundContent={<Empty />}
                                disabled={!getFieldValue("provinceCode")}
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                onChange={(e) => {
                                    dispatch(resetDataArea([]))
                                    dispatch(resetDataDistrict([]))
                                    formRef.setFieldsValue({
                                        districtCode: "",
                                        wardCode: "",
                                    })
                                    if (e) {
                                        dispatch(getDistrictSuggest(e))
                                    }
                                }}>
                                {cities?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form.Item>
            </Col>
            {!isEmpty(districts) && (
                <Col span={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.cityCode !== next.cityCode}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                label={detailContact?.countryCode === COUNTRY_CODE.VIETNAM ? trans("contact_activity.ward") : trans("contact-detail.district")}
                                name="districtCode"
                                rules={
                                    detailContact?.countryCode !== COUNTRY_CODE.MALAYSIA
                                        ? [
                                              {
                                                  required: true,
                                                  message: trans("message.required"),
                                              },
                                          ]
                                        : []
                                }>
                                <Select
                                    placeholder={
                                        detailContact?.countryCode === COUNTRY_CODE.VIETNAM
                                            ? trans("order_list.ward_placeholder")
                                            : trans("contact-detail.district_pl")
                                    }
                                    showSearch
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    notFoundContent={<Empty />}
                                    disabled={!getFieldValue("cityCode")}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    onChange={(e) => {
                                        dispatch(resetDataArea([]))
                                        formRef.setFieldsValue({
                                            wardCode: "",
                                        })
                                        if (e) {
                                            dispatch(getAreaSuggest(e))
                                        }
                                    }}>
                                    {districts?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
            )}
            {!isEmpty(areas) ? (
                <Col span={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.districtCode !== next.districtCode || pre.cityCode !== next.cityCode}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                label={trans("contact-detail.area")}
                                name="wardCode">
                                <Select
                                    placeholder={trans("placeholder.select_area")}
                                    showSearch
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    disabled={!getFieldValue("districtCode") && !getFieldValue("cityCode")}
                                    optionFilterProp="children"
                                    notFoundContent={<Empty />}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}>
                                    {areas?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
            ) : (
                <Col span={24}>
                    <Form.Item
                        label={trans("contact-detail.area")}
                        name="wardCode">
                        <Input
                            placeholder={trans("contact-detail.area")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    wardCode: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
            )}
            <Col span={24}>
                <Form.Item
                    label={trans("contact-detail.address_detail")}
                    name="address"
                    rules={[
                        {
                            required: true,
                            message: trans("message.required"),
                        },
                    ]}>
                    <Input.TextArea
                        rows={4}
                        placeholder={trans("contact-detail.address_pl")}
                        onBlur={(e) => {
                            formRef.setFieldsValue({
                                address: e.target.value.trim(),
                            })
                        }}
                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}
