import UserApi from "@api/version2/UserApi"
import { IUserAutocomplete } from "@domain/version2/User"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    listUserAutocomplete: IUserAutocomplete[]
}

const initState: State = {
    listUserAutocomplete: [],
}

export const callGetUserAutocomplete = createAsyncThunk("USER.GET_AUTOCOMPLETE", async (keyword: string, thunkApi) => {
    try {
        return await UserApi.getUsersAutocomplete(keyword)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const resetListUserAutocomplete = createAction<any>("USER.RESET_LIST_USER")

export const userReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetUserAutocomplete.pending, (state) => {
            state.listUserAutocomplete = []
        })
        .addCase(callGetUserAutocomplete.rejected, (state) => {
            state.listUserAutocomplete = []
        })
        .addCase(callGetUserAutocomplete.fulfilled, (state, { payload }) => {
            state.listUserAutocomplete = payload.data
        })

    builder.addCase(resetListUserAutocomplete, (state, action: PayloadAction<any>) => {
        state.listUserAutocomplete = action.payload
    })
})
