import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Row, Table, Pagination, Button, Popover, Modal, message, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { AddSkuCombo } from "./AddSkuCombo"
import SecurityService from "@util/SecurityService"
import { COMBO_SCOPE, TELESALES_COPE } from "@config/permission"
import { callDeleteCombo, callGetCombosInGroup } from "@reducer/version2/telesales-group-detail.reducer"
import { filter, find } from "lodash"
import { formatDateFull } from "@util/Common"
import { ISkuComboOfTelesaleGroup } from "@domain/version2/SkuCombo"
import { callGetComboCategories } from "@reducer/version2/categories.reducer"

export const SkuCombo: React.FC = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const [open, setOpen] = useState<boolean>(false)
    const total = useAppSelector((state) => state.telesalesGroupDetail.paginationCombo.total)
    const currentPage = useAppSelector((state) => state.telesalesGroupDetail.paginationCombo.currentPage)
    const totalPage = useAppSelector((state) => state.telesalesGroupDetail.paginationCombo.totalPage)
    const pageSize = useAppSelector((state) => state.telesalesGroupDetail.paginationCombo.pageSize)
    const combos = useAppSelector((state) => state.telesalesGroupDetail.combosInGroup)
    const loadingList = useAppSelector((state) => state.telesalesGroupDetail.loadingCombo)

    const comboCategories = useAppSelector((state) => state.categoriesVersion2.comboCategories)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        dispatch(callGetComboCategories())
    }, [dispatch])

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            callGetCombosInGroup({
                code: id || "",
                params: {
                    ...params,
                    page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                },
            })
        )
    }, [dispatch, searchParams])

    const confirmDeleteCombo = (record: ISkuComboOfTelesaleGroup) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <ExclamationCircleOutlined />,
            okText: trans("button.delete"),
            cancelText: trans("button.cancel"),
            onOk: () => {
                dispatch(
                    callDeleteCombo({
                        code: id || "",
                        body: {
                            comboCodes: [record?.comboCode || ""],
                        },
                    })
                ).then((result: any) => {
                    if (result?.payload?.status === 200) {
                        message.success(trans("message.success"))
                        const params = Object.fromEntries(searchParams.entries())
                        dispatch(
                            callGetCombosInGroup({
                                code: id || "",
                                params: {
                                    ...params,
                                    page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                },
                            })
                        )
                    } else {
                        message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                    }
                })
            },
        })
    }
    const columns: ColumnsType<ISkuComboOfTelesaleGroup> = [
        {
            title: trans("skuCombo.code"),
            dataIndex: ["combo", "code"],
            key: "code",
            width: 120,
            render: (code: string) =>
                SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_VIEW) || SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_VIEW_ALL) ? (
                    <Link to={`/skuCombo/${code}`}>{code}</Link>
                ) : (
                    code
                ),
        },
        {
            title: trans("skuCombo.name"),
            dataIndex: ["combo", "name"],
            key: "name",
            width: 150,
        },
        {
            title: trans("product.category"),
            dataIndex: ["combo", "categoryCode"],
            key: "categoryCode",
            width: 150,
            render: (categoryCode: string) => find(comboCategories, { code: categoryCode })?.name || "",
        },
        {
            title: trans("table.added_by"),
            dataIndex: "createdBy",
            key: "createdBy",
            width: 100,
        },
        {
            title: trans("table.added_at"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 120,
            render: (date: string) => (date ? formatDateFull(date) : ""),
        },
        {
            title: trans("table.action"),
            dataIndex: SecurityService.can(TELESALES_COPE.GROUP_COMBO_UPDATE) ? "action" : "disabledAction",
            key: SecurityService.can(TELESALES_COPE.GROUP_COMBO_UPDATE) ? "action" : "disabledAction",
            width: 60,
            render: (action: string, record: ISkuComboOfTelesaleGroup) =>
                SecurityService.can(TELESALES_COPE.GROUP_COMBO_UPDATE) && (
                    <Button
                        type="link"
                        icon={<i className="fa-solid fa-trash"></i>}
                        onClick={() => confirmDeleteCombo(record)}
                    />
                ),
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(TELESALES_COPE.GROUP_COMBO_UPDATE) && (
                    <Popover
                        content={
                            <AddSkuCombo
                                setClose={() => {
                                    const params = Object.fromEntries(searchParams.entries())
                                    dispatch(
                                        callGetCombosInGroup({
                                            code: id || "",
                                            params: {
                                                ...params,
                                                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                                pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                            },
                                        })
                                    )
                                    setOpen(false)
                                }}
                            />
                        }
                        title={trans("skuCombo.add")}
                        placement="left"
                        trigger="click"
                        open={open}
                        onOpenChange={() => setOpen((old) => !old)}>
                        <Button type="primary">{trans("skuCombo.add")}</Button>
                    </Popover>
                )
            }>
            <div className="">
                <Table
                    scroll={{
                        x: true,
                    }}
                    loading={loadingList}
                    columns={filter(columns, (iFil: any) => iFil?.dataIndex !== "disabledAction")}
                    dataSource={combos}
                    rowKey={(record) => record.id}
                    pagination={false}
                    locale={{
                        emptyText: trans("table.empty"),
                    }}
                />
            </div>
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
