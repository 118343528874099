import { IUserAutocomplete } from "@domain/version2/User"
import apiClient from "@util/ApiClient"

export default class UserApi {
    static getCurrentUser() {
        return apiClient.get("/users/current")
    }
    static getUsersAutocomplete(keyword: string): Promise<{ data: IUserAutocomplete[] }> {
        return apiClient.get(`/users`, {
            params: {
                keyword,
            },
        })
    }
}
