import { ICancelAssignment, ICancelAssignRequest } from "@domain/version2/CancelAssign"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetCancelAssignment, callGetHistoryCancel, callUpdateCancelAssign, changeVisibleModalHistory } from "@reducer/version2/cancelAssign.reducer"
import { callGetContactEventStatus } from "@reducer/version2/categories.reducer"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, InputNumber, message, Row, Space, Switch, Typography } from "antd"
import { first, isEmpty, isEqual } from "lodash"
import React, { useEffect, useState } from "react"
import SecurityService from "@util/SecurityService"
import { SETTING_SCOPE } from "@config/permission"
import { HistoryCancelAssignment } from "./HistoryCancelAssignment"
import { PAGINATION } from "@config/constant"
import { formatDateFull } from "@util/Common"

export const CancelAssignment: React.FC = () => {
    const cancelAssignments = useAppSelector((state) => state.settingCancel.cancelAssignments)
    const loadingUpdate = useAppSelector((state) => state.settingCancel.loadingUpdate)
    const loadingCancel = useAppSelector((state) => state.settingCancel.loadingCancel)
    const visibleHistory = useAppSelector((state) => state.settingCancel.isVisibleHistory)
    const histories = useAppSelector((state) => state.settingCancel.historyCancels)
    const [oldData, setOldData] = useState<any>([])
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(callGetContactEventStatus())
        dispatch(callGetCancelAssignment())
        dispatch(
            callGetHistoryCancel({
                settingType: "cancel_assignment",
                page: PAGINATION.DEFAULT_CURRENT_PAGE,
                pageSize: 5,
            })
        )
    }, [dispatch])
    useEffect(() => {
        if (!isEmpty(cancelAssignments)) {
            const oldData = cancelAssignments?.map((item: ICancelAssignment) => ({
                code: item?.code,
                name: item?.conditionOption?.name,
                hours: item?.hours,
                isActive: item?.isActive,
            }))
            formRef.setFieldsValue({
                cancelAssign: oldData,
            })
            setOldData(oldData)
        } else {
            setOldData([])
            formRef.setFieldsValue({
                cancelAssign: [],
            })
        }
    }, [cancelAssignments])
    const onUpdateCancelAssignment = (values: any) => {
        const requestData = values?.cancelAssign?.map((item: ICancelAssignRequest) => ({
            code: item?.code,
            hours: item?.hours,
            isActive: item?.isActive,
        }))
        dispatch(callUpdateCancelAssign([...requestData])).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(callGetCancelAssignment())
                dispatch(
                    callGetHistoryCancel({
                        settingType: "cancel_assignment",
                        page: PAGINATION.DEFAULT_CURRENT_PAGE,
                        pageSize: 5,
                    })
                )
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <>
            <Card className="space-layout">
                <Row>
                    <Col
                        xs={24}
                        sm={24}
                        md={8}>
                        <Typography.Text className="font-medium">{trans("setting.last_update")}:</Typography.Text>
                        <Typography.Text className="ml-2">
                            {first(histories)?.createdAt ? formatDateFull(first(histories)?.createdAt || "") : ""}
                        </Typography.Text>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={8}>
                        <Typography.Text className="font-medium">{trans("setting.update_by")}:</Typography.Text>
                        <Typography.Text className="ml-2">{first(histories)?.createdBy}</Typography.Text>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={8}>
                        <p
                            className="text-blue-400 m-0 cursor-pointer font-medium"
                            onClick={() => dispatch(changeVisibleModalHistory(true))}>
                            {trans("setting.update_history")}
                        </p>
                    </Col>
                </Row>
            </Card>
            <Card
                className="space-layout mt-4"
                loading={loadingCancel}>
                <div className="grid grid-cols-4 gap-x-4 px-4">
                    <div className="col-span-2 font-medium">{trans("setting.action_name")}</div>
                    <div className="col-span-1 font-medium">{trans("setting.after_day")}</div>
                    <div className="col-span-1 font-medium">{trans("campaign.status")}</div>
                </div>
                <Form
                    form={formRef}
                    onFinish={onUpdateCancelAssignment}>
                    <Form.List name="cancelAssign">
                        {(fields) => (
                            <>
                                <div className="mt-4">
                                    {fields.map(({ name, ...restField }) => (
                                        <div
                                            key={name}
                                            className="grid grid-cols-4 gap-x-4 px-4 overflow-x-auto">
                                            <div className="col-span-2">
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.cancelAssign !== next.cancelAssign}>
                                                    {({ getFieldValue }) => <Typography.Text>{getFieldValue(["cancelAssign", name, "name"])}</Typography.Text>}
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "hours"]}
                                                    rules={[{ required: true, message: trans("message.required") }]}>
                                                    <InputNumber
                                                        placeholder={trans("setting.after_day")}
                                                        disabled={!SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_UPDATE)}
                                                        min={1}
                                                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1 flex">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "isActive"]}
                                                    valuePropName="checked">
                                                    <Switch disabled={!SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_UPDATE)} />
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.cancelAssign !== next.cancelAssign}>
                                                    {({ getFieldValue }) => (
                                                        <Typography.Text className="mt-1.5 ml-2">
                                                            {getFieldValue(["cancelAssign", name, "isActive"])
                                                                ? trans("user_list.active")
                                                                : trans("user_list.inactive")}
                                                        </Typography.Text>
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </Form.List>
                    <Row justify="end">
                        <Space>
                            <Button
                                type="default"
                                loading={loadingUpdate}
                                onClick={() => {
                                    if (!isEmpty(cancelAssignments)) {
                                        formRef.setFieldsValue({
                                            cancelAssign: cancelAssignments?.map((item: ICancelAssignment) => ({
                                                code: item?.code,
                                                name: item?.conditionOption?.name,
                                                hours: item?.hours,
                                                isActive: item?.isActive,
                                            })),
                                        })
                                    } else {
                                        formRef.resetFields()
                                    }
                                }}
                                disabled={
                                    !SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_CREATE) &&
                                    !SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_UPDATE)
                                }>
                                {trans("button.cancel")}
                            </Button>
                            <Form.Item
                                noStyle
                                shouldUpdate={(pre, next) => pre.cancelAssign !== next.cancelAssign}>
                                {({ getFieldValue }) => (
                                    <Button
                                        type="primary"
                                        loading={loadingUpdate}
                                        disabled={
                                            (!SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_CREATE) &&
                                                !SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_UPDATE)) ||
                                            isEqual(oldData, getFieldValue("cancelAssign"))
                                        }
                                        htmlType="submit">
                                        {trans("button.save")}
                                    </Button>
                                )}
                            </Form.Item>
                        </Space>
                    </Row>
                </Form>
            </Card>
            {visibleHistory && <HistoryCancelAssignment />}
        </>
    )
}
