import { IReport, IReportQuery, IReportTelesales } from "@domain/version2/Report";
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios";

export default class ReportApi {

    static getReportCallStatus(params: IReportQuery): Promise<AxiosResponse<IReport[]>> {
        return apiClient.get("reports/call-performances", {
            params,
        })
    }
    static getReportTelesales(params: IReportQuery): Promise<AxiosResponse<IReportTelesales[]>> {
        return apiClient.get("reports/telesales-performances", {
            params,
        })
    }
}
