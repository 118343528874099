import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetActivityHistory } from "@reducer/version2/contact-detail.reducer"
import { trans } from "@resources/localization"
import { formatDateFull, renderHistory } from "@util/Common"
import { Card, Timeline } from "antd"
import { find, isEmpty, templateSettings } from "lodash"
import React, { useEffect, useMemo } from "react"

type Props = {
    currentKey: string
}
export const ActionHistory: React.FC<Props> = ({ currentKey }) => {
    const contactDetail = useAppSelector((state) => state.contactDetailVersion2?.contactDetail)
    const loadingActivity = useAppSelector((state) => state.contactDetailVersion2?.loadingActivity)
    const statusEvents = useAppSelector((state) => state.categoriesVersion2.contactEventStatus)
    const contactStatus = useAppSelector((state) => state.categoriesVersion2.contactStatus)
    const activityHistories = useAppSelector((state) => state.contactDetailVersion2?.activityHistories)
    console.log("🚀 ~ file: index.tsx:19 ~ activityHistories:", activityHistories)
    const dispatch = useAppDispatch()
    templateSettings.interpolate = /{{([\s\S]+?)}}/g

    const customActivity = useMemo(
        () =>
            activityHistories?.map((item) => {
                return {
                    ...item,
                    ...(!isEmpty(item?.change) && {
                        change: item?.change?.map((iChange: any) => ({
                            ...iChange,
                            key: trans(`contact_activity.${iChange.key}`),
                            left: iChange?.left || "-",
                            right: iChange?.right || "-",
                            ...(iChange?.key === "status" && {
                                left: find(contactStatus, { code: iChange?.left })?.description || "-",
                                right: find(contactStatus, { code: iChange?.right })?.description || "-",
                            }),
                            ...(iChange?.key === "isSpam" && {
                                left: iChange?.key !== undefined ? (iChange?.left === false ? `${trans("contact.blanks")}` : `${trans("contact.spam")}`) : "-",
                                right:
                                    iChange?.key !== undefined ? (iChange?.right === false ? `${trans("contact.blanks")}` : `${trans("contact.spam")}`) : "-",
                            }),
                        })),
                    }),
                    ...(!isEmpty(item?.event) && {
                        event: {
                            ...item?.event,
                            status: find(statusEvents, { code: item?.event?.status })?.name?.toLocaleUpperCase(),
                        },
                    }),
                }
            }),
        [activityHistories]
    )
    useEffect(() => {
        if (currentKey === "activity") {
            dispatch(callGetActivityHistory(contactDetail?.id || ""))
        }
    }, [currentKey])
    return (
        <Card className="space-y">
            <Timeline pending={loadingActivity}>
                {customActivity?.map((iActivity, index) => (
                    <Timeline.Item
                        key={index}
                        color="red">
                        {iActivity?.type === "PROPERTY_CHANGE"
                            ? renderHistory(trans("contact_detail.change_logging"), {
                                  timestampz: formatDateFull(iActivity?.timestampz) || "",
                                  module: iActivity?.module,
                                  actor: iActivity?.actor,
                                  changes: iActivity?.change,
                              })
                            : ""}
                        {iActivity?.type === "PROPERTY_NEW"
                            ? renderHistory(trans("contact_detail.change_event"), {
                                  timestampz: formatDateFull(iActivity?.timestampz) || "",
                                  module: iActivity?.module,
                                  actor: iActivity?.actor,
                                  status: iActivity?.event?.status,
                                  phone: contactDetail?.phone,
                              })
                            : ""}
                    </Timeline.Item>
                ))}
            </Timeline>
        </Card>
    )
}
