import React from "react"
import { Card, Col, Row, Typography } from "antd"
import { useAppSelector } from "@hook/useAppSelector"
import { SummaryInfo } from "./SummaryInfo"
import { Event } from "./Event"
import { TablePricePolicy } from "./TablePricePolicy"
import { trans } from "@resources/localization"
import RelatedOrders from "./RelatedOrders"
import { ListComboSku } from "./ListComboSku"
import { find, first } from "lodash"
import { LIST_CURRENCY } from "@config/constant"
type Props ={
    setStatusShow: (data: any) => void 
}
export const Summary: React.FC<Props> = ({setStatusShow}) => {
    const contactDetail = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    return (
        <>
            <div className="mt-3">
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        lg={12}
                        md={12}>
                        <SummaryInfo setStatusShow={setStatusShow} />
                        <div className="mt-3">
                            {contactDetail?.campaignDetail?.productCode && (
                                <Card title={trans("contact-detail.price_policy")}>
                                    <Typography.Text className="font-medium">
                                        {contactDetail?.campaignDetail?.product?.code} - {contactDetail?.campaignDetail?.product?.name}
                                    </Typography.Text>
                                    <TablePricePolicy
                                        currency={contactDetail?.country?.currency?.code || ""}
                                        dataTable={contactDetail?.campaignDetail?.pricePolicies || []}
                                    />
                                </Card>
                            )}
                            {contactDetail?.campaignDetail?.comboCode && (
                                <Card title={trans("contact-detail.price_policy")}>
                                    <ListComboSku
                                        // currency={contactDetail?.country?.currency?.code || ""}
                                        currency={find(LIST_CURRENCY, { value: contactDetail?.countryCode })?.code || ""}
                                        listPricePolicy={first(contactDetail?.campaignDetail?.pricePolicies || [])?.wholeSalePrice}
                                    />
                                </Card>
                            )}
                        </div>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        lg={12}
                        md={12}>
                        <Event />
                        <RelatedOrders />
                    </Col>
                </Row>
            </div>
        </>
    )
}
