import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { TELESALES_COPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Tabs } from "antd"
import { filter } from "lodash"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { GroupTelesales } from "./GroupTelesales"
import { Telesales } from "./Telesales"
export const Team: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [current, setCurrentTab] = useState<string>(SecurityService.can(TELESALES_COPE.TELESALES_VIEW_LIST) ? "telesales" : "groups")
    const dispatch = useAppDispatch()
    useEffect(() => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        if (searchParams.has("tab")) {
            if (oldSearch?.tab === "telesales" && SecurityService.can(TELESALES_COPE.TELESALES_VIEW_LIST)) {
                setCurrentTab("telesales")
            } else if (!SecurityService.can(TELESALES_COPE.TELESALES_VIEW_LIST)) {
                const params: any = {
                    ...oldSearch,
                    tab: "groups",
                }
                setSearchParams(params)
                setCurrentTab("groups")
            }
            if (oldSearch?.tab === "groups" && SecurityService.can(TELESALES_COPE.GROUP_VIEW_LIST)) {
                setCurrentTab("groups")
            } else if (!SecurityService.can(TELESALES_COPE.GROUP_VIEW_LIST)) {
                const params: any = {
                    ...oldSearch,
                    tab: "telesales",
                }
                setSearchParams(params)
                setCurrentTab("telesales")
            }
        } else {
            const params: any = {
                ...oldSearch,
                tab: SecurityService.can(TELESALES_COPE.TELESALES_VIEW_LIST) ? "telesales" : "groups",
            }
            setSearchParams(params)
        }
    }, [dispatch, searchParams])
    const items = [
        { label: trans("telesales.title"), key: "telesales", children: <Telesales />, disabled: !SecurityService.can(TELESALES_COPE.TELESALES_VIEW_LIST) },
        { label: trans("telesales.group"), key: "groups", children: <GroupTelesales />, disabled: !SecurityService.can(TELESALES_COPE.GROUP_VIEW_LIST) },
    ]
    const onChangeTab = (e: string) => {
        const params: any = {
            tab: e,
        }
        setSearchParams(params)
    }
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: trans("sidebar.dashboard"),
                        link: "/",
                    },
                    {
                        name: trans("telesales.team"),
                        link: "",
                    },
                ]}
            />
            <Tabs
                className="nav-space"
                onChange={onChangeTab}
                activeKey={current}
                items={filter(items, { disabled: false })}
            />
        </DefaultLayout>
    )
}
