import SkuComboApi from "@api/version2/SkuComboApi"
import { PAGINATION } from "@config/constant"
import { ICombo, ISkuComboParams, ISkuComboResponse } from "@domain/version2/SkuCombo"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    listSkuComboAutocomplete: ICombo[]
    listSkuCombo: ISkuComboResponse[]
    loadingListCombo: boolean
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
}
const initState: State = {
    listSkuComboAutocomplete: [],
    listSkuCombo: [],
    loadingListCombo: false,
    pagination: {
        current: 0,
        size: 0,
        total: 0,
        totalPage: 0,
    },
}

export const callGetListSkuComboAutocomplete = createAsyncThunk("SKU_COMBO.LIST_COMBO_AUTO", async (payload: { keyword: string }, thunkApi) => {
    try {
        return await SkuComboApi.getListComboAutocomplete(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetListCombo = createAsyncThunk("SKU_COMBO.LIST_COMBO", async (payload: ISkuComboParams, thunkApi) => {
    try {
        return await SkuComboApi.getListCombo(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const skuComboReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetListSkuComboAutocomplete.pending, (state) => {
            state.listSkuComboAutocomplete = []
        })
        .addCase(callGetListSkuComboAutocomplete.fulfilled, (state, { payload }) => {
            state.listSkuComboAutocomplete = payload.data || []
        })
        .addCase(callGetListSkuComboAutocomplete.rejected, (state) => {
            state.listSkuComboAutocomplete = []
        })

    builder
        .addCase(callGetListCombo.pending, (state) => {
            state.loadingListCombo = true
            state.listSkuCombo = []
        })
        .addCase(callGetListCombo.fulfilled, (state, action) => {
            state.listSkuCombo = action.payload.data || []
            state.pagination.current = Number(action.payload.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = Number(action.payload.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(action.payload.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(action.payload.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingListCombo = false
        })
        .addCase(callGetListCombo.rejected, (state) => {
            state.loadingListCombo = false
            state.listSkuCombo = []
        })
})
