import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCreateTelesales } from "@reducer/version2/telesales.reducer"
import { callGetUserAutocomplete } from "@reducer/version2/user.reducer"
import { trans } from "@resources/localization"
import { AutoComplete, Button, Col, Form, message, Row, Space, Spin } from "antd"
import { debounce, find, isEmpty } from "lodash"
import React, { useEffect, useMemo } from "react"
type Props = {
    setClose: () => void
}
export const AddTelesales: React.FC<Props> = ({ setClose }) => {
    const listUsers = useAppSelector((state) => state.user.listUserAutocomplete)
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const loadingCreate = useAppSelector((state) => state.telesales.loadingCreate)

    useEffect(() => {
        dispatch(callGetUserAutocomplete(""))
    }, [dispatch])

    const onSearchTelesales = (searchText: string) => {
        if (searchText.trim().length >= 3) {
            dispatch(callGetUserAutocomplete(searchText.trim()))
        } else {
            dispatch(callGetUserAutocomplete(""))
        }
    }
    const options = useMemo(
        () =>
            listUsers?.map((item) => ({
                value: item?.username,
                key: item?.username,
            })),
        [listUsers]
    )
    const onCreateTelesales = (values: { username: string }) => {
        dispatch(
            callCreateTelesales({
                username: values.username,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                formRef.resetFields()
                setClose()
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <div className="w-72">
            <Form
                onFinish={onCreateTelesales}
                form={formRef}
                layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={trans("telesales.name")}
                            name="username"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <AutoComplete
                                notFoundContent={<Spin />}
                                onSearch={debounce(onSearchTelesales, 1000)}
                                placeholder={trans("telesales.search_user")}
                                options={options}
                                onBlur={(e: any) => {
                                    if (isEmpty(find(options, { value: e?.target?.value }))) {
                                        formRef.setFieldsValue({
                                            username: "",
                                        })
                                    }
                                    dispatch(callGetUserAutocomplete(""))
                                }}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Space>
                        <Button
                            loading={loadingCreate}
                            type="default"
                            onClick={() => {
                                formRef.resetFields()
                                setClose()
                            }}>
                            {trans("button.cancel")}
                        </Button>
                        <Button
                            loading={loadingCreate}
                            type="primary"
                            htmlType="submit"
                            className="ml-2">
                            {trans("button.add")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}
