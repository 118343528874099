import React from "react"
import { trans } from "@resources/localization"
import { Col, DatePicker, Form, Row, Select, Spin, InputNumber, Input } from "antd"
import moment from "moment"
import { useAppSelector } from "@hook/useAppSelector"

type Props = {
    formRef: any
}

export const PaymentMethod: React.FC<Props> = ({ formRef }) => {
    
    const paymentMethods = useAppSelector((state) => state.categoriesVersion2.orderPaymentMethods)
    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    label={trans("order_payment.method")}
                    name="method"
                    rules={[
                        {
                            required: true,
                            message: trans("message.required"),
                        },
                    ]}>
                    <Select
                        placeholder={trans("order_payment.method")}
                        onChange={() => {
                            formRef.setFieldsValue({
                                prePaidAmount: undefined,
                                bankName: "",
                                bankAccount: "",
                                paymentTime: "",
                                notePayment: "",
                            })
                        }}
                        showSearch
                        notFoundContent={<Spin />}>
                        {paymentMethods?.map((item) => (
                            <Select.Option
                                key={item.id}
                                value={item.code}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Form.Item
                noStyle
                shouldUpdate={(pre, next) => pre.status !== next.status}>
                {({ getFieldValue }) => {
                    const payment = getFieldValue("method")
                    return (
                        <>
                            {["pre_paid_by_cash", "pre_paid_by_bank_transfer"].indexOf(payment) >= 0 && (
                                <>
                                    <Col span={24}>
                                        <Form.Item noStyle>
                                            <Form.Item
                                                label={trans("order_payment.pre_paid_amount")}
                                                name="prePaidAmount"
                                                rules={[
                                                    { required: true, message: trans("message.required") },
                                                    {
                                                        validator(_, value) {
                                                            if (Number(value) > 0) {
                                                                return Promise.resolve()
                                                            }
                                                            return Promise.reject(new Error(trans("order_payment.amount_gt_0")))
                                                        },
                                                    },
                                                ]}>
                                                <InputNumber
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    step="1"
                                                    min={0}
                                                    placeholder={trans("contact-detail.price")}
                                                    parser={(value: any) =>
                                                        value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                                    }
                                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                />
                                            </Form.Item>
                                        </Form.Item>
                                    </Col>
                                    {["pre_paid_by_bank_transfer"].indexOf(payment) >= 0 && (
                                        <>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={trans("order_payment.bank")}
                                                    name="bankName"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: trans("message.required"),
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder={trans("order_payment.bank")}
                                                        onBlur={(e) => {
                                                            formRef.setFieldsValue({
                                                                bank: e.target.value.trim(),
                                                            })
                                                        }}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={trans("order_payment.bank_account_number")}
                                                    name="bankAccount"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: trans("message.required"),
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder={trans("order_payment.bank_account_number")}
                                                        onBlur={(e) => {
                                                            formRef.setFieldsValue({
                                                                bankNumber: e.target.value.trim(),
                                                            })
                                                        }}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                    <Col span={24}>
                                        <Form.Item
                                            label={trans("order_payment.date")}
                                            name="paymentTime"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: trans("message.required"),
                                                },
                                            ]}>
                                            <DatePicker
                                                disabledDate={(current) => current > moment().endOf("day")}
                                                placeholder={trans("order_payment.date")}
                                                format={"DD/MM/YYYY"}
                                                showTime={false}
                                                allowClear={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label={trans("order_payment.note")}
                                            name="notePayment">
                                            <Input.TextArea
                                                rows={4}
                                                placeholder={trans("order_payment.note")}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </>
                    )
                }}
            </Form.Item>
        </Row>
    )
}
