import { trans } from "@resources/localization"
import { Col, DatePicker, Form, Row } from "antd"
import { range } from "lodash"
import moment from "moment"
import React from "react"
export const AppointmentDate: React.FC= () => {
    const disabledTime = (time: any) => {
        const startDate = moment(new Date()).format("DD/MM/YYYY");
		const endDate = moment(time).format("DD/MM/YYYY");
		const hour = moment(new Date()).format("H");
		const minute = moment(new Date()).format("m");
		if (startDate === endDate) {
			return {
				disabledHours: () => range(0, +hour),
				disabledMinutes: () => range(0, +minute),
			};
		}
		return {
			disabledHours: () => range(0, 0),
			disabledMinutes: () => range(0, 0),
		};
    }
    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    label={trans("contact-detail.appointment")}
                    name='scheduleDate'>
                    <DatePicker
                        disabledDate={(current) => current < moment().startOf("day")}
                        disabledTime={(current) => disabledTime(current)}
                        placeholder={trans("contact-detail.appointment_pl")}
                        format={"DD/MM/YYYY HH:mm"}
                        showTime={{ defaultValue: moment(new Date(), "HH:mm:ss") }}
                        allowClear={false}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}
