import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import { OrderApi } from "@api/version2/Order"
import { IOrder, IOrderQuery } from "@domain/version2/Order"

interface State {
    orders: IOrder[]
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
    loading: boolean
    loadingExport: boolean
    loadingSyncM28: boolean
}

const initState: State = {
    orders: [],
    pagination: {
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loading: false,
    loadingExport: false,
    loadingSyncM28: false,
}

export const fetchOrders = createAsyncThunk("ORDER.FETCH_LIST", async (orderQuery: IOrderQuery, thunkApi) => {
    try {
        return await OrderApi.getOrders(orderQuery)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const exportOrders = createAsyncThunk("ORDER.EXPORT", async (payload: IOrderQuery, thunkApi) => {
    try {
        return await OrderApi.exportOrders(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const syncOrderToM28 = createAsyncThunk("ORDER.SYNC_TO_M28", async (body: { orderCode: string }, thunkApi) => {
    try {
        return await OrderApi.syncOrderToM28(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const orderReducer = createReducer(initState, (builder) => {
    builder
        .addCase(fetchOrders.pending, (state) => {
            state.loading = true
            state.orders = []
        })
        .addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders = action.payload.data ? action.payload.data : []
            state.pagination.current = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loading = false
        })
        .addCase(fetchOrders.rejected, (state) => {
            state.loading = false
        })
    builder
        .addCase(exportOrders.pending, (state) => {
            state.loadingExport = true
        })
        .addCase(exportOrders.fulfilled, (state) => {
            state.loadingExport = false
        })
        .addCase(exportOrders.rejected, (state) => {
            state.loadingExport = false
        })
    builder
        .addCase(syncOrderToM28.pending, (state) => {
            state.loadingSyncM28 = true
        })
        .addCase(syncOrderToM28.fulfilled, (state) => {
            state.loadingSyncM28 = false
        })
        .addCase(syncOrderToM28.rejected, (state) => {
            state.loadingSyncM28 = false
        })
})
