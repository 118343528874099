import { commonReducer } from "@reducer/common.reducer"
//V1
import { telesalesReducer } from "@reducer/version2/telesales.reducer"
import { userReducer } from "@reducer/version2/user.reducer"
import { productReducer } from "@reducer/version2/product.reducer"
import { productDetailReducer } from "@reducer/version2/product-detail"
import { telesalesGroupReducer } from "./version2/telesales-group.reducer"
import { telesalesGroupDetailReducer } from "./version2/telesales-group-detail.reducer"
// V2
import { contactReducer as contactVer2 } from "./version2/contact.reducer"
import { contactDetailReducer as contactDetailVer2 } from "./version2/contact-detail.reducer"
import { categoriesReducer as categoriesVer2 } from "./version2/categories.reducer"
import { orderReducer as orderVer2 } from "./version2/order.reducer"
import { orderDetailReducer as orderDetailVer2 } from "./version2/order-detail.reducer"
import { countriesReducer as countriesVer2 } from "./version2/countries.reducer"
import { callStringeeReducer } from "./version2/call-stringee.reducer"
import { deliveryCaseReducer } from "./version2/delivery-case.reducer"
import { vendorReducer } from "./version2/vendor.reducer"
import { reportReducer } from "./version2/report.reducer"
import { cancelAssignReducer } from "./version2/cancelAssign.reducer"
import { slaSettingReducer } from "./version2/slaSetting.reducer"
import { skuComboReducer } from "./version2/skuCombo.reducer"
import { skuComboDetailReducer } from "./version2/skuCombo-detail.reducer"
import { financialStatusReducer } from "./version2/financialStatus.reducer"


const rootReducer = {
    common: commonReducer,
    //V1
    telesales: telesalesReducer,
    telesalesGroup: telesalesGroupReducer,
    telesalesGroupDetail: telesalesGroupDetailReducer,
    user: userReducer,
    product: productReducer,
    productDetail: productDetailReducer,
    // V2
    contactVersion2: contactVer2,
    contactDetailVersion2: contactDetailVer2,
    categoriesVersion2: categoriesVer2,
    ordersVersion2: orderVer2,
    orderDetailVersion2: orderDetailVer2,
    countriesVersion2: countriesVer2,
    callStringee: callStringeeReducer,
    deliveryCase: deliveryCaseReducer,
    vendor: vendorReducer,
    report: reportReducer,
    settingCancel: cancelAssignReducer,
    settingSla: slaSettingReducer,
    skuCombo: skuComboReducer,
    skuComboDetail: skuComboDetailReducer,
    financialStatus: financialStatusReducer
}

export default rootReducer
