import lodash from "lodash"

import localStore from "@util/LocalStore"

class SecurityService {
    static can = (permission: string) => {
        const permissions = localStore.getJson("permissions") || []
        return permissions.includes(permission)
    }
    static isLogged = () => {
        const loginSession = localStore.getJson("loginSession")
        const accessToken = lodash.get(loginSession, "accessToken")
        return !!accessToken
    }

    static getUser = () => {
        if (SecurityService.isLogged()) {
            return localStore.getJson("loggedUser")
        }
    }

    static isExpired = () => {
        const expiresAt = localStore.getItem("expiresAt")

        if (!expiresAt) return true

        const endTime: number = Number.parseInt(expiresAt) * 1000

        return endTime <= new Date().getTime()
    }
}

export default SecurityService
