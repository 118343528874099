import { IPricePolicy } from "@domain/version2/ContactDetail"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { Col, Row, Table } from "antd"
import React from "react"
type Props = {
    dataTable: IPricePolicy[]
    currency: string
}
export const TablePricePolicy: React.FC<Props> = ({ dataTable, currency }) => {
    const columns: any = [
        {
            title: trans("ads.properties"),
            dataIndex: "variant",
            render: (variant: any) => (variant ? Object.values(variant)?.join("/") : ""),
        },
        {
            title: trans("ads.sku"),
            dataIndex: "sku",
        },
        {
            title: trans("contact-detail.quantity"),
            dataIndex: "quantity",
            className: "table-vertical-align",
            render: (_: any, record: IPricePolicy) => (
                <>
                    {record?.wholeSalePrice?.map((item: { to: number; from: number; price: number }, index: number) => (
                        <div key={index}>
                            {trans("contact.from")} <span className="ml-1">{item?.from}</span> {trans("contact.to")}
                            <span className="ml-1">{item?.to}</span>
                        </div>
                    ))}
                </>
            ),
        },
        {
            title: `${trans("products.price")} (${currency})`,
            dataIndex: "price",
            className: "table-vertical-align text-end",
            render: (_: any, record: IPricePolicy) => (
                <>
                    {record?.wholeSalePrice?.map((item: { to: number; from: number; price: number }, index: number) => (
                        <div key={index}>{item?.price ? convertNumberToCurrency(item.price || 0) : 0}</div>
                    ))}
                </>
            ),
        },
    ]
    return (
        <Row>
            <Col span={24}>
                <Table
                    className="mt-3"
                    bordered
                    rowKey={"id"}
                    columns={columns}
                    dataSource={dataTable || []}
                    pagination={false}
                />
            </Col>
        </Row>
    )
}
