import React, { useMemo, useState } from "react"
import { Button, Card, message, Pagination, Row, Space, Table, Tag } from "antd"
import { Link, useSearchParams } from "react-router-dom"
import { trans } from "@resources/localization"
import { PAGINATION } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import IContact from "@domain/version2/Contact"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"
import { callAssignContacts, callGetContacts, exportContacts } from "@reducer/version2/contact.reducer"
import { useAppDispatch } from "@hook/useAppDispatch"
import { filter, find, isEmpty } from "lodash"
import { formatDateFull } from "@util/Common"
import { SettingTable } from "@component/General/SettingTable"

export const ContactTable = () => {
    const dispatch = useAppDispatch()
    const loading: boolean = useAppSelector((state) => state.contactVersion2.loading)
    const loadingExport: boolean = useAppSelector((state) => state.contactVersion2.loadingExport)
    const contacts = useAppSelector((state) => state.contactVersion2.contacts)
    const size: number = useAppSelector((state) => state.contactVersion2.pagination.size)
    const currentPage: number = useAppSelector((state) => state.contactVersion2.pagination.currentPage)
    const total: number = useAppSelector((state) => state.contactVersion2.pagination.total)
    const totalPage: number = useAppSelector((state) => state.contactVersion2.pagination.totalPage)
    const loadingAssign = useAppSelector((state) => state.contactVersion2.loadingAssign)
    const statusEvents = useAppSelector((state) => state.categoriesVersion2.contactEventStatus)
    const contactStatus = useAppSelector((state) => state.categoriesVersion2.contactStatus)
    const countriesSuggest = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const vendorSuggest = useAppSelector((state) => state.vendor.vendorSuggests)
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedTable, setSelectedTable] = useState<string[]>([])
    const oldSearch = Object.fromEntries(searchParams.entries())
    const userInfo = SecurityService.getUser()

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch: any = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            pageSize,
        }
        setSearchParams(params)
    }
    const onGetContact = () => {
        dispatch(callAssignContacts()).then((result: any) => {
            if (result?.payload?.status) {
                message.success(trans("message.success"))
                const params: any = Object.fromEntries(searchParams.entries())
                dispatch(
                    callGetContacts({
                        ...params,
                        pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : 100,
                        sort: "assignedAt:DESC",
                    })
                )
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    const handleExportContact = () => {
        dispatch(
            exportContacts({
                ...oldSearch,
                sort: "assignedAt:DESC",
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    const columns: any[] = [
        {
            title: trans("contact-detail.fullname"),
            className: "bold-400",
            width: 120,
            dataIndex: "name",
            key: "name",
            align: "left",
            default: true,
            render: (name: string, record: IContact) =>
            (userInfo?.sub === record?.assignee && SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_VIEW)) || SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_GROUPVIEW) ||SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_VIEW_ALL) ? (
                    <Link to={`/contacts/${record?.id}`}>{name || ""}</Link>
                ) : (
                    <p className="mb-0">{name}</p>
                ),
        },
        {
            title: trans("contact-detail.campaign"),
            className: "bold-400",
            width: 120,
            dataIndex: ["campaignDetail", "name"],
            key: "campaignDetail",
            algin: "left",
            default: true,
        },
        {
            title: trans("contact-detail.product"),
            className: "bold-400",
            width: 150,
            dataIndex: ["productDetail", "name"],
            key: "productDetail",
            algin: "left",
            default: false,
        },
        {
            title: trans("contact-detail.phone"),
            className: "bold-400",
            width: 120,
            dataIndex: "phone",
            align: "center",
            key: "phone",
            default: true,
        },
        {
            title: trans("contact.created_at"),
            className: "bold-400",
            width: 120,
            dataIndex: "createdAt",
            key: "createdAt",
            default: true,
            align: "center",
            render: (date: string) => (date ? formatDateFull(date) : ""),
        },
        {
            title: trans("contact.assigned_at"),
            className: "bold-400",
            width: 120,
            dataIndex: "assignedAt",
            key: "assignedAt",
            align: "center",
            default: false,
            render: (assignedAt: string) => (assignedAt ? formatDateFull(assignedAt) : ""),
        },
        {
            title: trans("order_list.assign_to"),
            className: "bold-400",
            width: 100,
            dataIndex: "assignee",
            default: false,
            key: "assignee",
            align: "left",
        },
        {
            title: trans("contact.assigned_by"),
            className: "bold-400",
            width: 100,
            dataIndex: "assigner",
            default: false,
            key: "assigner",
            align: "left",
        },
        {
            title: trans("contact.country"),
            className: "bold-400",
            width: 120,
            dataIndex: "country",
            default: false,
            key: "country",
            align: "left",
            render: (country: string) => find(countriesSuggest, { code: country })?.name || "",
        },
        {
            title: trans("contact.calls"),
            className: "bold-400",
            width: 100,
            dataIndex: "totalCall",
            key: "totalCall",
            default: false,
            align: "right",
        },
        {
            title: trans("contact.callTime"),
            className: "bold-400",
            width: 120,
            dataIndex: "lastCallAt",
            key: "lastCallAt",
            align: "center",
            default: false,
            render: (lastCallAt: string) => (lastCallAt ? formatDateFull(lastCallAt) : ""),
        },
        {
            title: trans("contact.vendor"),
            className: "bold-400",
            width: 120,
            dataIndex: "vendor",
            align: "left",
            default: true,
            key: "vendor",
            render: (vendor: string) => (vendor ? find(vendorSuggest, { code: vendor })?.name : ""),
        },
        {
            title: trans("contact-detail.contact_state"),
            className: "bold-400",
            width: 120,
            dataIndex: "status",
            align: "left",
            default: true,
            key: "status",
            render: (status: any) => (status ? find(contactStatus, { code: status })?.description : ""),
        },
        {
            title: trans("contact.remark"),
            className: "bold-400",
            dataIndex: "isSpam",
            key: "isSpam",
            align: "center",
            hidden: !(SecurityService.can(CONTACT_SCOPE.CONTACT_LIST_GROUP_VIEW) || SecurityService.can(CONTACT_SCOPE.CONTACT_LIST_VIEW_ALL)),
            default: true,
            render: (isSpam: boolean) => <p className="mb-0">{isSpam ? trans("contact.spam") : ""}</p>,
            width: 80,
        },
        {
            title: trans("sidebar.call_status"),
            className: "bold-400",
            dataIndex: "lastCallStatus",
            default: false,
            key: "lastCallStatus",
            align: "left",
            width: 150,
            render: (callStatus: string) =>
                callStatus && <Tag color={find(statusEvents, { code: callStatus })?.bgColor}>{find(statusEvents, { code: callStatus })?.name}</Tag>,
        },
    ]
    const dataTrees = useMemo(
        () =>
            !isEmpty(columns)
                ? columns?.map((item: any) => ({
                      title: item?.title,
                      key: item?.key,
                      disabled: item?.default,
                  }))
                : [],
        []
    )

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                <Space>
                    {SecurityService.can(CONTACT_SCOPE.CONTACT_GET) && (
                        <Button
                            loading={loadingAssign}
                            type={"primary"}
                            onClick={() => onGetContact()}>
                            {trans("contact.assign_contacts")}
                        </Button>
                    )}
                    <Button
                        type="primary"
                        className="m-0 ml-2"
                        loading={loadingExport}
                        onClick={() => handleExportContact()}
                        icon={<i className="fa-solid fa-cloud-arrow-down mr-2" />}>
                        {trans("contact.export_contact")}
                    </Button>
                    {!isEmpty(dataTrees) && (
                        <SettingTable
                            dataTree={dataTrees}
                            keyTable="contactTelesales"
                            setSelectedTable={setSelectedTable}
                            selectedTable={selectedTable}
                        />
                    )}
                </Space>
            }>
            <Table
                rowKey={"id"}
                scroll={{
                    x: true,
                }}
                columns={filter(
                    columns.filter((col) => !col.hidden),
                    (iFil: any) => selectedTable?.includes(iFil?.key)
                )}
                dataSource={contacts || []}
                loading={loading}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={size}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
