import React, { useEffect, useState } from "react"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { useSearchParams } from "react-router-dom"
import { forEach, isEmpty, omitBy } from "lodash"
import moment from "moment"
import { FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetProductAutocomplete } from "@reducer/version2/product.reducer"
import { ICourier, ILocation } from "@domain/version2/Country"
import { IVendor } from "@domain/version2/Vendor"
import { IUserAutocomplete } from "@domain/version2/User"

export const Filter = () => {
    const [isShowMore, setIsShowMore] = useState<boolean>(false)
    const [formRef] = Form.useForm()
    const statuses = useAppSelector((state) => state.categoriesVersion2.orderStatuses)
    const [searchParams, setSearchParams] = useSearchParams()
    const productAutocomplete = useAppSelector((state) => state.product.productSuggest)
    const dispatch = useAppDispatch()
    const countriesSuggest = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const vendorSuggest = useAppSelector((state) => state.vendor.vendorSuggests)
    const userSuggest = useAppSelector((state) => state.user.listUserAutocomplete)
    const couriers = useAppSelector((state) => state.countriesVersion2.couriersSuggestAll)
    const listFinancialStatus = useAppSelector(state => state.financialStatus.listFinancialStatus)

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("createdFrom") &&
                    searchParams.has("createdTo") && {
                        createdAt: [moment(oldSearch?.createdFrom), moment(oldSearch?.createdTo)],
                    }),
                ...(searchParams.has("status") && {
                    status: oldSearch?.status?.split(","),
                }),
                ...(searchParams.has("hasDeliveryCase") && {
                    hasDeliveryCase: oldSearch?.hasDeliveryCase === "true",
                }),
                ...(searchParams.has("code") && {
                    code: oldSearch?.code?.split(","),
                }),
                ...(searchParams.has("productCode") && {
                    productCode: oldSearch?.productCode?.split(","),
                }),
                ...(searchParams.has("vendors") && {
                    vendors: oldSearch.vendors.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("countries") && {
                    countries: oldSearch.countries.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("createdBy") && {
                    createdBy: oldSearch.createdBy.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("couriers") && {
                    couriers: oldSearch.couriers.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("financial") && {
                    financial: oldSearch.financial.split(",")?.map((item) => item),
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const onFilter = (values: any) => {
        const oldSearch = Object.fromEntries(searchParams.entries())

        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                createdFrom: values?.createdAt ? moment(values?.createdAt[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
                createdTo: values?.createdAt ? moment(values?.createdAt[1].format(FORMAT_DATE_END)).toISOString() : undefined,
                status: values?.status?.join(",") || undefined,
                code: values?.code?.join(",") || undefined,
                productCode: values?.productCode?.join(",") || undefined,
                trackingNumber: values?.trackingNumber ? values.trackingNumber.trim() : undefined,
                hasDeliveryCase: values?.hasDeliveryCase !== undefined ? values?.hasDeliveryCase : undefined,
                countries: values.countries ? values.countries.join(",") : undefined,
                vendors: values.vendors ? values.vendors.join(",") : undefined,
                createdBy: values.createdBy ? values.createdBy.join(",") : undefined,
                couriers: values.couriers ? values.couriers.join(",") : undefined,
                financial: values.financial ? values.financial.join(",") : undefined,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }

    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "product":
                    dispatch(
                        callGetProductAutocomplete({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "product":
                    dispatch(
                        callGetProductAutocomplete({
                            keyword: "",
                        })
                    )
                    break
                default:
                    break
            }
        }
    }
    return (
        <Card className="space-layout">
            <Form
                onFinish={onFilter}
                layout="vertical"
                form={formRef}>
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="code"
                            label={trans("order_list.code")}>
                            <Select
                                // className={"_filter_code w-full"}
                                placeholder={trans("order_list.code")}
                                mode="tags"
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="customerName"
                            label={trans("order_list.customerName")}>
                            <Input
                                suffix={<i className={"fas fa-search"} />}
                                placeholder={trans("order_list.customerName")}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="customerPhone"
                            label={trans("order.customer_phone")}>
                            <Input
                                suffix={<i className={"fas fa-search"} />}
                                placeholder={trans("order.customer_phone")}
                            />
                        </Form.Item>
                    </Col>
                    {isShowMore ? (
                        <>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="status"
                                    label={trans("order_list.status")}>
                                    <Select
                                        placeholder={trans("order_list.select_status")}
                                        mode="multiple">
                                        {statuses.map((x) => (
                                            <Select.Option
                                                key={x.code}
                                                value={x.code}>
                                                {trans(`order_status.${x.code}`)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="createdAt"
                                    label={trans("order_list.created_at")}>
                                    <DatePicker.RangePicker
                                        format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                        placeholder={[trans("order_list.from"), trans("order_list.to")]}
                                        className={"_filter_created_at w-full"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 12 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                    label={trans("order_detail.tracking_number")}
                                    name="trackingNumber">
                                    <Input
                                        allowClear
                                        placeholder={trans("order.tracking_number_placeholder")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="hasDeliveryCase"
                                    label={trans("order.delivery_case_filter")}>
                                    <Select placeholder={trans("order.delivery_case_filter")}>
                                        <Select.Option
                                            key={0}
                                            value={true}>
                                            {trans("delivery_case.has_delivery_case")}
                                        </Select.Option>
                                        <Select.Option
                                            key={1}
                                            value={false}>
                                            {trans("delivery_case.no_delivery_case")}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact-detail.product")}
                                    name="productCode">
                                    <Select
                                        allowClear
                                        placeholder={trans("contact-detail.product")}
                                        showSearch
                                        onBlur={() => onSearchAutocomplete("", "product")}
                                        onSearch={(e) => {
                                            onSearchAutocomplete(e, "product")
                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}
                                        mode="multiple">
                                        {productAutocomplete?.map((item) => {
                                            return (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.country")}
                                    name="countries">
                                    <Select
                                        placeholder={trans("contact.country")}
                                        mode="multiple"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}>
                                        {countriesSuggest &&
                                            countriesSuggest?.map((item: ILocation) => {
                                                return (
                                                    <Select.Option
                                                        key={item?.code}
                                                        value={item?.code}>
                                                        {item.name}
                                                    </Select.Option>
                                                )
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.vendor")}
                                    name="vendors">
                                    <Select
                                        allowClear
                                        placeholder={trans("payment_statement.vendor_filter")}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}
                                        mode="multiple">
                                        {vendorSuggest?.map((item: IVendor) => {
                                            return (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("table.created_by")}
                                    name="createdBy">
                                    <Select
                                        allowClear
                                        placeholder={trans("order_list.created_by_placeholder")}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}
                                        mode="multiple">
                                        {userSuggest?.map((item: IUserAutocomplete) => {
                                            return (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.username}>
                                                    {item?.username}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("stock_issue_detail.courier")}
                                    name="couriers">
                                    <Select
                                        allowClear
                                        placeholder={trans("vendor.select_courier")}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}
                                        mode="multiple">
                                        {couriers?.map((item: ICourier) => {
                                            return (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}>
                            <Form.Item
                                label={trans("order_detail.financial_status")}
                                name="financial">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    placeholder={trans("order.financial_status_placeholder")}>
                                    {listFinancialStatus?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {trans(`order_detail.financial_status_${item?.code}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        </>
                    ) : (
                        ""
                    )}
                </Row>
                <Row>
                    <Col
                        span={24}
                        className={`form-filter-control filter-button`}>
                        <Button
                            type="primary"
                            className="mg-r-10 _filter_button_filter"
                            htmlType="submit">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            className="mg-r-10 _filter_button_clear"
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({})
                            }}>
                            {trans("contact.clear")}
                        </Button>
                        <Button
                            type="link"
                            onClick={() => setIsShowMore((prev) => !prev)}>
                            {isShowMore ? trans("contact.filter_less") : trans("contact.filter_more")}
                            <i className={`ml-2 fa-solid ${isShowMore ? "fa-chevron-up" : "fa-chevron-down"}`} />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
