import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { REPORT_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { getCountriesSuggest } from "@reducer/version2/countries.reducer"
import { callGetSlaSetting } from "@reducer/version2/slaSetting.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Tabs } from "antd"
import { filter } from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { CallReport } from "./CallReport"
import { FilterReport } from "./Filter"
import { TelesalesReport } from "./PerformanceByTelesales"
export const Report: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [current, setCurrentTab] = useState<string>("")
    const userInfo = SecurityService.getUser()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("report.title")))
        dispatch(getCountriesSuggest())
        dispatch(callGetSlaSetting())
    }, [dispatch])
    useEffect(() => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        if (!current) {
            if (searchParams.has("tab")) {
                setCurrentTab(oldSearch?.tab)
            } else {
                const params: any = {
                    ...oldSearch,
                    ...(SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW) || SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW_ALL)
                        ? { tab: "call" }
                        : { tab: "telesales" }),
                }
                setSearchParams(params)
            }
        }
    }, [dispatch, searchParams])
    const items = [
        {
            label: trans("report.call_title"),
            key: "call",
            children: <CallReport currentTab={current} />,
            disabled: !SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW) && !SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW_ALL),
        },
        {
            label: trans("report.telesales_title"),
            key: "telesales",
            children: <TelesalesReport currentTab={current} />,
            disabled: !SecurityService.can(REPORT_SCOPE.SLA_REPORT_VIEW) && !SecurityService.can(REPORT_SCOPE.SLA_REPORT_VIEW_ALL),
        },
    ]
    const onChangeTab = (e: string) => {
        setCurrentTab(e)
        const params: any = {
            tab: e,
            fromDate: moment(moment().subtract(30, "days").format(FORMAT_DATE_BEGIN)).toISOString(),
            toDate: moment(moment().format(FORMAT_DATE_END)).toISOString(),
            telesales: userInfo?.sub,
        }
        setSearchParams(params)
    }
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: trans("sidebar.dashboard"),
                        link: "/",
                    },
                    {
                        name: trans("report.title"),
                        link: "/reports",
                    },
                ]}
            />
            <FilterReport currentTab={current} />
            <Tabs
                className="nav-space-x nav-space"
                onChange={onChangeTab}
                activeKey={current}
                items={filter(items, { disabled: false })}
            />
        </DefaultLayout>
    )
}
