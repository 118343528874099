import { IHistorySlaSetting, ISlaSetting, ISlaSettingRequest } from "@domain/version2/SlaSetting"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class SlaSettingApi {

    static getSlaSetting(): Promise<AxiosResponse<ISlaSetting[]>> {
        return apiClient.get(`/slas`)
    }
    static postSlaSetting(body: ISlaSettingRequest[]) {
        return apiClient.post(`/slas`, body)
    }
    static patchSlaSetting(body: ISlaSettingRequest[]) {
        return apiClient.patch(`/slas`, body)
    }
    static getHistorySlaSetting(params:{settingType: string, page: number, pageSize: number}): Promise<AxiosResponse<IHistorySlaSetting[]>> {
        return apiClient.get(`/setting-histories`, {
            params,
        })
    }
}
