import { trans } from "@resources/localization"
import { parseSecondToTime } from "@util/Common"
import { Button, Modal } from "antd"
import React from "react"

type Props = {
    isCalling: boolean
    closeModal: () => void
    callState: string
    phone: string
    isAnswerPhone: boolean
    countTime: number
    handleMuteCall: () => void
    isMute: boolean
}

export const ModalStringee: React.FC<Props> = ({ isCalling, closeModal, callState, phone, isAnswerPhone, countTime, handleMuteCall, isMute }) => {
    return (
        <Modal
            title={
                <div className="flex items-center">
                    <i className="fa-light fa-circle-info text-blue-400"></i>
                    <span className="ml-1">{trans("stringee.info_call")}</span>
                </div>
            }
            open={isCalling}
            destroyOnClose
            footer={null}
            width={500}
            style={{ top: 15, left: 10 }}
            onCancel={closeModal}
            mask={false}>
            <div>
                <div className="grid place-items-center">
                    <h3 className="font-medium">{callState}</h3>
                    <p className="m-0">{phone}</p>
                    {isCalling && isAnswerPhone && <p className="m-0">{parseSecondToTime(+countTime)}</p>}
                    {isCalling && isAnswerPhone && (
                        <Button
                            type="link"
                            onClick={handleMuteCall}
                            icon={isMute ? <i className="fa-light fa-volume-xmark"></i> : <i className="fa-regular fa-volume"></i>}></Button>
                    )}
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => closeModal()}
                        icon={<i className="fa-light fa-phone-flip"></i>}></Button>
                </div>
            </div>
        </Modal>
    )
}
