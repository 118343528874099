import { PAGINATION } from "@config/constant"
import { TELESALES_COPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callDeleteTelesalesOfGroup, callDetailTelesalesGroup, callGetTelesalesInGroup } from "@reducer/version2/telesales-group-detail.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Button, Card, Modal, Pagination, Popover, Row } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import { filter } from "lodash"
import React, { useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import AddTelesale from "./AddTelesale"

const ListTelesales = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const groupCode = useParams().id as string
    const pageSize: number = useAppSelector((state) => state.telesalesGroupDetail.pagination.pageSize)
    const currentPage: number = useAppSelector((state) => state.telesalesGroupDetail.pagination.currentPage)
    const total: number = useAppSelector((state) => state.telesalesGroupDetail.pagination.total)
    const totalPage: number = useAppSelector((state) => state.telesalesGroupDetail.pagination.totalPage)
    const listTelesales = useAppSelector((state) => state.telesalesGroupDetail.listTelesales)
    const loadingTelesales = useAppSelector((state) => state.telesalesGroupDetail.loadingTelesales)

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }

    const [open, setOpen] = useState<boolean>(false)
    const chagneVisiblePopover = (newOpen: boolean) => setOpen(newOpen)
    const closePopover = () => setOpen(false)

    const columns: ColumnsType<any> = [
        {
            title: trans("telesales.name"),
            dataIndex: "username",
            width: 120,
        },
        {
            title: trans("telesales.display"),
            width: 150,
            render: (record: any) => <p className="mb-0">{record?.user?.name || ""}</p>,
        },
        {
            title: trans("telesales.email"),
            width: 120,
            render: (record: any) => <p className="mb-0">{record?.user?.email || ""}</p>,
        },
        {
            title: trans("table.added_by"),
            width: 100,
            render: (record: any) => (record?.groupTelesales[0]?.createdBy ? record?.groupTelesales[0]?.createdBy : ""),
        },
        {
            title: trans("table.added_at"),
            width: "15%",
            render: (record: any) => (record?.groupTelesales[0]?.createdAt ? formatDateFull(record?.groupTelesales[0]?.createdAt) : ""),
        },
        {
            title: trans("table.action"),
            dataIndex: SecurityService.can(TELESALES_COPE.GROUP_TELESALES_UPDATE) ? "action" : "disabledAction",
            width: "15%",
            render: (_, row) => (
                <Button
                    type="link"
                    disabled={!SecurityService.can(TELESALES_COPE.GROUP_TELESALES_UPDATE)}
                    icon={<i className="fa-solid fa-trash"></i>}
                    onClick={() => showConfirmDelete(row)}
                />
            ),
        },
    ]
    const showConfirmDelete = (item: any) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            onOk() {
                dispatch(
                    callDeleteTelesalesOfGroup({
                        groupCode,
                        body: {
                            telesales: [item.username],
                        },
                    })
                ).then(() => {
                    dispatch(callDetailTelesalesGroup({ groupCode }))
                    dispatch(
                        callGetTelesalesInGroup({
                            code: groupCode,
                            params: {
                                ...Object.fromEntries(searchParams.entries()),
                                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                sort: "createdAt:desc",
                            },
                        })
                    )
                })
            },
        })
    }

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(TELESALES_COPE.GROUP_TELESALES_UPDATE) && (
                    <Popover
                        title={trans("contact.add_telesale")}
                        placement="left"
                        trigger="click"
                        onOpenChange={chagneVisiblePopover}
                        content={<AddTelesale close={closePopover} />}
                        open={open}>
                        <Button type="primary">{trans("contact.add_telesale")}</Button>
                    </Popover>
                )
            }>
            <div className="">
                <Table
                    scroll={{
                        x: true,
                    }}
                    columns={filter(columns, (iFil: any) => iFil?.dataIndex !== "disabledAction")}
                    dataSource={listTelesales || []}
                    loading={loadingTelesales}
                    rowKey={(record) => record.id}
                    pagination={false}
                    locale={{
                        emptyText: trans("table.empty"),
                    }}
                />
            </div>
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}

export default ListTelesales
