import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeVisibleDeliveryCaseImport } from "@reducer/version2/delivery-case.reducer"
import { trans } from "@resources/localization"
import { Modal } from "antd"
import React, { useState } from "react"
import { FormImport } from "./FormImport"
import { TableError } from "./TableError"

export const ImportDeliveryCase: React.FC = () => {
    const dispatch = useAppDispatch()
    const [isUpload, setIsUpload] = useState<boolean>(false)
    const visibleImport = useAppSelector((state) => state.deliveryCase.isShowImport)
    return (
        <Modal
            destroyOnClose
            title={!isUpload ? trans("delivery_case.import") : trans("order_list.import_tracking_number_fail_list_title")}
            visible={visibleImport}
            width={670}
            footer={null}
            onCancel={() => {
                setIsUpload(false)
                dispatch(changeVisibleDeliveryCaseImport(false))
            }}>
            {!isUpload ? <FormImport setIsUpload={setIsUpload} /> : <TableError />}
        </Modal>
    )
}
