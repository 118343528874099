import { TelesalesGroupApi } from "@api/version2/TelesalesGroupApi"
import { PAGINATION } from "@config/constant"
import { IProduct } from "@domain/version2/Product"
import { ISkuComboOfTelesaleGroup } from "@domain/version2/SkuCombo"
import { IProductInGroup, ITelesalesInGroup, ITheSameTelesales } from "@domain/version2/TelesalesGroup"
import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    pagination: {
        total: number
        currentPage: number
        totalPage: number
        pageSize: number
    }
    telesalesGroup: any
    listTelesales: Array<ITelesalesInGroup>
    loadingDetail: boolean
    loadingTelesales: boolean
    listTelesalesNotInGroup: Array<any>
    loadingAdd: boolean
    productsSuggest: IProduct[]
    loadingAddProduct: boolean
    loadingProducts: boolean
    productsInGroup: IProductInGroup[]
    paginationProduct: {
        total: number
        currentPage: number
        totalPage: number
        pageSize: number
    }
    loadingDeleteProduct: boolean
    telesalesInGroup: ITheSameTelesales[]

    loadingCombo: boolean
    combosInGroup: ISkuComboOfTelesaleGroup[]
    paginationCombo: {
        total: number
        currentPage: number
        totalPage: number
        pageSize: number
    }
    loadingAddCombo: boolean
    loadingDeleteCombo: boolean
}

const initState: State = {
    pagination: {
        total: 0,
        currentPage: 0,
        totalPage: 0,
        pageSize: 0,
    },
    telesalesGroup: {},
    listTelesales: [],
    loadingDetail: false,
    loadingTelesales: false,
    listTelesalesNotInGroup: [],
    loadingAdd: false,
    productsSuggest: [],
    loadingAddProduct: false,
    loadingProducts: false,
    productsInGroup: [],
    paginationProduct: {
        total: 0,
        currentPage: 0,
        totalPage: 0,
        pageSize: 0,
    },
    loadingDeleteProduct: false,
    telesalesInGroup: [],

    loadingCombo: false,
    combosInGroup: [],
    paginationCombo: {
        total: 0,
        currentPage: 0,
        totalPage: 0,
        pageSize: 0,
    },
    loadingAddCombo: false,
    loadingDeleteCombo: false,
}

export const callDetailTelesalesGroup = createAsyncThunk("TELESALES_GROUPS.GET_LIST", async (payload: { groupCode: string }, thunkApi) => {
    try {
        return await TelesalesGroupApi.getDetailTelesaleGroup(payload.groupCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateNameTelesalesGroup = createAsyncThunk(
    "TELESALES_GROUPS.UPDATE_GROUP_NAME",
    async (payload: { groupCode: string; body: { new_name: string } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.updateNameTelesalesGroup(payload.groupCode, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callGetTelesalesInGroup = createAsyncThunk(
    "TELESALES_GROUP.GET_TELESALES_IN_GROUP",
    async (payload: { code: string; params: { page: number; pageSize: number; sort: string } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.getTelesalesInGroup(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callDeleteTelesalesOfGroup = createAsyncThunk(
    "TELESALES_GROUPS.DELETE_TELESALES_OF_GROUP",
    async (payload: { groupCode: string; body: { telesales: Array<string> } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.deleteTelesaleOfGroup(payload.groupCode, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callListTelesalesNotInGroup = createAsyncThunk(
    "TELESALES_GROUP.GET_LIST_SUGGEST_TELESALES",
    async (payload: { code: string; params: { keyword: string } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.getTelesalesNotInGroup(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callAddTelesalesToGroup = createAsyncThunk(
    "TELESALES_GROUP.ADD_TELESALE",
    async (payload: { code: string; body: { telesales: Array<string> } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.addTelesalesToGroup(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callGetProductSuggest = createAsyncThunk(
    "TELESALES_GROUP.PRODUCT_SUGGEST",
    async (payload: { code: string; params: { keyword: string } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.getProductSuggest(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callAddProductToGroup = createAsyncThunk(
    "TELESALES_GROUP.ADD_PRODUCT",
    async (payload: { code: string; body: { productCodes: string[] } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.addProductToGroup(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callGetProductsInGroup = createAsyncThunk(
    "TELESALES_GROUP.GET_PRODUCT_IN_GROUP",
    async (payload: { code: string; params: { page: number; pageSize: number } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.getProductInGroup(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callDeleteProducts = createAsyncThunk(
    "TELESALES_GROUP.DELETE_PRODUCT",
    async (payload: { code: string; body: { productCodes: string[] } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.removeProductFromGroup(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callGetTheSameTelesalesInGroups = createAsyncThunk("TELESALES_GROUP.GET_THE_SAME_TELESALES_IN_GROUP", async (code: string, thunkApi) => {
    try {
        return await TelesalesGroupApi.getTheSameTelesalesInGroups(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callAddCombosToGroup = createAsyncThunk(
    "TELESALES_GROUP.ADD_COMBO",
    async (payload: { code: string; body: { comboCodes: string[] } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.updateListComboInGroup(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callGetCombosInGroup = createAsyncThunk(
    "TELESALES_GROUP.GET_COMBO_IN_GROUP",
    async (payload: { code: string; params: { page: number; pageSize: number } }, thunkApi) => {
        try {
            return await TelesalesGroupApi.getListComboInGroup(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callDeleteCombo = createAsyncThunk("TELESALES_GROUP.DELETE_COMBO", async (payload: { code: string; body: { comboCodes: string[] } }, thunkApi) => {
    try {
        return await TelesalesGroupApi.deleteComboOfGroup(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const resetDataSuggestUser = createAction<any>("TELESALES_GROUP.RESET_DATA")
export const resetDataProduct = createAction<any>("TELESALES_GROUP.RESET_DATA_PRODUCT")

export const telesalesGroupDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callDetailTelesalesGroup.pending, (state) => {
            state.loadingDetail = true
        })
        .addCase(callDetailTelesalesGroup.fulfilled, (state, { payload }) => {
            state.telesalesGroup = payload?.data
            state.loadingDetail = false
        })
        .addCase(callDetailTelesalesGroup.rejected, (state) => {
            state.loadingDetail = false
        })

    builder
        .addCase(callGetTelesalesInGroup.pending, (state) => {
            state.loadingTelesales = true
        })
        .addCase(callGetTelesalesInGroup.fulfilled, (state, { payload }) => {
            state.listTelesales = payload?.data as Array<ITelesalesInGroup>
            state.pagination.currentPage = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingTelesales = false
        })
        .addCase(callGetTelesalesInGroup.rejected, (state) => {
            state.loadingTelesales = false
        })

    builder
        .addCase(callListTelesalesNotInGroup.pending, (state) => {
            state.listTelesalesNotInGroup = []
        })
        .addCase(callListTelesalesNotInGroup.fulfilled, (state, { payload }) => {
            state.listTelesalesNotInGroup = payload?.data || []
        })
        .addCase(callListTelesalesNotInGroup.rejected, (state) => {
            state.listTelesalesNotInGroup = []
        })

    builder
        .addCase(callAddTelesalesToGroup.pending, (state) => {
            state.loadingAdd = true
        })
        .addCase(callAddTelesalesToGroup.fulfilled, (state) => {
            state.loadingAdd = false
        })
        .addCase(callAddTelesalesToGroup.rejected, (state) => {
            state.loadingAdd = false
        })
    builder.addCase(resetDataSuggestUser, (state, { payload }) => {
        state.listTelesalesNotInGroup = payload
    })
    builder
        .addCase(callGetProductSuggest.pending, (state) => {
            state.productsSuggest = []
        })
        .addCase(callGetProductSuggest.fulfilled, (state, { payload }) => {
            state.productsSuggest = payload.data
        })
        .addCase(callGetProductSuggest.rejected, (state) => {
            state.productsSuggest = []
        })
    builder.addCase(resetDataProduct, (state, { payload }) => {
        state.productsSuggest = payload
    })
    builder
        .addCase(callAddProductToGroup.pending, (state) => {
            state.loadingAddProduct = true
        })
        .addCase(callAddProductToGroup.fulfilled, (state) => {
            state.loadingAddProduct = false
        })
        .addCase(callAddProductToGroup.rejected, (state) => {
            state.loadingAddProduct = false
        })
    builder
        .addCase(callGetProductsInGroup.pending, (state) => {
            state.loadingProducts = true
            state.productsInGroup = []
        })
        .addCase(callGetProductsInGroup.rejected, (state) => {
            state.loadingProducts = false
            state.productsInGroup = []
        })
        .addCase(callGetProductsInGroup.fulfilled, (state, { payload }) => {
            state.loadingProducts = false
            state.productsInGroup = payload?.data?.data
            state.paginationProduct.currentPage = payload?.data?.page || PAGINATION.DEFAULT_CURRENT_PAGE
            state.paginationProduct.pageSize = payload?.data?.size || PAGINATION.DEFAULT_PAGE_SIZE
            state.paginationProduct.total = payload?.data?.total || PAGINATION.DEFAULT_TOTAL_ITEM
            state.paginationProduct.totalPage = payload?.data?.pageCount || PAGINATION.DEFAULT_TOTAL_PAGE
        })
    builder
        .addCase(callDeleteProducts.pending, (state) => {
            state.loadingDeleteProduct = true
        })
        .addCase(callDeleteProducts.fulfilled, (state) => {
            state.loadingDeleteProduct = false
        })
        .addCase(callDeleteProducts.rejected, (state) => {
            state.loadingDeleteProduct = false
        })
    builder
        .addCase(callGetTheSameTelesalesInGroups.pending, (state) => {
            state.telesalesInGroup = []
        })
        .addCase(callGetTheSameTelesalesInGroups.fulfilled, (state, { payload }) => {
            state.telesalesInGroup = payload.data
        })
        .addCase(callGetTheSameTelesalesInGroups.rejected, (state) => {
            state.telesalesInGroup = []
        })

    builder
        .addCase(callAddCombosToGroup.pending, (state) => {
            state.loadingAddCombo = true
        })
        .addCase(callAddCombosToGroup.fulfilled, (state) => {
            state.loadingAddCombo = false
        })
        .addCase(callAddCombosToGroup.rejected, (state) => {
            state.loadingAddCombo = false
        })
    builder
        .addCase(callGetCombosInGroup.pending, (state) => {
            state.loadingCombo = true
            state.combosInGroup = []
        })
        .addCase(callGetCombosInGroup.rejected, (state) => {
            state.loadingCombo = true
            state.combosInGroup = []
        })
        .addCase(callGetCombosInGroup.fulfilled, (state, action) => {
            state.loadingCombo = false
            state.combosInGroup = action.payload?.data || []
            state.paginationCombo.currentPage = Number(action.payload.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.paginationCombo.pageSize = Number(action.payload.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.paginationCombo.total = Number(action.payload.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.paginationCombo.totalPage = Number(action.payload.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
        })
    builder
        .addCase(callDeleteCombo.pending, (state) => {
            state.loadingDeleteCombo = true
        })
        .addCase(callDeleteCombo.fulfilled, (state) => {
            state.loadingDeleteCombo = false
        })
        .addCase(callDeleteCombo.rejected, (state) => {
            state.loadingDeleteCombo = false
        })
})
