import { PAGINATION } from "@config/constant"
import { IChangedValue, IHistoryUpdate } from "@domain/version2/CancelAssign"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetHistoryCancel, changeVisibleModalHistory } from "@reducer/version2/cancelAssign.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Col, List, Modal, Pagination, Row, Typography } from "antd"
import React from "react"
export const HistoryCancelAssignment: React.FC = () => {
    const visibleHistory = useAppSelector((state) => state.settingCancel.isVisibleHistory)
    const histories = useAppSelector((state) => state.settingCancel.historyCancels)
    const page = useAppSelector((state) => state.settingCancel.pagination.page)
    const pageSize = useAppSelector((state) => state.settingCancel.pagination.pageSize)
    const total = useAppSelector((state) => state.settingCancel.pagination.total)
    const totalPage = useAppSelector((state) => state.settingCancel.pagination.totalPage)
    const loadingHistory = useAppSelector((state) => state.settingCancel.loadingHistory)
    const dispatch = useAppDispatch()
    const onChangePage = (newPage: number, newSize: number) => {
        dispatch(
            callGetHistoryCancel({
                settingType: "cancel_assignment",
                page: newSize !== pageSize ? PAGINATION.DEFAULT_CURRENT_PAGE : newPage,
                pageSize: newSize,
            })
        )
    }

    return (
        <Modal
            width={790}
            open={visibleHistory}
            onCancel={() => dispatch(changeVisibleModalHistory(false))}
            destroyOnClose
            maskClosable={false}
            footer={null}
            title={`${trans("setting.assign")} - ${trans("setting.update_history")}`}>
            <div>{total !== 0 ? `${trans("contact.page")} ${page}/${totalPage}(${total})` : ""}</div>
            <List
                loading={loadingHistory}
                itemLayout="vertical"
                size="large"
                pagination={false}
                dataSource={histories}
                renderItem={(item: IHistoryUpdate) => (
                    <List.Item className="mt-2">
                        <Row
                            gutter={12}
                            className="mt-2">
                            <Col
                                xs={24}
                                sm={24}
                                md={12}>
                                <Typography.Text className="font-medium">{trans("setting.update_at")}:</Typography.Text>
                                <Typography.Text className="ml-2">{item?.createdAt ? formatDateFull(item?.createdAt || "") : ""}</Typography.Text>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}>
                                <Typography.Text className="font-medium">{trans("setting.update_by")}:</Typography.Text>
                                <Typography.Text className="ml-2">{item?.createdBy}</Typography.Text>
                            </Col>
                        </Row>
                        <div className="grid grid-cols-4 gap-x-4 bg-gray-200 p-2 mt-2">
                            <div className="col-span-2 font-medium">{trans("setting.action_name")}</div>
                            <div className="col-span-1 font-medium">{trans("setting.after_day")}</div>
                            <div className="col-span-1 font-medium">{trans("table.action")}</div>
                        </div>
                        {item?.values?.map((item: IChangedValue) => (
                            <div
                                key={item?.id}
                                className="grid grid-cols-4 gap-x-4 mt-2 border-b">
                                <div className="col-span-2">{item?.conditionOption?.name}</div>
                                <div className="col-span-1">{item?.hours}</div>
                                <div className="col-span-1">{item?.isActive ? trans("user_list.active") : trans("user_list.inactive")}</div>
                            </div>
                        ))}
                    </List.Item>
                )}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={[1, 5, 10, 15]}
                    defaultCurrent={1}
                    current={page}
                    pageSize={pageSize}
                    total={total}
                    onChange={onChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Modal>
    )
}
