import React from "react"
import { Button, Card, Col, message, Row, Select } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeVisibleFormReasonCancel, fetchUpdateOrderStatus, getOrderDetail } from "@reducer/version2/order-detail.reducer"
import { ORDER_STATUS, STATUS_CAN_UPDATE } from "@config/constant"
import { callGetSubOrderStatus } from "@reducer/version2/categories.reducer"
import { formatDateFull } from "@util/Common"
import { find } from "lodash"
import moment from "moment"
import { syncOrderToM28 } from "@reducer/version2/order.reducer"

export const OrderDetailHeader: React.FC = () => {
    const dispatch = useAppDispatch()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loadingSync = useAppSelector((state) => state.ordersVersion2.loadingSyncM28)
    const loadingUpdateStatus = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderStatus)
    const orderStatuses = useAppSelector((state) => state.categoriesVersion2.orderStatuses)

    const onChangeStatus = (status: string) => {
        if (status === ORDER_STATUS.CANCELLED) {
            dispatch(callGetSubOrderStatus(status))
            dispatch(changeVisibleFormReasonCancel(true))
        } else {
            dispatch(
                fetchUpdateOrderStatus({
                    code: detailOrder?.code || "",
                    body: {
                        status: status || "",
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(detailOrder?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }

    const paymentMethod = detailOrder?.paymentTransactions?.find((item: any) => item.method)?.method
    const prePaidAmountRegex = (value?: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    const paymentConfirmation = (method?: string) => {
        if (method === "cod") {
            return <div>
            <span className="font-bold">COD</span>
            {` - ${trans("order_payment.cod_confirmation")}`}
        </div>
        } else if (method === "pre_paid_by_cash") {
            const cash = find(detailOrder?.paymentTransactions, { method: "pre_paid_by_cash" })
            return (
                <div className="font-medium">
                    <span className="font-bold">{trans("order_payment.pre_paid_by_cash")}</span>
                    {` - ${trans("order_payment.customer_prepaid")}`} <span className="font-bold">{prePaidAmountRegex(cash?.prePaidAmount)}</span>
                    {` ${trans("order_payment.on")}`}{" "}
                    <span className="font-bold">{cash?.paymentTime ? moment(cash?.paymentTime).format("DD/MM/YYYY") : undefined}</span>
                    {` ${trans("order_payment.by")}`} <span className="font-bold">{cash?.methodDetail?.name}</span>
                    {`, ${trans("package-detail.note")} :`} <span className="font-bold">{cash?.note}</span>
                </div>
            )
        } else if (method === "pre_paid_by_bank_transfer") {
            const bank = find(detailOrder?.paymentTransactions, { method: "pre_paid_by_bank_transfer" })
            return (
                <div className="font-medium">
                    <span className="font-bold">{trans("order_payment.pre_paid_by_bank")}</span>
                    {` - ${trans("order_payment.customer_prepaid")}`} <span className="font-bold">{prePaidAmountRegex(bank?.prePaidAmount)}</span>
                    {` ${trans("order_payment.on")}`}{" "}
                    <span className="font-bold">{bank?.paymentTime ? moment(bank?.paymentTime).format("DD/MM/YYYY") : undefined}</span>
                    {` ${trans("order_payment.by")}`} <span className="font-bold">{bank?.methodDetail?.name}</span>
                    {`, ${trans("order_payment.bank")}:`} <span className="font-bold">{bank?.bankName}</span>
                    {`, ${trans("order_payment.bank_account_number")}:`} <span className="font-bold">{bank?.bankAccount}</span>
                    {`, ${trans("package-detail.note")}:`} <span className="font-bold">{bank?.note}</span>
                </div>
            )
        } else {
            return "---"
        }
    }

    const handleSyncOrder = () => {
        dispatch(syncOrderToM28({ orderCode: detailOrder?.code })).then((result) => {
            console.log("🚀 ~ file: Header.tsx:82 ~ dispatch ~ result:", result)
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                dispatch(getOrderDetail(detailOrder?.code || ""))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                dispatch(getOrderDetail(detailOrder?.code || ""))
            }
        })
    }
    return (
        <Card className="space-layout">
            <Row gutter={[15, 15]}>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="font-medium m-0">{trans("order.code")}</p>
                    <div>{detailOrder?.code}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="font-medium m-0">{trans("order.campaign")}</p>
                    <div>{detailOrder?.campaign?.name || ""}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="font-medium m-0">{trans("order_detail.ads")}</p>
                    <div>{detailOrder?.ads?.name || ""}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="font-medium m-0">{trans("order_detail.due_date")}</p>
                    <div>{detailOrder?.deliveryDate ? formatDateFull(detailOrder?.deliveryDate) : "---"}</div>
                </Col>
                <Col span={18}>
                    <Row gutter={[15, 15]}>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}>
                            <p className="font-medium m-0">{trans("order_detail.status")}</p>
                            {SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_STATUS) && STATUS_CAN_UPDATE.includes(detailOrder?.status || "") ? (
                                <Select
                                    loading={loadingUpdateStatus}
                                    value={detailOrder?.status}
                                    className="block w-60"
                                    onChange={(e) => {
                                        onChangeStatus(e)
                                    }}>
                                    {orderStatuses.map((x, index: number) => (
                                        <Select.Option
                                            key={x.code}
                                            value={x.code}
                                            disabled={
                                                index < orderStatuses.findIndex((x: any) => x.code === detailOrder?.status) ||
                                                ![...STATUS_CAN_UPDATE, "CANCELLED"].includes(x.code)
                                            }>
                                            {" "}
                                            {trans(`order_status.${x?.code}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ) : (
                                <div>{trans(`order_status.${detailOrder?.status}`)}</div>
                            )}
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}>
                            <p className="font-medium m-0">{trans("order_detail.created_time")}</p>
                            <div>{detailOrder?.createdAt ? formatDateFull(detailOrder?.createdAt) : ""}</div>
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}>
                            <p className="font-medium m-0">{trans("order_detail.created_by")}</p>
                            <div>{detailOrder?.createdBy}</div>
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}>
                            <p className="font-medium m-0">{trans("order_detail.tracking_number")}</p>
                            <div>{detailOrder?.trackingNumber || "---"}</div>
                        </Col>
                        {SecurityService.can(ORDER_SCOPE.ORDER_DETAIL_SYNC_TO_POS) &&
                            !detailOrder?.existedMapping &&
                            detailOrder?.status !== ORDER_STATUS.DRAFT && (
                                <Col
                                    xs={24}
                                    sm={12}
                                    md={8}
                                    lg={8}>
                                    <Button
                                        loading={loadingSync}
                                        onClick={handleSyncOrder}>
                                        {trans("order.sync_to_pos")}
                                    </Button>
                                </Col>
                            )}
                    </Row>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("order_payment.confirmation")}</p>
                    {paymentConfirmation(paymentMethod)}
                </Col>
            </Row>
        </Card>
    )
}
