import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { Filter } from "./Filter"
import { List } from "./ListProduct"
import { useSearchParams } from "react-router-dom"
import { callGetAllProductGroup, fetchListProduct } from "@reducer/version2/product.reducer"
import { PAGINATION } from "@config/constant"

export const Products: React.FC = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("products.product")))
        dispatch(callGetAllProductGroup())
    }, [dispatch])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        delete params.createdAt
        dispatch(
            fetchListProduct({
                ...params,
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                sort: "createdAt:desc",
            })
        )
    }, [dispatch, searchParams])
    return (
        <div className="">
            <Filter />
            <List />
        </div>
    )
}
