import { FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { IReportCallLarge } from "@domain/version2/Report"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetReportCallStatus } from "@reducer/version2/report.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Card, message } from "antd"
import { find, first, groupBy, isEmpty, sumBy } from "lodash"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
interface Props {
    currentTab: string 
}
export const CallReport: React.FC<Props> = ({currentTab}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const userInfo = SecurityService.getUser()
    const loading = useAppSelector((state) => state.report.loading)
    const listReportCallStatus = useAppSelector((state) => state.report.reportCallStatus)
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        setSearchParams({
            ...params,
            ...(searchParams.has("fromDate")
                ? { fromDate: params?.fromDate }
                : { fromDate: moment(moment().subtract(30, "days").format(FORMAT_DATE_BEGIN)).toISOString() }),
            ...(searchParams.has("toDate") ? { toDate: params?.toDate } : { toDate: moment(moment().format(FORMAT_DATE_END)).toISOString() }),
            ...(searchParams.has("telesales") ? { telesales: params?.telesales } : { telesales: userInfo?.sub }),
        })
    }, [dispatch, currentTab])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        if (params.tab === "call" && searchParams.has('fromDate') && searchParams.has('toDate')) {
            delete params.tab
            delete params.createdAt
            dispatch(
                callGetReportCallStatus({
                    ...params,
                })
            ).then((result: any) =>{
                if(result?.payload?.status !== 200){
                    message.error(result?.payload?.response?.data?.message)
                }

            })
        }
    }, [dispatch, searchParams])
    const defaultCallStatus = [
        { key: "new", title: trans("report.call_new"), children: [], total: 0 },
        { key: "agreed", title: trans("report.agreed"), children: [], total: 0 },
        { key: "on_process", title: trans("report.on_process"), children: [], total: 0 },
        { key: "declined", title: trans("report.declined"), children: [], total: 0 },
    ]
    const listStep = useMemo(() => {
        if (!isEmpty(listReportCallStatus)) {
            const listGroups: any = groupBy(listReportCallStatus, "step")
            const findChild: any[] = defaultCallStatus?.map((item) => ({
                ...item,
                children: !isEmpty(listGroups) ? listGroups[item.key] : [],
                total: !isEmpty(listGroups) ? find(listGroups['total'], {code: 'total'})?.count : 0,
            }))
            const finalReport: any[] = !isEmpty(findChild) ? findChild?.map((item: any) =>({
                ...item,
                children: item?.children?.map((iChild: any) =>({
                    ...iChild,
                    percent: item?.total !== 0 && iChild?.count !== 0 ? (iChild?.count/item?.total)*100 : 0
                }))
            })) : []
            return finalReport
        }
        return []
    }, [listReportCallStatus, defaultCallStatus])
    return (
        <Card className="space-layout" loading={loading}>
        <div className="grid grid-cols-10 border-b pb-2">
            <div className="col-span-2 font-semibold">{trans("report.call_step")}</div>
            <div className="col-span-4 font-semibold">{trans("report.call_status")}</div>
            <div className="col-span-2 font-semibold">{trans("report.contact")}</div>
            <div className="col-span-2 font-semibold">{trans("report.percentage")} %</div>
        </div>
        {listStep?.map((item: IReportCallLarge, index) => (
            <div
                key={index}
                className="grid grid-cols-10 border-b pb-2">
                <div className="col-span-2">
                    <p className="m-0">{item?.title}</p>
                    {Array.from({ length: item?.children?.length - 1 })?.map((_, iChildIndex) => (
                        <p
                            className="m-0"
                            key={iChildIndex}>
                            -
                        </p>
                    ))}
                    <p className="m-0 font-semibold">{trans("report.sub_total")}</p>
                </div>
                <div className="col-span-4">
                    {item?.children?.map((iChild, iChildIndex) => (
                        <p
                            className="m-0"
                            key={iChildIndex}>
                            {iChild?.name}
                        </p>
                    ))}
                </div>
                <div className="col-span-2">
                    {item?.children?.map((iChild, iChildIndex) => (
                        <p
                            className="m-0"
                            key={iChildIndex}>
                            {iChild?.count}
                        </p>
                    ))}
                    <p className="m-0 font-semibold">{sumBy(item?.children, 'count')}</p>
                </div>
                <div className="col-span-2">
                    {item?.children?.map((iChild, iChildIndex) => (
                        <p
                            className="m-0"
                            key={iChildIndex}>
                            {parseFloat(iChild?.percent?.toString() || '')?.toFixed(1)} %
                        </p>
                    ))}
                    <p className="m-0 font-semibold">{parseFloat(sumBy(item?.children, 'percent')?.toString() || '')?.toFixed(1) }</p>
                </div>
            </div>
        ))}
        <div className="grid grid-cols-10 border-b pb-2">
            <div className="col-span-2 font-semibold">{trans("report.total")}</div>
            <div className="col-span-4 font-semibold" />
            <div className="col-span-2 font-semibold">{first(listStep)?.total}</div>
            <div className="col-span-2 font-semibold">{first(listStep)?.total !== 0 ? '100%' : '0%'}</div>
        </div>
    </Card>
    )
}
