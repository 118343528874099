import DeliveryCaseApi from "@api/version2/DeliveryCase"
import { PAGINATION } from "@config/constant"
import { IDeliveryCase, IDeliveryCaseAssignee, IDeliveryCaseForm, IDeliveryCaseQuery, IResponseImport } from "@domain/version2/DeliveryCase"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
interface State {
    isShowImport: boolean
    listDeliveryCase: IDeliveryCase[]
    loadingList: boolean
    loadingImport: boolean
    dataResponseImport: IResponseImport
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
    deliveryCaseAssignees: IDeliveryCaseAssignee[]
    loadingAssignee: boolean
    loadingUpdate: boolean
}

const initState: State = {
    isShowImport: false,
    listDeliveryCase: [],
    loadingList: false,
    loadingImport: false,
    dataResponseImport: {},
    pagination: {
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    deliveryCaseAssignees: [],
    loadingAssignee: false,
    loadingUpdate: false
}

export const changeVisibleDeliveryCaseImport = createAction<boolean>("DELIVERY_CASE.CHANGE_VISIBLE_IMPORT")

export const callGetListDeliveryCase = createAsyncThunk("DELIVERY_CASE.GET_LIST", async (params: IDeliveryCaseQuery, thunkApi) => {
    try {
        return await DeliveryCaseApi.getDeliveryCases(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callImportDeliveryCase = createAsyncThunk("DELIVERY_CASE.IMPORT", async (payload: { file: any }, thunkApi) => {
    try {
        return await DeliveryCaseApi.importDeliveryCases(payload.file)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetAssignee = createAsyncThunk("DELIVERY_CASE.GET_ASSIGNEE", async (_, thunkApi) => {
    try {
        return await DeliveryCaseApi.getDeliveryCaseAssignee()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateAssignee = createAsyncThunk("DELIVERY_CASE.UPDATE_ASSIGNEE", async (payload: { code: string, body: { assignee: string } }, thunkApi) => {
    try {
        return await DeliveryCaseApi.updateAssignee(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateDeliveryCase = createAsyncThunk("DELIVERY_CASE.UPDATE", async (payload: { code: string, body: IDeliveryCaseForm }, thunkApi) => {
    try {
        return await DeliveryCaseApi.updateDeliveryCase(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const deliveryCaseReducer = createReducer(initState, (builder) => {
    builder
        .addCase(changeVisibleDeliveryCaseImport, (state, action: PayloadAction<boolean>) => {
            state.isShowImport = action.payload
        })
    builder
        .addCase(callImportDeliveryCase.pending, (state) => {
            state.loadingImport = true
            state.dataResponseImport = {}
        })
        .addCase(callImportDeliveryCase.fulfilled, (state, { payload }) => {
            state.loadingImport = false
            state.dataResponseImport = payload.data
        })
        .addCase(callImportDeliveryCase.rejected, (state) => {
            state.loadingImport = false
            state.dataResponseImport = {}
        })
    builder
        .addCase(callGetListDeliveryCase.pending, (state) => {
            state.loadingList = true
        })
        .addCase(callGetListDeliveryCase.fulfilled, (state, { payload }) => {
            state.listDeliveryCase = payload?.data
            state.pagination.current = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callGetListDeliveryCase.rejected, (state) => {
            state.listDeliveryCase = []
            state.loadingList = false
        })
    builder
        .addCase(callGetAssignee.pending, (state) => {
            state.deliveryCaseAssignees = []
        })
        .addCase(callGetAssignee.fulfilled, (state, { payload }) => {
            state.deliveryCaseAssignees = payload.data
        })
        .addCase(callGetAssignee.rejected, (state) => {
            state.deliveryCaseAssignees = []
        })
    builder
        .addCase(callUpdateAssignee.pending, (state) => {
            state.loadingAssignee = true
        })
        .addCase(callUpdateAssignee.fulfilled, (state) => {
            state.loadingAssignee = false
        })
        .addCase(callUpdateAssignee.rejected, (state) => {
            state.loadingAssignee = true
        })
    builder
        .addCase(callUpdateDeliveryCase.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateDeliveryCase.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateDeliveryCase.rejected, (state) => {
            state.loadingUpdate = true
        })
})
