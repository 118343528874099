import VendorApi from "@api/version2/VendorApi"
import { IVendor } from "@domain/version2/Vendor"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    vendorSuggests: IVendor[]
}
const initState: State = {
    vendorSuggests: [],
}
export const callGetVendorSuggest = createAsyncThunk("VENDOR.GET_VENDOR", async (params: { codeOrName?: string; countryCode?: string }, thunkApi) => {
    try {
        return await VendorApi.getVendorSuggest(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const vendorReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetVendorSuggest.pending, (state) => {
            state.vendorSuggests = []
        })
        .addCase(callGetVendorSuggest.rejected, (state) => {
            state.vendorSuggests = []
        })
        .addCase(callGetVendorSuggest.fulfilled, (state, { payload }) => {
            state.vendorSuggests = payload.data
        })
})
