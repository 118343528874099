// import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { SearchOutlined } from "@ant-design/icons"
import moment from "moment"
import { FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.skuCombo.loadingListCombo)
    const listComboCategories = useAppSelector((state) => state.categoriesVersion2.comboCategories)

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,

                ...(searchParams.has("createdFrom") &&
                    searchParams.has("createdTo") && {
                        createdAt: [moment(oldSearch?.createdAtFrom), moment(oldSearch?.createdAtTo)],
                    }),
            })
            if (Object.keys(oldSearch).length === 1 && searchParams.has("tab")) {
                formRef.resetFields()
            }
        } else {
            formRef.resetFields()
        }
    }, [])
    const handleFilter = (values: any) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                createdFrom: values?.createdAt ? moment(values?.createdAt[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
                createdTo: values?.createdAt ? moment(values?.createdAt[1].format(FORMAT_DATE_END)).toISOString() : undefined,
            }
            params.tab = "skuCombo"
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={handleFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("skuCombo.name_code")}
                            name="keyword">
                            <Input
                                placeholder={trans("skuCombo.name_code")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("product.categories")}
                            name="category">
                            <Select placeholder={trans("skuCombo.category_pl")}>
                                {listComboCategories?.map((el: any) => (
                                    <Select.Option
                                        key={el.id}
                                        value={el.code}>
                                        {el.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("products.vendor_created_at")}
                            name="createdAt">
                            <DatePicker.RangePicker placeholder={[trans("order_list.from"), trans("order_list.to")]} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingList}
                            className="mg-r-10 _filter_button_filter">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({ tab: "skuCombo" })
                            }}
                            loading={loadingList}
                            className="mg-r-10 _filter_button_clear">
                            {trans("contact.clear")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
