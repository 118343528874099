import { IGroupTelesalesQuery } from "@domain/version2/Telesales"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, Input, Row, Select } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { SearchOutlined } from "@ant-design/icons"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetListLeaderSuggest } from "@reducer/version2/telesales-group.reducer"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const listLeader = useAppSelector((state) => state.telesalesGroup.listLeader)
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.telesalesGroup.loadingList)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("admins") && {
                    admins: oldSearch?.admins?.split(","),
                }),
            })
            if (Object.keys(oldSearch).length === 1 && searchParams.has("tab")) {
                formRef.resetFields()
            }
        } else {
            formRef.resetFields()
        }
    }, [])

    const onFilter = (values: IGroupTelesalesQuery) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                admins: values?.admins ? values?.admins?.join(",") : undefined,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "user":
                    dispatch(
                        callGetListLeaderSuggest({
                            username: searchText.trim(),
                        })
                    )
                    break

                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "user":
                    dispatch(
                        callGetListLeaderSuggest({
                            username: "",
                        })
                    )
                    break

                default:
                    break
            }
        }
    }
    return (
        <Card className="space-layout">
            <Form
                onFinish={onFilter}
                form={formRef}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("group.name_code")}
                            name="keyword">
                            <Input
                                placeholder={trans("group.name_code")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("group.leader")}
                            name="admins">
                            <Select
                                onBlur={() => onSearchAutocomplete("", "user")}
                                onSearch={(e) => {
                                    onSearchAutocomplete(e, "user")
                                }}
                                showSearch
                                mode="multiple"
                                placeholder={trans("group.leader_pl")}>
                                {listLeader?.map((item) => (
                                    <Select.Option
                                        key={item.id}
                                        value={item.username}>
                                        {item.username}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingList}
                            className="mg-r-10 _filter_button_clear">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            className="mg-r-10 _filter_button_filter"
                            loading={loadingList}
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({
                                    tab: "groups",
                                })
                            }}>
                            {trans("contact.clear")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
