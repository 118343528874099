import { ICampaignAdResponse } from "@domain/version2/CampaignAd"
import apiClient from "@util/ApiClient"

export default class CampaignApi {
    static getCampaignAdsOfCampaign(id: number): Promise<ICampaignAdResponse> {
        return apiClient.get(`campaigns/${id}/campaign-ads`)
    }

    static getListCampaign(params: { keyword: string }): Promise<any> {
        return apiClient.get("campaigns/autocomplete", {
            params,
        })
    }
}
