import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Row, Table, Pagination, Button, Popover, Modal, message, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { AddGroup } from "./AddGroup"
import SecurityService from "@util/SecurityService"
import { filter } from "lodash"
import { callDeleteGroupInCombo, callGetDetailSkuCombo, callGetGroupsInCombo } from "@reducer/version2/skuCombo-detail.reducer"
import { IGroupOfCombo } from "@domain/version2/SkuCombo"
import { callGetTelesalesGroupsSuggest } from "@reducer/version2/telesales-group.reducer"
import { COMBO_SCOPE } from "@config/permission"

export const ListGroups: React.FC = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const [open, setOpen] = useState<boolean>(false)
    const total = useAppSelector((state) => state.skuComboDetail.paginationGroup.total)
    const currentPage = useAppSelector((state) => state.skuComboDetail.paginationGroup.currentPage)
    const totalPage = useAppSelector((state) => state.skuComboDetail.paginationGroup.totalPage)
    const pageSize = useAppSelector((state) => state.skuComboDetail.paginationGroup.pageSize)
    const groupsTelesales = useAppSelector((state) => state.skuComboDetail.groupsOfCombo)
    const loadingList = useAppSelector((state) => state.skuComboDetail.loadingListGroup)
    const loadingDeleteGroup = useAppSelector((state) => state.skuComboDetail.loadingDeleteGroup)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        id &&
            dispatch(
                callGetGroupsInCombo({
                    code: id,
                    params: {
                        ...params,
                        pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                        page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                    },
                })
            )
    }, [dispatch, searchParams])

    const confirmDeleteGroups = (record: IGroupOfCombo) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <ExclamationCircleOutlined />,
            okText: trans("button.delete"),
            cancelText: trans("button.cancel"),
            onOk: () => {
                id &&
                    dispatch(
                        callDeleteGroupInCombo({
                            code: id,
                            body: {
                                groupCodes: [record?.groupCode],
                            },
                        })
                    ).then((result: any) => {
                        if (result?.payload?.status === 200) {
                            message.success(trans("message.success"))
                            const params = Object.fromEntries(searchParams.entries())
                            dispatch(
                                callGetGroupsInCombo({
                                    code: id,
                                    params: {
                                        ...params,
                                        pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                        page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                    },
                                })
                            )
                            dispatch(callGetDetailSkuCombo(id))
                        } else {
                            message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                        }
                    })
            },
        })
    }
    const columns: ColumnsType<IGroupOfCombo> = [
        {
            title: trans("group.code"),
            key: "code",
            width: 120,
            fixed: "left",
            render: (record: IGroupOfCombo) => <p className="mb-0">{record?.group?.code}</p>,
        },
        {
            title: trans("group.name"),
            key: "name",
            width: 150,
            render: (record: IGroupOfCombo) => <p className="mb-0">{record?.group?.name}</p>,
        },
        {
            title: trans("telesales.title"),
            key: "telesales",
            width: 150,
            render: (record: IGroupOfCombo) => <p className="mb-0">{record?.group?.totalTelesales}</p>,
        },
        {
            title: trans("group.leader"),
            key: "admin",
            width: 100,
            render: (record: IGroupOfCombo) => <p className="mb-0">{record?.group?.admin}</p>,
        },
        {
            title: trans("table.added_by"),
            dataIndex: "createdBy",
            key: "created_by",
            width: 100,
        },
        {
            title: trans("table.added_at"),
            dataIndex: "createdAt",
            key: "created_at",
            render: (date: string) => (date ? formatDateFull(date) : ""),
            width: 120,
        },
        {
            title: trans("table.action"),
            dataIndex: SecurityService.can(COMBO_SCOPE.COMBO_GROUP_UPDATE) ? "action" : "disabledAction",
            key: SecurityService.can(COMBO_SCOPE.COMBO_GROUP_UPDATE) ? "action" : "disabledAction",
            width: 80,
            render: (action: string, record: IGroupOfCombo) =>
                SecurityService.can(COMBO_SCOPE.COMBO_GROUP_UPDATE) && (
                    <Button
                        loading={loadingDeleteGroup}
                        type="link"
                        icon={<i className="fa-solid fa-trash"></i>}
                        onClick={() => confirmDeleteGroups(record)}
                    />
                ),
            fixed: "right",
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(COMBO_SCOPE.COMBO_GROUP_UPDATE) && (
                    <Popover
                        content={
                            <AddGroup
                                setClose={() => {
                                    const params = Object.fromEntries(searchParams.entries())
                                    if (id) {
                                        dispatch(
                                            callGetGroupsInCombo({
                                                code: id,
                                                params: {
                                                    ...params,
                                                    pageSize: searchParams.has("pageSize")
                                                        ? Number(searchParams.get("pageSize"))
                                                        : PAGINATION.DEFAULT_PAGE_SIZE,
                                                    page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                                },
                                            })
                                        )
                                        dispatch(callGetTelesalesGroupsSuggest({ query: "" }))
                                        dispatch(callGetDetailSkuCombo(id))
                                    }
                                    setOpen(false)
                                }}
                            />
                        }
                        title={trans("products.add_group")}
                        placement="left"
                        trigger="click"
                        open={open}
                        onOpenChange={() => setOpen((old) => !old)}>
                        <Button type="primary">{trans("products.add_group")}</Button>
                    </Popover>
                )
            }>
            <Table
                scroll={{
                    x: true,
                }}
                loading={loadingList}
                columns={filter(columns, (iFil: any) => iFil?.dataIndex !== "disabledAction")}
                dataSource={groupsTelesales}
                rowKey={(record) => record.groupCode}
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
