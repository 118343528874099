import { IOneSkuCombo } from "@domain/version2/SkuCombo"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { Col, Form, InputNumber, Popover, Row, Typography } from "antd"
import { isEmpty, sum } from "lodash"
import React from "react"
type Props = {
    currency: string
}
export const AddListSkuCombo: React.FC<Props> = ({ currency }) => {
    const detailSkuCombo = useAppSelector((state) => state.skuComboDetail.skuComboDetail)
    const adSkus = useAppSelector((state) => state.contactDetailVersion2.adSkus)

    const listHeader = [
        { title: trans("skuCombo.name_code"), span: 6, className: "" },
        { title: trans("contact-detail.quantity"), span: 6, className: "" },
        { title: `${trans("products.price")} (${currency})`, span: 6, className: "" },
        { title: `${trans("contact-detail.price")} (${currency})`, span: 6, className: "text-right" },
    ]
    return (
        <div className="mt-3">
            <div className="bg-gray-200 p-3 border-b-black">
                <Row gutter={15}>
                    {listHeader.map((item: { title: string; span: number; className: string }, index: number) => (
                        <Col
                            span={item.span}
                            className={item?.className}
                            key={index}>
                            <Typography.Text strong>{item.title}</Typography.Text>
                        </Col>
                    ))}
                </Row>
            </div>
            <Form.List name="combos">
                {(fields) => (
                    <div>
                        {fields.map(({ name, ...restField }) => (
                            <Row
                                className="p-2 border-b"
                                key={name}
                                gutter={15}>
                                <Col span={6}>
                                    <Popover
                                        content={
                                            <>
                                                {detailSkuCombo?.skus?.map((item: IOneSkuCombo) => (
                                                    <ul key={item.id}>
                                                        <li className="ml-3 list-disc">{item?.sku?.product?.name || ""}</li>
                                                        <li className="ml-3 list-none">
                                                            {item?.sku?.variant ? Object?.values(item?.sku?.variant)?.join("/") : ""}({item?.sku?.sku || ""})
                                                        </li>
                                                        <li className="ml-3 list-none">
                                                            {item?.quantity || 0} {trans("campaign.items")}
                                                        </li>
                                                    </ul>
                                                ))}
                                            </>
                                        }>
                                        <p className="m-0 cursor-pointer hover:text-blue-400">
                                            {detailSkuCombo?.name || ""} - {detailSkuCombo?.code || ""}
                                        </p>
                                    </Popover>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "quantity"]}
                                        rules={[{ required: true, message: trans("message.required") }]}>
                                        <InputNumber
                                            placeholder={trans("contact-detail.quantity")}
                                            parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "unitPrice"]}
                                        rules={[{ required: true, message: trans("message.required") }]}>
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            placeholder={trans("contact-detail.price")}
                                            parser={(value: any) =>
                                                value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                            }
                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) =>
                                            pre?.combos[name]?.quantity !== next?.combos[name]?.quantity ||
                                            pre?.combos[name]?.unitPrice !== next?.combos[name]?.unitPrice
                                        }>
                                        {({ getFieldValue }) => {
                                            const price =
                                                Number(getFieldValue(["combos", name, "quantity"])) * Number(getFieldValue(["combos", name, "unitPrice"]))
                                            return <Typography.Text className="block text-right">{convertNumberToCurrency(price)}</Typography.Text>
                                        }}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </div>
                )}
            </Form.List>

            <Row
                className="mt-3"
                justify="end">
                <Col
                    span={14}
                    className="flex mb-3">
                    <Typography.Text className="w-40 mr-1">{trans("contact-detail.total_quantity")}:</Typography.Text>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.combos !== next.combos}>
                        {({ getFieldValue }) => {
                            const listQuantity: any = getFieldValue("combos")?.map((item: any) => item?.quantity || 0)
                            let totalQuantity = sum(listQuantity)
                            if (adSkus) {
                                adSkus.forEach((x) => {
                                    totalQuantity = Number(totalQuantity) + Number(x.quantity || 0)
                                })
                            }
                            return <Typography.Text>{convertNumberToCurrency(totalQuantity)}</Typography.Text>
                        }}
                    </Form.Item>
                </Col>
                <Col
                    span={14}
                    className="flex mb-3">
                    <Typography.Text className="w-40 mr-1">
                        {trans("order_detail.total_amount")} ({currency}):
                    </Typography.Text>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.combos !== next.combos}>
                        {({ getFieldValue }) => {
                            const listAmount: any = getFieldValue("combos")?.map((item: any) => Number(item?.quantity) * Number(item?.unitPrice))
                            let totalAmount = !isEmpty(listAmount) ? sum(listAmount) : 0
                            if (adSkus) {
                                adSkus.forEach((x) => {
                                    totalAmount = Number(totalAmount) + Number(x.quantity || 0) * Number(x.price || 0)
                                })
                            }
                            return <Typography.Text>{convertNumberToCurrency(totalAmount)}</Typography.Text>
                        }}
                    </Form.Item>
                </Col>
                <Col
                    span={14}
                    className="flex">
                    <Typography.Text className="w-40 mr-1">
                        {trans("order.delivery_fee")} ({currency}):
                    </Typography.Text>
                    <Form.Item name="deliveryFee">
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            placeholder={trans("order.delivery_fee")}
                            parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    span={14}
                    className="flex">
                    <Typography.Text className="w-40 mr-1">
                        {trans("order_detail.total_payment")} ({currency})<span className="text-red-500">*</span>:
                    </Typography.Text>
                    <Form.Item
                        name="totalPayment"
                        rules={[{ required: true, message: trans("message.required") }]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            placeholder={trans("order_detail.total_payment")}
                            parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}
