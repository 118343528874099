import React, { useMemo, useEffect } from "react"
import { Card, Pagination, Row, Table, Select, message } from "antd"
import { trans } from "@resources/localization"
import { FORMAT_DATE_BEGIN, FORMAT_DATE_END, PAGINATION } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { useSearchParams } from "react-router-dom"
import { IReportTelesales, ISLASettings } from "@domain/version2/Report"
import { find, isEmpty } from "lodash"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetListReportTelesales, callUpdateSlaSettingOfTelesales } from "@reducer/version2/report.reducer"
import SecurityService from "@util/SecurityService"
import { REPORT_SCOPE } from "@config/permission"
import moment from "moment"
interface Props {
    currentTab: string
}
export const TelesalesReport: React.FC<Props> = ({ currentTab }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const size: number = useAppSelector((state) => state.report.pagination.size)
    const current: number = useAppSelector((state) => state.report.pagination.current)
    const total: number = useAppSelector((state) => state.report.pagination.total)
    const totalPage: number = useAppSelector((state) => state.report.pagination.totalPage)
    const loading = useAppSelector((state) => state.report.loadingList)
    const listReportPerformanceByTelesales = useAppSelector((state) => state.report.reportTelesales)
    const listSlaSetting = useAppSelector((state) => state.settingSla.slaSettings)
    const loadingUpdate = useAppSelector((state) => state.report.loadingUpdate)
    const userInfo = SecurityService.getUser()

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        setSearchParams({
            ...params,
            ...(searchParams.has("fromDate")
                ? { fromDate: params?.fromDate }
                : { fromDate: moment(moment().subtract(30, "days").format(FORMAT_DATE_BEGIN)).toISOString() }),
            ...(searchParams.has("toDate") ? { toDate: params?.toDate } : { toDate: moment(moment().format(FORMAT_DATE_END)).toISOString() }),
            ...(searchParams.has("telesales") ? { telesales: params?.telesales } : { telesales: userInfo?.sub }),
        })
    }, [dispatch, currentTab])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        if (params.tab === "telesales" && searchParams.has("fromDate") && searchParams.has("toDate")) {
            delete params.tab
            delete params.createdAt
            dispatch(
                callGetListReportTelesales({
                    ...params,
                    size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                })
            ).then((result: any) => {
                if (result?.payload?.status !== 200) {
                    message.error(result?.payload?.response?.data?.message)
                }
            })
        }
    }, [dispatch, searchParams])

    const listTelesales = useMemo(() => {
        if (!isEmpty(listReportPerformanceByTelesales)) {
            const percentAgreed: any[] = !isEmpty(listReportPerformanceByTelesales)
                ? listReportPerformanceByTelesales?.map((item: any) => ({
                      ...item,
                      agreedRate: item?.agreedContacts !== 0 && item?.assignedContacts !== 0 ? (item?.agreedContacts / item?.assignedContacts) * 100 : 0,
                      deliveredRate: item?.deliveredOrders !== 0 && item?.agreedEvents !== 0 ? (item?.deliveredOrders / item?.agreedEvents) * 100 : 0,
                  }))
                : []
            return percentAgreed
        }
    }, [listReportPerformanceByTelesales])

    const updateSlaSettingOfTelesales = (record: any, value: string) => {
        dispatch(
            callUpdateSlaSettingOfTelesales({
                id: record?.telesales,
                body: { slaSettingCode: value },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                const params = Object.fromEntries(searchParams.entries())
                if (params.tab === "telesales") {
                    delete params.tab
                    delete params.createdAt
                    dispatch(
                        callGetListReportTelesales({
                            ...params,
                            size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                            page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                        })
                    )
                }
                message.success(trans("message.success"))
            }
        })
    }

    const columns: any[] = [
        {
            title: trans("report.telesales"),
            className: "bold-400",
            width: 100,
            dataIndex: "telesales",
            key: "telesales",
            align: "left",
        },
        {
            title: trans("report.email"),
            className: "bold-400",
            width: 150,
            dataIndex: "email",
            key: "email",
            algin: "left",
        },
        {
            title: trans("report.assigned"),
            className: "bold-400",
            width: 60,
            dataIndex: "assignedContacts",
            key: "assignedContacts",
            align: "left",
        },
        {
            title: trans("report.agreed"),
            className: "bold-400",
            width: 60,
            dataIndex: "agreedContacts",
            key: "agreedContacts",
            align: "left",
        },
        {
            title: trans("report.agreed_rate"),
            className: "bold-400",
            width: 60,
            dataIndex: "agreedRate",
            key: "agreedRate",
            align: "left",
            render: (agreedRate: any) => (agreedRate ? `${parseFloat(agreedRate?.toString() || "")?.toFixed(1)} %` : 0),
        },
        {
            title: trans("report.delivered_rate"),
            className: "bold-400",
            width: 60,
            dataIndex: "deliveredRate",
            key: "deliveredRate",
            align: "left",
            render: (deliveredRate: number) => (deliveredRate ? `${parseFloat(deliveredRate?.toString() || "")?.toFixed(1)} %` : 0),
        },
        {
            title: trans("report.sla_code"),
            className: "bold-400",
            width: 150,
            align: "left",
            render: (record: IReportTelesales) => (
                <>
                    {SecurityService.can(REPORT_SCOPE.REPORT_SLA_UPDATE) ? (
                        <Select
                            loading={loadingUpdate || loading}
                            onChange={(value) => {
                                updateSlaSettingOfTelesales(record, value)
                            }}
                            defaultValue={record?.slaSettings?.code}
                            className="w-full">
                            {listSlaSetting
                                ?.filter((el) => el.isActive)
                                ?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    ) : (
                        <p className="mb-0">{find(listSlaSetting, { code: record?.slaCode })?.name || ""}</p>
                    )}
                </>
            ),
        },
        {
            title: trans("report.maximum_stock"),
            className: "bold-400",
            width: 100,
            dataIndex: "slaSettings",
            key: "slaSettings",
            align: "left",
            render: (value: ISLASettings) => (value ? value?.maximumOfStock : ""),
        },
        {
            title: trans("report.get_contact_quantity"),
            className: "bold-400",
            width: 100,
            dataIndex: "slaSettings",
            key: "slaSettings",
            align: "left",
            render: (value: ISLASettings) => (value ? value?.quantityContact : ""),
        },
    ]
    const onHandleChange = (newPage: number, newSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !oldSearch.pageSize || Number(oldSearch.pageSize) === newSize ? newPage : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: newSize,
        }
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${current}/${totalPage}(${total})` : ""}>
            <Table
                rowKey={"id"}
                scroll={{
                    x: true,
                }}
                loading={loading}
                columns={columns}
                dataSource={listTelesales}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    current={current}
                    pageSize={size}
                    total={total}
                    onChange={onHandleChange}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
