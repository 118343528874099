import React from "react"
import { Card, Col, Row } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"

export const BasicInfo = () => {
    const contactDetail = useAppSelector((state) => state.contactDetailVersion2.contactDetail)

    const renderInfo = (key: string, value: any, valueWrapperClassName = "") => {
        return (
            <div>
                <p className="font-medium m-0">{key}:</p>
                <p className={`text ${valueWrapperClassName}`}>{value}</p>
            </div>
        )
    }
    return (
        <Card
            className="space-layout custom-bottom"
            title={contactDetail?.name}>
            <Row gutter={20}>
                <Col span={4}>
                    {renderInfo(trans("contact-detail.campaign"), `${contactDetail?.campaignDetail?.code} - ${contactDetail?.campaignDetail?.name}`)}
                </Col>
                <Col span={4}>{renderInfo(trans("order_list.vendor"), `${contactDetail?.vendor} - ${contactDetail.vendorName}`)}</Col>
                <Col span={4}>{renderInfo(trans("contact.created_at"), contactDetail?.createdAt ? formatDateFull(contactDetail?.createdAt) : "---")}</Col>
                <Col span={4}>{renderInfo(trans("contact-detail.created_by"), contactDetail?.createdBy || "---")}</Col>
                <Col span={4}>
                    {renderInfo(
                        trans("contact-detail.contact_state"),
                        `${contactDetail?.contactStatus?.name || "---"}-${contactDetail?.contactStatus?.description || "---"}`
                    )}
                </Col>

                {(SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_GROUP_VIEW) || SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_VIEW_ALL)) && (
                    <Col span={4}>
                        <p className="font-medium m-0">{trans("contact.remark")}:</p>
                        {contactDetail?.isSpam ? <p className="ml-1">{trans("contact.spam")}</p> : <p className="ml-1">{trans("contact.blanks")}</p>}
                    </Col>
                )}
            </Row>
        </Card>
    )
}
