import React from "react"
import { Dashboard } from "@screen/Dashboard"
import { Login } from "@screen/Login"
import { Logout } from "@screen/Logout"
import { Authentication } from "@screen/Authentication"
import { AuthenticateRoute } from "@component/AuthenticateRoute"
//V1
import { Team } from "@screen/version2/Team"
import DetailGroupTelesales from "@screen/version2/Team/GroupTelesales/Detail"
import { Product } from "@screen/version2/Product"
import { DetailProduct } from "@screen/version2/Product/Products/Detail"
import { COMBO_SCOPE, CONTACT_SCOPE, DELIVERY_CASE_SCOPE, ORDER_SCOPE, PRODUCT_SCOPE, REPORT_SCOPE, SETTING_SCOPE, TELESALES_COPE } from "./permission"

//V2
import { Order } from "@screen/version2/Order"
import { OrderDetail } from "@screen/version2/Order/Detail"
import { Contact } from "@screen/version2/Contact"
import { ContactDetail } from "@screen/version2/Contact/Detail"
import { DeliveryCase } from "@screen/version2/DeliveryCase"
import { Report } from "@screen/version2/Report"
import { Settings } from "@screen/version2/Setting"
import { DetailSkuCombo } from "@screen/version2/Product/SkuCombo/Detail"

export interface IRouteConfig {
    name: string
    path: string
    component: JSX.Element
}

export const routes: Array<IRouteConfig> = [
    {
        name: "dashboard",
        path: "/",
        component: (
            <AuthenticateRoute>
                <Dashboard />
            </AuthenticateRoute>
        ),
    },
    {
        name: "product",
        path: "/product",
        component: (
            <AuthenticateRoute
                permissions={[
                    PRODUCT_SCOPE.PRODUCT_LIST_VIEW,
                    PRODUCT_SCOPE.PRODUCT_LIST_VIEW_ALL,
                    COMBO_SCOPE.COMBO_LIST_VIEW,
                    COMBO_SCOPE.COMBO_LIST_VIEW_ALL,
                ]}>
                <Product />
            </AuthenticateRoute>
        ),
    },
    {
        name: "product",
        path: "/product/:id",
        component: (
            <AuthenticateRoute permissions={[PRODUCT_SCOPE.PRODUCT_VIEW, PRODUCT_SCOPE.PRODUCT_VIEW_ALL]}>
                <DetailProduct />
            </AuthenticateRoute>
        ),
    },
    {
        name: "team",
        path: "/team",
        component: (
            <AuthenticateRoute permissions={[TELESALES_COPE.GROUP_VIEW_LIST, TELESALES_COPE.TELESALES_VIEW_LIST]}>
                <Team />
            </AuthenticateRoute>
        ),
    },
    {
        name: "team",
        path: "/team/group/:id",
        component: (
            <AuthenticateRoute permissions={[TELESALES_COPE.GROUP_DETAIL_VIEW]}>
                <DetailGroupTelesales />
            </AuthenticateRoute>
        ),
    },
    {
        name: "order",
        path: "/orders",
        component: (
            <AuthenticateRoute permissions={[ORDER_SCOPE.ORDER_LIST_VIEW, ORDER_SCOPE.ORDER_LIST_VIEW_ALL]}>
                <Order />
            </AuthenticateRoute>
        ),
    },
    {
        name: "order",
        path: "/orders/:id",
        component: (
            <AuthenticateRoute permissions={[ORDER_SCOPE.ORDER_DETAIL_VIEW, ORDER_SCOPE.ORDER_DETAIL_VIEW_ALL]}>
                <OrderDetail />
            </AuthenticateRoute>
        ),
    },
    {
        name: "login",
        path: "/login",
        component: <Login />,
    },
    {
        name: "logout",
        path: "/logout",
        component: <Logout />,
    },
    {
        name: "authentication",
        path: "/authentication",
        component: <Authentication />,
    },

    //v2
    {
        name: "contact",
        path: "/contacts",
        component: (
            <AuthenticateRoute permissions={[CONTACT_SCOPE.CONTACT_LIST_VIEW, CONTACT_SCOPE.CONTACT_LIST_GROUP_VIEW, CONTACT_SCOPE.CONTACT_LIST_VIEW_ALL]}>
                <Contact />
            </AuthenticateRoute>
        ),
    },
    {
        name: "contact",
        path: "/contacts/:id",
        component: (
            <AuthenticateRoute permissions={[CONTACT_SCOPE.CONTACT_DETAIL_VIEW, CONTACT_SCOPE.CONTACT_DETAIL_GROUPVIEW, CONTACT_SCOPE.CONTACT_DETAIL_VIEW_ALL]}>
                <ContactDetail />
            </AuthenticateRoute>
        ),
    },
    {
        name: "deliveryCase",
        path: "/delivery-case",
        component: (
            <AuthenticateRoute permissions={[DELIVERY_CASE_SCOPE.DELIVERY_CASE_VIEW, DELIVERY_CASE_SCOPE.DELIVERY_CASE_VIEW_ALL]}>
                <DeliveryCase />
            </AuthenticateRoute>
        ),
    },
    {
        name: "report",
        path: "/reports",
        component: (
            <AuthenticateRoute
                permissions={[
                    REPORT_SCOPE.CALL_REPORT_VIEW,
                    REPORT_SCOPE.CALL_REPORT_VIEW_ALL,
                    REPORT_SCOPE.SLA_REPORT_VIEW,
                    REPORT_SCOPE.SLA_REPORT_VIEW_ALL,
                ]}>
                <Report />
            </AuthenticateRoute>
        ),
    },
    {
        name: "setting",
        path: "/settings",
        component: (
            <AuthenticateRoute permissions={[SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_VIEW, SETTING_SCOPE.SETTING_SLA_VIEW]}>
                <Settings />
            </AuthenticateRoute>
        ),
    },
    {
        name: "product",
        path: "/skuCombo/:id",
        component: (
            <AuthenticateRoute permissions={[COMBO_SCOPE.COMBO_DETAIL_VIEW, COMBO_SCOPE.COMBO_DETAIL_VIEW_ALL]}>
                <DetailSkuCombo />
            </AuthenticateRoute>
        ),
    },
]
