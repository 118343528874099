import * as React from "react"
import { Row, Col, Card } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { formatDateMedium } from "@util/Common"

export const InfoProduct = () => {
    const productDetail = useAppSelector((state) => state.productDetail.productDetail)
    return (
        <Card className="space-layout custom-bottom" title={productDetail.name}>
            <Row gutter={15}>
                <Col xs={24} sm={8} md={3}  span={3}>
                    <p className="m-0 font-medium">{trans("telesales.group")}:</p>
                    <p className="m-0"> {productDetail.numberOfGroup || '---'}</p>
                </Col>
                <Col xs={24} sm={8} md={4} span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.product_code")}:</p>
                    <p className="m-0">{productDetail.code || '---'}</p>
                </Col>
                <Col xs={24} sm={8} md={5} span={5}>
                    <p className="m-0 font-medium">{trans("product.category")}:</p>
                    <p className="m-0">{productDetail?.productGroup?.name || '---'}</p>
                </Col>
                <Col xs={24} sm={8} md={4} span={4}>
                    <p className="m-0 font-medium">{trans("product.manufactory")}:</p>
                    <p className="m-0">{productDetail.manufactory || '---'}</p>
                </Col>
                <Col xs={24} sm={8} md={4} span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.createdBy")}:</p>
                    <p className="m-0">{productDetail.createdBy || '---'}</p>
                </Col>
                <Col xs={24} sm={8} md={4} span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.created_at")}:</p>
                    <p className="m-0">{productDetail?.createdAt ? formatDateMedium(productDetail?.createdAt) : "---"}</p>
                </Col>
            </Row>
        </Card>
    )
}
