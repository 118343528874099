import { ITelesalesQuery } from "@domain/version2/Telesales"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, Input, Row, Select } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { SearchOutlined } from "@ant-design/icons"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetTelesalesGroupsSuggest } from "@reducer/version2/telesales-group.reducer"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.telesales.loadingTelesales)
    const listGroups = useAppSelector((state) => state.telesalesGroup.telesalesGroupsSuggest)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("groupCodes") && {
                    groupCodes: oldSearch?.groupCodes?.split(","),
                }),
            })
            if (Object.keys(oldSearch).length === 1 && searchParams.has("tab")) {
                formRef.resetFields()
            }
        } else {
            formRef.resetFields()
        }
    }, [])

    const onFilterTelesales = (values: ITelesalesQuery) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                groupCodes: values?.groupCodes ? values?.groupCodes?.join(",") : undefined,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "group":
                    dispatch(
                        callGetTelesalesGroupsSuggest({
                            query: searchText.trim(),
                        })
                    )
                    break

                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "group":
                    dispatch(
                        callGetTelesalesGroupsSuggest({
                            query: "",
                        })
                    )
                    break

                default:
                    break
            }
        }
    }

    return (
        <Card className="space-layout">
            <Form
                layout="vertical"
                form={formRef}
                onFinish={onFilterTelesales}>
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("telesales.name")}
                            name="name">
                            <Input
                                placeholder={trans("telesales.name")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("telesales.email")}
                            name="email">
                            <Input
                                placeholder={trans("telesales.email")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("telesales.group_name")}
                            name="groupCodes">
                            <Select
                                onBlur={() => onSearchAutocomplete("", "group")}
                                onSearch={(e) => {
                                    onSearchAutocomplete(e, "group")
                                }}
                                showSearch
                                mode="multiple"
                                placeholder={trans("telesales.group_name_pl")}
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}>
                                {listGroups?.map((item) => (
                                    <Select.Option
                                        key={item.code}
                                        value={item.code}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button
                            type="primary"
                            className="mg-r-10 _filter_button_filter"
                            htmlType="submit"
                            loading={loadingList}>
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({})
                            }}
                            className="mg-r-10 _filter_button_clear"
                            loading={loadingList}>
                            {trans("contact.clear")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
