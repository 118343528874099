import SlaSettingApi from "@api/version2/SlaSettingApi"
import { PAGINATION } from "@config/constant"
import { IHistorySlaSetting, ISlaSetting, ISlaSettingRequest } from "@domain/version2/SlaSetting"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    slaSettings: ISlaSetting[]
    loadingSla: boolean
    loadingUpdate: boolean
    visibleSla: boolean
    historySlaSettings: IHistorySlaSetting[]
    loadingHistory: boolean
    pagination: {
        page: number
        pageSize: number
        total: number
        totalPage: number
    }
}
const initState: State = {
    slaSettings: [],
    loadingSla: false,
    loadingUpdate: false,
    visibleSla: false,
    historySlaSettings: [],
    loadingHistory: false,
    pagination: {
        page: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
}
export const callGetSlaSetting = createAsyncThunk("SETTING.GET_SLA", async (_, thunkApi) => {
    try {
        return await SlaSettingApi.getSlaSetting()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreateSlaSetting = createAsyncThunk("SETTING.CREATE_SLA", async (body: ISlaSettingRequest[], thunkApi) => {
    try {
        return await SlaSettingApi.postSlaSetting(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateSlaSetting = createAsyncThunk("SETTING.UPDATE_SLA", async (body: ISlaSettingRequest[], thunkApi) => {
    try {
        return await SlaSettingApi.patchSlaSetting(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const changeVisibleSlaHistory = createAction<boolean>("SETTING.VISIBLE_SLA_HISTORY")
export const callGetHistorySlaSetting = createAsyncThunk(
    "SETTING.SLA_HISTORY",
    async (params: { settingType: string; page: number; pageSize: number }, thunkApi) => {
        try {
            return await SlaSettingApi.getHistorySlaSetting(params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const slaSettingReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetSlaSetting.pending, (state) => {
            state.loadingSla = true
            state.slaSettings = []
        })
        .addCase(callGetSlaSetting.fulfilled, (state, { payload }) => {
            state.loadingSla = false
            state.slaSettings = payload?.data
        })
        .addCase(callGetSlaSetting.rejected, (state) => {
            state.loadingSla = true
            state.slaSettings = []
        })
    builder
        .addCase(callCreateSlaSetting.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callCreateSlaSetting.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callCreateSlaSetting.rejected, (state) => {
            state.loadingUpdate = false
        })
    builder
        .addCase(callUpdateSlaSetting.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateSlaSetting.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateSlaSetting.rejected, (state) => {
            state.loadingUpdate = false
        })
    builder.addCase(changeVisibleSlaHistory, (state, action: PayloadAction<boolean>) => {
        state.visibleSla = action.payload
    })
    builder
        .addCase(callGetHistorySlaSetting.pending, (state) => {
            state.loadingHistory = true
            state.historySlaSettings = []
        })
        .addCase(callGetHistorySlaSetting.fulfilled, (state, { payload }) => {
            state.loadingHistory = false
            state.historySlaSettings = payload.data
            state.pagination.page = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
        })
        .addCase(callGetHistorySlaSetting.rejected, (state) => {
            state.loadingHistory = false
            state.historySlaSettings = []
        })
})
