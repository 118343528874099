import * as React from "react"
import { Row, Col, Card } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { formatDateMedium } from "@util/Common"
import { find } from "lodash"

export const InfoProduct = () => {
    const skuComboDetail = useAppSelector((state) => state.skuComboDetail.skuComboDetail)
    const listCategories = useAppSelector((state) => state.categoriesVersion2.comboCategories)
    const vendor = useAppSelector((state) => state.vendor.vendorSuggests)[0]

    return (
        <Card
            className="space-layout custom-bottom"
            title={skuComboDetail.name}>
            <Row gutter={15}>
                <Col
                    xs={24}
                    sm={8}
                    md={3}
                    span={3}>
                    <p className="m-0 font-medium">{trans("telesales.group")}:</p>
                    <p className="m-0"> {skuComboDetail.numberOfGroup || "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("skuCombo.code")}:</p>
                    <p className="m-0">{skuComboDetail.code || "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("contact.vendor")}:</p>
                    <p className="m-0">{skuComboDetail.vendorCode ? `${skuComboDetail.vendorCode} - ${vendor?.name}` : "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={5}
                    span={5}>
                    <p className="m-0 font-medium">{trans("product.category")}:</p>
                    <p className="m-0">{skuComboDetail.categoryCode ? find(listCategories, { code: skuComboDetail?.categoryCode })?.name : "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.createdBy")}:</p>
                    <p className="m-0">{skuComboDetail.createdBy || "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.created_at")}:</p>
                    <p className="m-0">{skuComboDetail?.createdAt ? formatDateMedium(skuComboDetail?.createdAt) : "---"}</p>
                </Col>
            </Row>
        </Card>
    )
}
