import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callAddProductToGroup, callGetProductSuggest } from "@reducer/version2/telesales-group-detail.reducer"
import { trans } from "@resources/localization"
import { AutoComplete, Button, Col, Form, message, Row, Space, Spin } from "antd"
import { debounce } from "lodash"
import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"

type Props = {
    setClose: () => void
}
export const AddProduct: React.FC<Props> = ({ setClose }) => {
    const { id } = useParams()
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const productsSuggest = useAppSelector((state) => state.telesalesGroupDetail.productsSuggest)
    const loadingAdd = useAppSelector((state) => state.telesalesGroupDetail.loadingAddProduct)

    useEffect(() => {
        dispatch(
            callGetProductSuggest({
                code: id?.toString() || "",
                params: {
                    keyword: "",
                },
            })
        )
    }, [dispatch])

    const onSearchProducts = (searchText: string) => {
        if (searchText.trim().length >= 3) {
            dispatch(
                callGetProductSuggest({
                    code: id?.toString() || "",
                    params: {
                        keyword: searchText?.trim(),
                    },
                })
            )
        } else {
            dispatch(
                callGetProductSuggest({
                    code: id?.toString() || "",
                    params: {
                        keyword: "",
                    },
                })
            )
        }
    }

    const options = useMemo(
        () =>
            productsSuggest?.map((item) => ({
                value: item?.name,
                key: item?.code,
            })),
        [productsSuggest]
    )
    const addProduct = (values: { productCodes: string }) => {
        dispatch(
            callAddProductToGroup({
                code: id || "",
                body: {
                    productCodes: [values.productCodes],
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                formRef.resetFields()
                dispatch(
                    callGetProductSuggest({
                        code: id?.toString() || "",
                        params: {
                            keyword: "",
                        },
                    })
                )
                setClose()
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <div className="w-72">
            <Form
                form={formRef}
                layout="vertical"
                onFinish={addProduct}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="productCodes"
                            hidden></Form.Item>
                        <Form.Item
                            label={trans("product.name")}
                            name="productName"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <AutoComplete
                                notFoundContent={<Spin />}
                                allowClear
                                onSearch={debounce(onSearchProducts, 1000)}
                                placeholder={trans("product.name")}
                                options={options}
                                onSelect={(val: any, option: any) => {
                                    formRef.setFieldsValue({
                                        productCodes: option?.key,
                                    })
                                }}
                                onBlur={() => {
                                    if (!formRef.getFieldValue("productCodes")) {
                                        formRef.setFieldsValue({
                                            productName: "",
                                        })
                                        // dispatch(resetDataProduct([]))
                                    }
                                }}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Space>
                        <Button
                            type="default"
                            loading={loadingAdd}
                            onClick={() => {
                                formRef.resetFields()
                                setClose()
                            }}>
                            {trans("button.cancel")}
                        </Button>
                        <Button
                            type="primary"
                            loading={loadingAdd}
                            htmlType="submit"
                            className="ml-2">
                            {trans("button.add")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}
