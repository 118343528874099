import { PAGINATION } from "@config/constant"
import { IGroupRequest } from "@domain/version2/Telesales"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCreateGroupTelesales, callGetListGroup, changeModalAddGroup } from "@reducer/version2/telesales-group.reducer"
import { trans } from "@resources/localization"
import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd"
import React from "react"
import { useSearchParams } from "react-router-dom"
export const AddGroup: React.FC = () => {
    const [formRef] = Form.useForm()
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const visibleModal = useAppSelector((state) => state.telesalesGroup.visibleModalAdd)
    const listLeader = useAppSelector((state) => state.telesalesGroup.listLeader)
    const loadingCreate = useAppSelector((state) => state.telesalesGroup.loadingCreate)
    const createGroup = (values: IGroupRequest) => {
        dispatch(
            callCreateGroupTelesales({
                ...values,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                const params = Object.fromEntries(searchParams.entries())
                setTimeout(() => {
                    dispatch(
                        callGetListGroup({
                            ...params,
                            size: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                        })
                    )
                    formRef.resetFields()
                    dispatch(changeModalAddGroup(false))
                }, 500)
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <Modal
            visible={visibleModal}
            title={trans("group.add_title")}
            width={670}
            destroyOnClose
            onCancel={() => {
                formRef.resetFields()
                dispatch(changeModalAddGroup(false))
            }}
            footer={null}>
            <Form
                layout="vertical"
                form={formRef}
                onFinish={createGroup}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={trans("group.name")}
                            rules={[{ required: true, message: trans("message.required") }]}
                            name="name">
                            <Input
                                placeholder={trans("group.name_pl")}
                                maxLength={128}
                                onBlur={(e) => {
                                    formRef.setFieldsValue({
                                        name: e.target.value.trim(),
                                    })
                                }}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("group.code")}
                            name="code"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <Input
                                placeholder={trans("group.code_pl")}
                                maxLength={32}
                                onChange={(e: any) => {
                                    const text: string = e.target.value?.replace(/[-’/` ~!#*$@%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                                    formRef.setFieldsValue({
                                        code: text,
                                    })
                                }}
                                onBlur={(e) => {
                                    formRef.setFieldsValue({
                                        code: e.target.value.trim(),
                                    })
                                }}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("group.leader")}
                            name="admin"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    return (option?.children ?? "")?.includes(input)
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "")?.toLowerCase().localeCompare((optionB?.children ?? "")?.toLowerCase())
                                }}
                                placeholder={trans("placeholder.select_user")}>
                                {listLeader?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.username}>
                                        {item?.username}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Button
                        className="mr-2"
                        type="default"
                        loading={loadingCreate}
                        onClick={() => {
                            formRef.resetFields()
                            dispatch(changeModalAddGroup(false))
                        }}>
                        {trans("button.cancel")}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingCreate}>
                        {trans("button.add")}
                    </Button>
                </Row>
            </Form>
        </Modal>
    )
}
