import { AccessDenied } from "@component/AccessDenied"
import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { Notfound } from "@component/Notfound"
import { HOME_BREADCRUMB, PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callDetailTelesalesGroup, callGetTelesalesInGroup } from "@reducer/version2/telesales-group-detail.reducer"
import { trans } from "@resources/localization"
import { capitalizeFirstLetter } from "@util/Common"
import { Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { Header } from "./Header"
import ListTelesales from "./ListTelesales"
import { Products } from "./Products"
import { SkuCombo } from "./SkuCombo"

const DetailGroupTelesales = () => {
    const dispatch = useAppDispatch()
    const groupCode = useParams().id as string
    const [searchParams] = useSearchParams()
    const [statusShow, setStatusShow] = useState<number>(0)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("telesales.group_name")))
        dispatch(callDetailTelesalesGroup({ groupCode }))
        dispatch(
            callGetTelesalesInGroup({
                code: groupCode,
                params: {
                    ...Object.fromEntries(searchParams.entries()),
                    page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "createdAt:desc",
                },
            })
        ).then((result: any) => {
            setStatusShow(result?.payload?.response?.status)
        })
    }, [dispatch, searchParams])

    const items = [
        { label: trans("telesales.title"), key: "1", children: <ListTelesales /> },
        { label: trans("products.product"), key: "2", children: <Products /> },
        { label: trans("skuCombo.title"), key: "3", children: <SkuCombo /> },
    ]
    const detailTelesaleGroup = useAppSelector((state) => state.telesalesGroupDetail.telesalesGroup)

    return (
        <DefaultLayout loading={false}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            ...HOME_BREADCRUMB,
                            { name: trans("telesales.team"), link: "/team" },
                            { name: capitalizeFirstLetter(detailTelesaleGroup.name) },
                        ]}
                    />
                    <Header />
                    <Tabs
                        items={items}
                        className="nav-space nav-space-x"
                    />
                </>
            )}
        </DefaultLayout>
    )
}

export default DetailGroupTelesales
