import appConfig from "@config/app"
import apiClient from "@util/ApiClient"

export default class AdApi {
    static getSkus(adCode: string, params: any): Promise<any> {
        return apiClient.get(`ads/${adCode}/skus`, {
            params,
            baseURL: appConfig.apiUrl2,
        })
    }
}
