import { IPricePolicy } from "@domain/version2/ContactDetail"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { Button, Col, Form, FormInstance, InputNumber, Row, Select, Typography } from "antd"
import { compact, isEmpty, map, sum } from "lodash"
import React, { useMemo } from "react"
import { AdSkuList } from "@screen/version2/Contact/Detail/CreateEvent/AdSkuList"
import { useAppSelector } from "@hook/useAppSelector"

type Props = {
    listSkus: IPricePolicy[]
    currency: any
    form: FormInstance
}
export const PricePolicy: React.FC<Props> = ({ listSkus, currency, form }) => {
    const adCode = Form.useWatch("ads", form)
    const adSkus = useAppSelector((state) => state.contactDetailVersion2.adSkus)

    const listHeader = [
        { title: trans("products.variant"), span: 6 },
        { title: trans("contact-detail.quantity"), span: 6 },
        { title: `${trans("products.price")} (${currency})`, span: 6 },
        { title: `${trans("contact-detail.price")} (${currency})`, span: 5 },
        { title: "", span: 1 },
    ]
    const optionSkus = useMemo(
        () =>
            !isEmpty(listSkus)
                ? listSkus?.map((item) => ({
                      label: !isEmpty(item?.variant) ? Object.values(item?.variant)?.join("/") : "",
                      key: item?.sku,
                  }))
                : [],
        [listSkus]
    )
    return (
        <>
            <Row
                gutter={15}
                className="bg-gray-200 mt-3 py-3 border-b-black">
                {listHeader.map((item: { title: string; span: number }, index: number) => (
                    <Col
                        span={item.span}
                        key={index}>
                        <Typography.Text
                            strong
                            className="block text-center">
                            {item.title}
                        </Typography.Text>
                    </Col>
                ))}
            </Row>
            <div>
                <Form.List name="pricePolicies">
                    {(fields, { remove, add }) => (
                        <div>
                            {fields.map(({ name, ...restField }) => (
                                <Row
                                    className={`border pt-2 pr-3 ${fields.length <= listSkus.length ? "border-b-0" : ""}`}
                                    key={name}
                                    gutter={15}>
                                    <Col span={6}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(pre, next) => pre.pricePolicies !== next.pricePolicies}>
                                            {({ getFieldValue }) => (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "property"]}
                                                    rules={[{ required: true, message: trans("message.required") }]}>
                                                    <Select placeholder={trans("contact-detail.validate_property")}>
                                                        {optionSkus?.map((item: { label: string; key: string }) => {
                                                            const selectedSku = map(getFieldValue("pricePolicies"), "property")
                                                            return (
                                                                <Select.Option
                                                                    disabled={compact(selectedSku)?.includes(item?.key)}
                                                                    key={item?.key}
                                                                    value={item?.key}>
                                                                    {item?.label || item?.key}
                                                                </Select.Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(pre, next) => pre?.pricePolicies[name]?.property !== next?.pricePolicies[name]?.property}>
                                            {({ getFieldValue }) => (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "quantity"]}
                                                    rules={[{ required: true, message: trans("message.required") }]}>
                                                    <InputNumber
                                                        placeholder={trans("contact-detail.quantity")}
                                                        disabled={!getFieldValue(["pricePolicies", name, "property"])}
                                                        parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(pre, next) => pre?.pricePolicies[name]?.property !== next?.pricePolicies[name]?.property}>
                                            {({ getFieldValue }) => (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "unitPrice"]}
                                                    rules={[{ required: true, message: trans("message.required") }]}>
                                                    <InputNumber
                                                        disabled={!getFieldValue(["pricePolicies", name, "property"])}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        placeholder={trans("contact-detail.price")}
                                                        parser={(value: any) =>
                                                            value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                                        }
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(pre, next) =>
                                                pre?.pricePolicies[name]?.quantity !== next?.pricePolicies[name]?.quantity ||
                                                pre?.pricePolicies[name]?.unitPrice !== next?.pricePolicies[name]?.unitPrice
                                            }>
                                            {({ getFieldValue }) => {
                                                const price =
                                                    Number(getFieldValue(["pricePolicies", name, "quantity"])) *
                                                    Number(getFieldValue(["pricePolicies", name, "unitPrice"]))
                                                return <Typography.Text className="block">{convertNumberToCurrency(price)}</Typography.Text>
                                            }}
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        {name > 0 && (
                                            <Button
                                                type="link"
                                                icon={<i className="fa-light fa-trash" />}
                                                onClick={() => remove(name)}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            ))}
                            {fields.length <= listSkus.length && (
                                <Row
                                    gutter={15}
                                    className="border border-t-0">
                                    <Button
                                        className="w-full mx-2 mb-2"
                                        type="default"
                                        onClick={() => add()}>
                                        {trans("contact-detail.add_property")}
                                    </Button>
                                </Row>
                            )}
                        </div>
                    )}
                </Form.List>

                <AdSkuList adCode={adCode} />

                <Row
                    className="mt-3"
                    justify="end">
                    <Col
                        span={14}
                        className="flex mb-3">
                        <Typography.Text className="w-40 mr-1">{trans("contact-detail.total_quantity")}:</Typography.Text>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.pricePolicies !== next.pricePolicies}>
                            {({ getFieldValue }) => {
                                const listQuantity: any = getFieldValue("pricePolicies")?.map((item: any) => item?.quantity || 0)
                                let totalQuantity = sum(listQuantity)
                                if (adSkus) {
                                    adSkus.forEach((x) => {
                                        totalQuantity = Number(totalQuantity) + Number(x.quantity || 0)
                                    })
                                }
                                return <Typography.Text>{convertNumberToCurrency(totalQuantity)}</Typography.Text>
                            }}
                        </Form.Item>
                    </Col>
                    <Col
                        span={14}
                        className="flex mb-3">
                        <Typography.Text className="w-40 mr-1">
                            {trans("order_detail.total_amount")} ({currency}):
                        </Typography.Text>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.pricePolicies !== next.pricePolicies}>
                            {({ getFieldValue }) => {
                                const listAmount: any = getFieldValue("pricePolicies")?.map((item: any) => Number(item?.quantity) * Number(item?.unitPrice))
                                let totalAmount = !isEmpty(listAmount) ? sum(listAmount) : 0
                                if (adSkus) {
                                    adSkus.forEach((x) => {
                                        totalAmount = Number(totalAmount) + Number(x.quantity || 0) * Number(x.price || 0)
                                    })
                                }
                                return <Typography.Text>{convertNumberToCurrency(totalAmount)}</Typography.Text>
                            }}
                        </Form.Item>
                    </Col>
                    <Col
                        span={14}
                        className="flex">
                        <Typography.Text className="w-40 mr-1">
                            {trans("order.delivery_fee")} ({currency}):
                        </Typography.Text>
                        <Form.Item name="deliveryFee">
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                placeholder={trans("order.delivery_fee")}
                                parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={14}
                        className="flex">
                        <Typography.Text className="w-40 mr-1">
                            {trans("order_detail.total_payment")} ({currency})<span className="text-red-500">*</span>:
                        </Typography.Text>
                        <Form.Item
                            name="totalPayment"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                placeholder={trans("order_detail.total_payment")}
                                parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    )
}
