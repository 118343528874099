import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetListGroup, callGetListLeaderSuggest } from "@reducer/version2/telesales-group.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { AddGroup } from "./AddGroup"
import { Filter } from "./Filter"
import { List } from "./ListGroup"
export const GroupTelesales: React.FC = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("telesales.title")))
        dispatch(callGetListLeaderSuggest({username: ''}))
    }, [dispatch])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        if (params?.tab === "groups") {
            delete params.tab
            dispatch(
                callGetListGroup({
                    ...params,
                    size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "createdAt:desc",
                })
            )
        }
    }, [dispatch, searchParams])
    return (
        <div>
            <Filter />
            <List />
            <AddGroup />
        </div>
    )
}
