import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import { ProductApi } from "@api/version2/Product"
import { IGroupTelesales, IGroupTelesalesInProductDetail } from "@domain/version2/Telesales"
import { IGroupTelesalesProduct, IProductProperties, IProductTelesales, IProductVendors } from "@domain/version2/Product"

interface State {
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
    loadingAddGroups: boolean
    groupsTelesales: IGroupTelesalesInProductDetail[]
    loadingGroupsTelesales: boolean
    loadingPropertiesOfProduct: boolean
    loadingVendorsOfProduct: boolean
    loadingDetailProduct: boolean
    loadingDelete: boolean
    productDetail: IProductTelesales
    listPropertiesOfProduct: IProductProperties[]
    listVendorsOfProduct: IProductVendors[]
    groupsSuggest: IGroupTelesales[]
    groupTelesalesProduct: IGroupTelesalesProduct[]
}

const initState: State = {
    pagination: {
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loadingAddGroups: false,
    groupsTelesales: [],
    loadingGroupsTelesales: false,
    loadingPropertiesOfProduct: false,
    loadingVendorsOfProduct: false,
    loadingDelete: false,
    loadingDetailProduct: false,
    listPropertiesOfProduct: [],
    listVendorsOfProduct: [],
    productDetail: {
        createdAt: "",
    },
    groupsSuggest: [],
    groupTelesalesProduct: [],
}
export const callGetGroupsSuggest = createAsyncThunk(
    "TELESALES_GROUPS.GET_LIST_SUGGEST",
    async (payload: { code: string; params: { keyword: string } }, thunkApi) => {
        try {
            return await ProductApi.getTelesalesGroupsSuggest(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callAddGroupTelesales = createAsyncThunk(
    "PRODUCT_DETAIL.ADD_GROUP_TELESALES",
    async (payload: { code: string; body: { groupCodes: string[] } }, thunkApi) => {
        try {
            return await ProductApi.addGroupTelesales(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callGroupsTelesales = createAsyncThunk(
    "PRODUCT_DETAIL.GET_GROUPS_OF_PRODUCT",
    async (payload: { code: string | undefined; params: { page?: number; pageSize?: number; sort?: string } }, thunkApi) => {
        try {
            return await ProductApi.getGroupsTelesales(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callDeleteGroupTelesales = createAsyncThunk(
    "PRODUCT_DETAIL.DELETE_GROUPS",
    async (payload: { code: string | undefined; body: { groupCodes: string[] } }, thunkApi) => {
        try {
            return await ProductApi.removeGroupTelesales(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callGetDetailProduct = createAsyncThunk("PRODUCT.GET_PRODUCT_DETAIL", async (code: string | undefined, thunkApi) => {
    try {
        return await ProductApi.getProductDetails(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetPropertiesOfProduct = createAsyncThunk(
    "PRODUCT_DETAIL.GET_PROPERTIES_OF_PRODUCT",
    async (payload: { code: string | undefined; params: { page?: number; pageSize?: number; sort?: string } }, thunkApi) => {
        try {
            return await ProductApi.getPropertiesOfProduct(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callGetVendorsOfProduct = createAsyncThunk(
    "PRODUCT_DETAIL.GET_VENDORS_OF_PRODUCT",
    async (payload: { code: string | undefined; params: { page?: number; pageSize?: number } }, thunkApi) => {
        try {
            return await ProductApi.getVendorsOfProduct(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callGetGroupTelesalesInProduct = createAsyncThunk("PRODUCT_DETAIL.GET_GROUP_TELESALES_PRODUCT", async (code: string, thunkApi) => {
    try {
        return await ProductApi.getTelesalesGroupInProduct(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const resetDataTelesalesGroups = createAction<any>("PRODUCT_DETAIL.RESET_DATA")

export const productDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetGroupsSuggest.pending, (state) => {
            state.groupsSuggest = []
        })
        .addCase(callGetGroupsSuggest.fulfilled, (state, { payload }) => {
            state.groupsSuggest = payload.data
        })
        .addCase(callGetGroupsSuggest.rejected, (state) => {
            state.groupsSuggest = []
        })
    builder.addCase(resetDataTelesalesGroups, (state) => {
        state.groupsSuggest = []
    })
    builder
        .addCase(callAddGroupTelesales.pending, (state) => {
            state.loadingAddGroups = true
        })
        .addCase(callAddGroupTelesales.rejected, (state) => {
            state.loadingAddGroups = false
        })
        .addCase(callAddGroupTelesales.fulfilled, (state) => {
            state.loadingAddGroups = false
        })
    builder
        .addCase(callGroupsTelesales.pending, (state) => {
            state.loadingGroupsTelesales = true
            state.groupsTelesales = []
        })
        .addCase(callGroupsTelesales.rejected, (state) => {
            state.loadingGroupsTelesales = false
            state.groupsTelesales = []
        })
        .addCase(callGroupsTelesales.fulfilled, (state, { payload }) => {
            state.loadingGroupsTelesales = false
            state.groupsTelesales = payload.data
            state.pagination.current = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
        })
    builder
        .addCase(callDeleteGroupTelesales.pending, (state) => {
            state.loadingDelete = true
        })
        .addCase(callDeleteGroupTelesales.rejected, (state) => {
            state.loadingDelete = false
        })
        .addCase(callDeleteGroupTelesales.fulfilled, (state) => {
            state.loadingDelete = false
        })
    builder
        .addCase(callGetDetailProduct.pending, (state) => {
            state.loadingDetailProduct = true
        })
        .addCase(callGetDetailProduct.fulfilled, (state, { payload }) => {
            state.loadingDetailProduct = false
            state.productDetail = payload.data
        })
        .addCase(callGetDetailProduct.rejected, (state) => {
            state.loadingDetailProduct = false
        })
    builder
        .addCase(callGetPropertiesOfProduct.pending, (state) => {
            state.listPropertiesOfProduct = []
            state.loadingPropertiesOfProduct = true
        })
        .addCase(callGetPropertiesOfProduct.fulfilled, (state, { payload }) => {
            state.loadingPropertiesOfProduct = false
            state.listPropertiesOfProduct = payload?.data?.data
            state.pagination.current = payload?.data?.page || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = payload?.data?.size || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload?.data.total || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload?.data?.pageCount || PAGINATION.DEFAULT_TOTAL_PAGE
        })
        .addCase(callGetPropertiesOfProduct.rejected, (state) => {
            state.listPropertiesOfProduct = []
            state.loadingPropertiesOfProduct = false
        })

    builder
        .addCase(callGetVendorsOfProduct.pending, (state) => {
            state.listVendorsOfProduct = []
            state.loadingVendorsOfProduct = true
        })
        .addCase(callGetVendorsOfProduct.fulfilled, (state, { payload }) => {
            state.loadingVendorsOfProduct = false
            state.listVendorsOfProduct = payload?.data?.data
            state.pagination.current = payload?.data?.page || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = payload?.data?.size || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload?.data.total || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload?.data?.pageCount || PAGINATION.DEFAULT_TOTAL_PAGE
        })
        .addCase(callGetVendorsOfProduct.rejected, (state) => {
            state.listVendorsOfProduct = []
            state.loadingVendorsOfProduct = false
        })
    builder
        .addCase(callGetGroupTelesalesInProduct.pending, (state) => {
            state.groupTelesalesProduct = []
        })
        .addCase(callGetGroupTelesalesInProduct.fulfilled, (state, { payload }) => {
            state.groupTelesalesProduct = payload.data
        })
        .addCase(callGetGroupTelesalesInProduct.rejected, (state) => {
            state.groupTelesalesProduct = []
        })
})
