import React, { useMemo, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { Button, Card, message, Pagination, Row, Space, Table } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { PAGINATION } from "@config/constant"
import { IOrder } from "@domain/version2/Order"
import { SettingTable } from "@component/General/SettingTable"
import { filter, find, isEmpty } from "lodash"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { exportOrders } from "@reducer/version2/order.reducer"
import { useAppDispatch } from "@hook/useAppDispatch"
import { formatDateFull } from "@util/Common"
import { changeVisibleOrderImport } from "@reducer/version2/order-detail.reducer"

export const List = () => {
    const dispatch = useAppDispatch()
    const loading = useAppSelector((state) => state.ordersVersion2.loading)
    const loadingExport = useAppSelector((state) => state.ordersVersion2.loadingExport)
    const pageSize = useAppSelector((state) => state.ordersVersion2.pagination.size)
    const currentPage = useAppSelector((state) => state.ordersVersion2.pagination.current)
    const total = useAppSelector((state) => state.ordersVersion2.pagination.total)
    const orders = useAppSelector((state) => state.ordersVersion2.orders)
    const totalPage: number = useAppSelector((state) => state.ordersVersion2.pagination.totalPage)
    const countriesSuggest = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const vendorSuggest = useAppSelector((state) => state.vendor.vendorSuggests)
    const couriers = useAppSelector((state) => state.countriesVersion2.couriersSuggestAll)
    const [selectedTable, setSelectedTable] = useState<string[]>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const oldSearch = Object.fromEntries(searchParams.entries())

    const handleExportOrder = () => {
        dispatch(
            exportOrders({
                ...oldSearch,
                sort: "createdAt:DESC",
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    const columns: any[] = [
        {
            title: trans("contact-detail.order_code"),
            dataIndex: "code",
            align: "left",
            key: "code",
            render: (name: string, record: IOrder) =>
                SecurityService.can(ORDER_SCOPE.ORDER_DETAIL_VIEW) || SecurityService.can(ORDER_SCOPE.ORDER_DETAIL_VIEW_ALL) ? (
                    <Link to={`/orders/${record?.code}`}>{name || ""}</Link>
                ) : (
                    <p className="mb-0">{name}</p>
                ),
            width: 100,
            default: true,
        },
        {
            title: trans("order.campaign"),
            dataIndex: ["campaign", "name"],
            key: "campaignName",
            align: "left",
            width: 150,
            default: true,
        },
        {
            title: trans("contact-detail.product"),
            className: "bold-400",
            width: 150,
            dataIndex: ["productDetail", "name"],
            key: "productDetail",
            algin: "left",
            default: false,
        },
        {
            title: trans("order_list.customerName"),
            dataIndex: "customerName",
            key: "customerName",
            width: 150,
            align: "left",
            default: true,
        },
        {
            title: trans("order.customer_phone"),
            dataIndex: "customerPhone",
            key: "customerPhone",
            align: "center",
            width: 120,
            default: true,
        },
        {
            title: trans("contact.country"),
            className: "bold-400",
            width: 120,
            dataIndex: "countryCode",
            default: false,
            key: "countryCode",
            align: "left",
            render: (countryCode: string) => find(countriesSuggest, { code: countryCode })?.name || "",
        },
        {
            title: trans("vendor.courier"),
            className: "bold-400",
            width: 120,
            dataIndex: "courierServiceCode",
            default: false,
            key: "courier",
            align: "left",
            render: (courier: string) => find(couriers, { code: courier })?.name || "",
        },
        {
            title: trans("contact.vendor"),
            className: "bold-400",
            width: 120,
            dataIndex: "vendorCode",
            align: "left",
            default: true,
            key: "vendor",
            render: (vendor: string) => (vendor ? find(vendorSuggest, { code: vendor })?.name : ""),
        },
        {
            title: trans("order_detail.tracking_number"),
            dataIndex: "trackingNumber",
            key: "trackingNumber",
            align: "center",
            width: 120,
            default: false,
        },
        {
            title: trans("order_list.created_at"),
            dataIndex: "createdAt",
            align: "center",
            key: "createdAt",
            render: (value: string) => formatDateFull(value),
            width: 120,
            default: true,
        },
        {
            title: trans("order_list.createdBy"),
            dataIndex: "createdBy",
            key: "createdBy",
            align: "left",
            width: 100,
            default: false,
        },
        {
            title: trans("order_list.status"),
            dataIndex: "status",
            key: "status",
            align: "left",
            render: (status: string) => trans(`order_status.${status}`),
            width: 120,
            default: true,
        },
        {
            title: trans("order_list.payment_status"),
            dataIndex: "isPaid",
            key: "isPaid",
            align: "center",
            render: (isPaid: boolean) => {
                return isPaid ? trans("order_list.paid_status") : trans("order_list.unpaid_status")
            },
            width: 120,
            default: false,
        },
        {
            title: trans("order_detail.financial_status"),
            dataIndex: "financial",
            key: "financial",
            default: false,
            align: "center",
            width: 100,
            render: (financial: string) => (financial ? trans(`order_detail.financial_status_${financial}`) : "---"),
        },
    ]

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !oldSearch.size || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(params)
    }
    const dataTrees = useMemo(
        () =>
            !isEmpty(columns)
                ? columns?.map((item: any) => ({
                      title: item?.title,
                      key: item?.key,
                      disabled: item?.default,
                  }))
                : [],
        []
    )

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                <Space>
                    {SecurityService.can(ORDER_SCOPE.ORDER_FINANCIAL_STATUS_UPDATE) && (
                        <Button
                            type="primary"
                            className="m-0 ml-2"
                            onClick={() => {
                                dispatch(changeVisibleOrderImport(true))
                            }}
                            icon={<i className="fa-solid fa-cloud-arrow-up mr-2" />}>
                            {trans("order.import_financial")}
                        </Button>
                    )}
                    <Button
                        type="primary"
                        className="m-0 ml-2"
                        loading={loadingExport}
                        onClick={() => handleExportOrder()}
                        icon={<i className="fa-solid fa-cloud-arrow-down mr-2" />}>
                        {trans("contact.export_order")}
                    </Button>
                    {!isEmpty(dataTrees) && (
                        <SettingTable
                            dataTree={dataTrees}
                            keyTable="orderTelesales"
                            setSelectedTable={setSelectedTable}
                            selectedTable={selectedTable}
                        />
                    )}
                </Space>
            }>
            <Table
                scroll={{
                    x: true,
                }}
                columns={filter(columns, (iFil: any) => selectedTable?.includes(iFil?.key))}
                dataSource={orders}
                rowKey={(record) => record.id}
                pagination={false}
                loading={loading}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
