import { IOrder } from "@domain/version2/Order"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Card, Col, Row, Table } from "antd"
import React from "react"
import { Link } from "react-router-dom"

const RelatedOrders = () => {
    const listOrders = useAppSelector((state) => state.contactDetailVersion2.listOrdersOfContact)

    const columns: any[] = [
        {
            title: trans("contact-detail.order_code"),
            dataIndex: "code",
            width: "20%",
            render: (value: string, record: IOrder) => (value ? <Link to={`/orders/${record?.code}`}>{value}</Link> : "---"),
        },
        {
            title: trans("order.customer_name"),
            dataIndex: "customerName",
            width: "20%",
        },
        {
            title: trans("order_list.customerPhone"),
            dataIndex: "customerPhone",
            width: "20%",
            className: "table-vertical-align",
        },
        {
            title: `${trans("order_list.status")}`,
            dataIndex: "status",
            width: "15%",
            className: "table-vertical-align text-left",
            render: (status: string) => <p>{trans(`order_status.${status}`)}</p>,
        },
        {
            title: trans("order_list.created_at"),
            dataIndex: "createdAt",
            align: "center",
            key: "createdAt",
            render: (value: string) => formatDateFull(value),
            width: "25%",
            default: true,
        },
    ]

    return (
        <div className="mt-3 ">
            <Card title={trans("contact.list_of_related_orders")}>
                <Row>
                    <Col span={24}>
                        <Table
                            className="mt-3"
                            bordered
                            rowKey={"id"}
                            columns={columns}
                            dataSource={listOrders || []}
                            pagination={false}
                            scroll={{ y: 320 }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default RelatedOrders
