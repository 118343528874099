import SkuComboApi from "@api/version2/SkuComboApi"
import { PAGINATION } from "@config/constant"
import { IGroupOfCombo, IPropertyOfCombo, ISkuComboDetail } from "@domain/version2/SkuCombo"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    skuComboDetail: ISkuComboDetail
    propertiesOfCombo: IPropertyOfCombo[]
    loadingAddGroup: boolean
    loadingDeleteGroup: boolean
    loadingListGroup: boolean
    groupsOfCombo: IGroupOfCombo[]
    paginationGroup: {
        total: number
        currentPage: number
        totalPage: number
        pageSize: number
    }
}

const initState: State = {
    skuComboDetail: {} as ISkuComboDetail,
    propertiesOfCombo: [],
    loadingAddGroup: false,
    loadingDeleteGroup: false,
    loadingListGroup: false,
    groupsOfCombo: [],
    paginationGroup: {
        total: 0,
        currentPage: 0,
        totalPage: 0,
        pageSize: 0,
    },
}

export const callGetDetailSkuCombo = createAsyncThunk("SKU_COMBO.GET_DETAIL", async (code: string, thunkApi) => {
    try {
        return await SkuComboApi.getDetailSkuCombo(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetPropertiesOfCombo = createAsyncThunk("SKU_COMBO.PROPERTIES_OF_COMBO", async (payload: { comboCode: string }, thunkApi) => {
    try {
        return await SkuComboApi.getPropertiesOfCombo(payload.comboCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callAddGroupToCombo = createAsyncThunk("COMBO.ADD_TLS_GROUP", async (payload: { code: string; body: { groupCodes: string[] } }, thunkApi) => {
    try {
        return await SkuComboApi.updateListTelesalesGroupOfCombo(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetGroupsInCombo = createAsyncThunk(
    "COMBO.GET_LIST_TLS_GROUP",
    async (payload: { code: string; params: { page: number; pageSize: number } }, thunkApi) => {
        try {
            return await SkuComboApi.getTelesalesGroupOfCombo(payload.code, payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callDeleteGroupInCombo = createAsyncThunk(
    "COMBO.DELETE_TLS_GROUP",
    async (payload: { code: string; body: { groupCodes: string[] } }, thunkApi) => {
        try {
            return await SkuComboApi.deleteTelesalesGroupOfCombo(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const resetDataDetailSkuCombo = createAction<any>("PRODUCT.RESET_DATA_DETAIL")

export const skuComboDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetDetailSkuCombo.pending, (state) => {
            state.skuComboDetail = {} as ISkuComboDetail
        })
        .addCase(callGetDetailSkuCombo.fulfilled, (state, { payload }) => {
            state.skuComboDetail = payload.data
        })
        .addCase(callGetDetailSkuCombo.rejected, (state) => {
            state.skuComboDetail = {} as ISkuComboDetail
        })

    builder.addCase(resetDataDetailSkuCombo, (state, action: PayloadAction<any>) => {
        state.skuComboDetail = action.payload
    })
    builder
        .addCase(callGetPropertiesOfCombo.pending, (state) => {
            state.propertiesOfCombo = []
        })
        .addCase(callGetPropertiesOfCombo.fulfilled, (state, { payload }) => {
            state.propertiesOfCombo = payload.data || []
        })
        .addCase(callGetPropertiesOfCombo.rejected, (state) => {
            state.propertiesOfCombo = []
        })

    builder
        .addCase(callAddGroupToCombo.pending, (state) => {
            state.loadingAddGroup = true
        })
        .addCase(callAddGroupToCombo.fulfilled, (state) => {
            state.loadingAddGroup = false
        })
        .addCase(callAddGroupToCombo.rejected, (state) => {
            state.loadingAddGroup = false
        })
    builder
        .addCase(callGetGroupsInCombo.pending, (state) => {
            state.loadingListGroup = true
            state.groupsOfCombo = []
        })
        .addCase(callGetGroupsInCombo.rejected, (state) => {
            state.loadingListGroup = true
            state.groupsOfCombo = []
        })
        .addCase(callGetGroupsInCombo.fulfilled, (state, action) => {
            state.loadingListGroup = false
            state.groupsOfCombo = action.payload?.data || []
            state.paginationGroup.currentPage = Number(action.payload.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.paginationGroup.pageSize = Number(action.payload.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.paginationGroup.total = Number(action.payload.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.paginationGroup.totalPage = Number(action.payload.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
        })
    builder
        .addCase(callDeleteGroupInCombo.pending, (state) => {
            state.loadingDeleteGroup = true
        })
        .addCase(callDeleteGroupInCombo.fulfilled, (state) => {
            state.loadingDeleteGroup = false
        })
        .addCase(callDeleteGroupInCombo.rejected, (state) => {
            state.loadingDeleteGroup = false
        })
})
