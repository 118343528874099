import { trans } from "@resources/localization"
import { Card, Col, Input, message, Row, Typography } from "antd"
import React, { useState } from "react"
import { useAppSelector } from "@hook/useAppSelector"
import { capitalizeFirstLetter, formatDateFull } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callDetailTelesalesGroup, callUpdateNameTelesalesGroup } from "@reducer/version2/telesales-group-detail.reducer"
import SecurityService from "@util/SecurityService"
import { TELESALES_COPE } from "@config/permission"
import { useParams } from "react-router-dom"

export const Header: React.FC = () => {
    const detailTelesaleGroup = useAppSelector((state) => state.telesalesGroupDetail.telesalesGroup)
    const dispatch = useAppDispatch()
    const [isEditing, setEdit] = useState<boolean>(false)
    const [groupName, setGroupName] = useState<string>("")
    const groupCode = useParams().id as string

    const handleOnKeyDown = () => {
        if (groupName.trim() === "") setEdit(false)
        else {
            dispatch(callUpdateNameTelesalesGroup({ groupCode: detailTelesaleGroup.code, body: { new_name: groupName.trim() } })).then((result: any) => {
                if (result?.payload?.status === 202) {
                    message.success(trans("message.success"))
                    dispatch(callDetailTelesalesGroup({ groupCode }))
                    setEdit(false)
                }
            })
        }
    }

    return (
        <Card
            className="space-layout custom-bottom"
            title={
                <div className="flex h-10">
                    {isEditing ? (
                        <div className="w-[200px] -mt-1">
                            <Input
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                onPressEnter={() => handleOnKeyDown()}
                                size="large"
                                autoFocus={true}
                                className="font-semibold text-xl"
                                required={true}
                            />
                            {!groupName && <p className="text-red-500 text-sm">{trans("message.required")}</p>}
                        </div>
                    ) : (
                        <div className="flex item-center">
                            <Typography.Title
                                level={4}
                                className="block">
                                {capitalizeFirstLetter(detailTelesaleGroup?.name)}
                            </Typography.Title>
                            {SecurityService.can(TELESALES_COPE.GROUP_TELESALES_UPDATE) && (
                                <div className="ml-3">
                                    <i
                                        className="cursor-pointer fa-light fa-pen-to-square"
                                        onClick={() => {
                                            setGroupName(detailTelesaleGroup?.name)
                                            setEdit(true)
                                        }}></i>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            }>
            <Row gutter={24}>
                <Col
                    xs={24}
                    sm={12}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("group.code")}:</p>
                    <p className="m-0">{detailTelesaleGroup?.code}</p>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={5}
                    span={5}>
                    <p className="m-0 font-medium">{trans("telesales.title")}:</p>
                    <p className="m-0">{detailTelesaleGroup?.totalTelesales}</p>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={5}
                    span={5}>
                    <p className="m-0 font-medium">{trans("group_user.leader")}:</p>
                    <p className="m-0">{detailTelesaleGroup?.admin}</p>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={5}
                    span={5}>
                    <p className="m-0 font-medium">{trans("contact-detail.created_by")}:</p>
                    <p className="m-0">{detailTelesaleGroup?.createdBy}</p>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={5}
                    span={5}>
                    <p className="m-0 font-medium">{trans("contact.created_at")}:</p>
                    <Typography.Text className="m-0">{detailTelesaleGroup?.createdAt && formatDateFull(detailTelesaleGroup?.createdAt)}</Typography.Text>
                </Col>
            </Row>
        </Card>
    )
}
