import { IVendor } from "@domain/version2/Vendor"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios";

export default class VendorApi {
    static getVendorSuggest(params: { codeOrName?: string; countryCode?: string }): Promise<AxiosResponse<IVendor[]>> {
        return apiClient.get("vendors/autocomplete", {
            params,
        })
    }
}
