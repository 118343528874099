import { PAGINATION } from "@config/constant"
import { TELESALES_COPE } from "@config/permission"
import { ITelesales } from "@domain/version2/Telesales"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetListTelesales } from "@reducer/version2/telesales.reducer"
import { trans } from "@resources/localization"
import { formatDateFull, shortenText } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Row, Table, Pagination, Button, Popover, Tooltip, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import { isEmpty, map } from "lodash"
import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { AddTelesales } from "./AddTelesales"
export const List: React.FC = () => {
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const total = useAppSelector((state) => state.telesales.pagination.total)
    const currentPage = useAppSelector((state) => state.telesales.pagination.currentPage)
    const totalPage = useAppSelector((state) => state.telesales.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.telesales.pagination.pageSize)
    const listTelesales = useAppSelector((state) => state.telesales.listTelesales)
    const loadingList = useAppSelector((state) => state.telesales.loadingTelesales)
    const [searchParams, setSearchParams] = useSearchParams()
    const columns: ColumnsType<any> = [
        {
            title: trans("telesales.name"),
            dataIndex: "username",
            key: "username",
            width: 120,
        },
        {
            title: trans("telesales.display"),
            dataIndex: "user",
            key: "user",
            render: (text: string, record: ITelesales) => record?.user?.name,
            width: 120,
        },
        {
            title: trans("telesales.email"),
            dataIndex: "email",
            key: "email",
            render: (text: string, record: ITelesales) => record?.user?.email,
            width: 150,
        },
        {
            title: trans("table.added_by"),
            dataIndex: "createdBy",
            key: "createdBy",
            width: 100,
        },
        {
            title: trans("table.added_at"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => (date ? formatDateFull(date) : ""),
            width: 120,
        },
        {
            title: trans("telesales.group_name"),
            dataIndex: "groups",
            key: "groups",
            width: 150,
            render: (groups: any) =>
                !isEmpty(groups) ? (
                    <Tooltip title={map(groups, (iGroup) => iGroup?.name)?.join(", ")}>
                        {shortenText(map(groups, (iGroup) => iGroup?.name)?.join(", "), 16)}
                    </Tooltip>
                ) : (
                    ""
                ),
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        params.tab = "telesales"
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(TELESALES_COPE.TELESALES_CREATE) && (
                    <Popover
                        content={
                            <AddTelesales
                                setClose={() => {
                                    const params = Object.fromEntries(searchParams.entries())
                                    delete params.tab

                                    dispatch(
                                        callGetListTelesales({
                                            ...params,
                                            pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                            sort: "createdAt:desc",
                                        })
                                    )
                                    setOpen(false)
                                }}
                            />
                        }
                        title={trans("telesales.add")}
                        placement="left"
                        trigger="click"
                        open={open}
                        onOpenChange={() => setOpen((old) => !old)}>
                        <Button
                            type="primary"
                            className="mg-r-10 _filter_button_filter">
                            {trans("telesales.add")}
                        </Button>
                    </Popover>
                )
            }>
            <Table
                scroll={{
                    x: true,
                }}
                columns={columns}
                dataSource={listTelesales || []}
                loading={loadingList}
                rowKey={(record) => record.id}
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
