import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import { useParams } from "react-router-dom"
import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { CONTACT_DETAIL_BREADCRUMB } from "@config/constant"
import { BasicInfo } from "./BasicInfo"
import { Summary } from "./Summary"
import { FormAddEvent } from "./CreateEvent"
import { callAdsOfContact, callGetContactDetail, callGetListOrdersOfContactInPeriod } from "@reducer/version2/contact-detail.reducer"
import { callGetContactEvents } from "@reducer/version2/contact-detail.reducer"
import { callGetContactEventStatus, callGetContactStatus } from "@reducer/version2/categories.reducer"
import { filter, isEmpty } from "lodash"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, getProvinceSuggest } from "@reducer/version2/countries.reducer"
import { Notfound } from "@component/Notfound"
import { AccessDenied } from "@component/AccessDenied"
import { HistoryCall } from "./CallHistory"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"
import { ActionHistory } from "./ActionHistory"
import { callGetGroupTelesalesInProduct } from "@reducer/version2/product-detail"

export const ContactDetail = () => {
    const { id } = useParams()
    const loading: boolean = useAppSelector((state) => state.contactDetailVersion2.loading)
    const contactDetail = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    const dispatch = useAppDispatch()
    const [statusShow, setStatusShow] = useState<number>(0)
    const [currentKey, setCurrentKey] = useState<string>("summary")

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.contact_detail")))
        dispatch(callGetContactEventStatus())
        dispatch(callGetContactStatus())
        if (id) {
            dispatch(callGetContactDetail(id)).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
            dispatch(callGetContactEvents(id))
            dispatch(callAdsOfContact(id))
            dispatch(callGetListOrdersOfContactInPeriod(id))
        }
    }, [dispatch])

    useEffect(() => {
        if (!isEmpty(contactDetail)) {
            dispatch(getProvinceSuggest(contactDetail?.country?.code || ""))
            if (contactDetail?.province?.code) {
                dispatch(getCitySuggest(contactDetail?.province?.code || ""))
            }
            if (contactDetail?.city?.code) {
                dispatch(getDistrictSuggest(contactDetail?.city?.code || ""))
            }
            if (contactDetail?.district?.code) {
                dispatch(getAreaSuggest(contactDetail?.district?.code))
            }
            if (contactDetail?.campaignDetail?.product?.code) {
                dispatch(callGetGroupTelesalesInProduct(contactDetail?.campaignDetail?.product?.code || ""))
            }
        }
    }, [contactDetail])
    const itemTabs = [
        { label: trans("contact-detail.summary"), children: <Summary setStatusShow={setStatusShow} />, key: "summary", disabled: false },
        {
            label: trans("contact-detail.history_call"),
            children: <HistoryCall currentKey={currentKey} />,
            key: "history",
            disabled: !SecurityService.can(CONTACT_SCOPE.CONTACT_CALL_HISTORY),
        },
        {
            label: trans("order_detail.tab_activity"),
            children: <ActionHistory currentKey={currentKey} />,
            key: "activity",
            disabled: !SecurityService.can(CONTACT_SCOPE.CONTACT_ACTIVITY_HISTORY),
        },
    ]
    return (
        <DefaultLayout loading={loading}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb breadCrumbs={CONTACT_DETAIL_BREADCRUMB} />
                    <BasicInfo />
                    <div className="mx-3">
                        <Tabs
                            className="nav-space"
                            activeKey={currentKey}
                            onChange={(key) => {
                                setCurrentKey(key)
                            }}
                            animated={false}
                            items={filter(itemTabs, { disabled: false })}
                        />
                    </div>

                    <FormAddEvent />
                </>
            )}
        </DefaultLayout>
    )
}
