import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetTelesalesGroupsSuggest } from "@reducer/version2/telesales-group.reducer"
import { callGetListTelesales } from "@reducer/version2/telesales.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Filter } from "./Filter"
import { List } from "./ListTelesales"
export const Telesales: React.FC = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("telesales.title")))
        dispatch(callGetTelesalesGroupsSuggest({ query: ''}))
    }, [dispatch])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        if (params.tab === "telesales") {
            delete params.tab
            dispatch(
                callGetListTelesales({
                    ...params,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "createdAt:desc",
                })
            )
        }
    }, [dispatch, searchParams])

    return (
        <div>
            <Filter />
            <List />
        </div>
    )
}
