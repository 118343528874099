import FinancialStatusApi from "@api/version2/FinancialStatus"
import { IFinancialStatus } from "@domain/version2/FinancialStauts"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    listFinancialStatus: IFinancialStatus[]
}
export const initState: State = {
    listFinancialStatus: [],
}
export const callGetFinancialStatus = createAsyncThunk("FINANCIAL_STATUS.GET_LIST", async (_, thunkApi) => {
    try {
        return await FinancialStatusApi.getFinancialStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const financialStatusReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetFinancialStatus.pending, (state) => {
            state.listFinancialStatus = []
        })
        .addCase(callGetFinancialStatus.fulfilled, (state, { payload }) => {
            state.listFinancialStatus = payload.data
        })
        .addCase(callGetFinancialStatus.rejected, (state) => {
            state.listFinancialStatus = []
        })
})
