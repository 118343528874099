import ReportApi from "@api/version2/ReportApi"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import { IReport, IReportQuery, IReportTelesales } from "@domain/version2/Report"
import { TelesalesApi } from "@api/version2/TelesalesApi"

interface State {
    reportCallStatus: IReport[]
    loading: boolean
    isShowImport: boolean
    reportTelesales: IReportTelesales[]
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
    loadingList: boolean
    loadingUpdate: boolean
}
const initState: State = {
    reportCallStatus: [],
    loading: false,
    isShowImport: false,
    reportTelesales: [],
    pagination: {
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loadingList: false,
    loadingUpdate: false,
}

export const callGetReportCallStatus = createAsyncThunk("REPORT.CALL_STATUS", async (params: IReportQuery, thunkApi) => {
    try {
        return await ReportApi.getReportCallStatus(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetListReportTelesales = createAsyncThunk("REPORT.TELESALES", async (params: IReportQuery, thunkApi) => {
    try {
        return await ReportApi.getReportTelesales(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateSlaSettingOfTelesales = createAsyncThunk(
    "REPORT.UPDATE_SLA_SETTING_OF_TELESALES",
    async (payload: { id: string; body: { slaSettingCode: string } }, thunkApi) => {
        try {
            return await TelesalesApi.updateSlaSettingOfTelesales(payload.id, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const reportReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetReportCallStatus.pending, (state) => {
            state.loading = true
            state.reportCallStatus = []
        })
        .addCase(callGetReportCallStatus.fulfilled, (state, action) => {
            state.loading = false
            state.reportCallStatus = action.payload.data
        })
        .addCase(callGetReportCallStatus.rejected, (state) => {
            state.loading = false
            state.reportCallStatus = []
        })
    builder
        .addCase(callGetListReportTelesales.pending, (state) => {
            state.reportTelesales = []
            state.loadingList = true
        })
        .addCase(callGetListReportTelesales.fulfilled, (state, { payload }) => {
            state.reportTelesales = payload?.data
            state.pagination.current = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callGetListReportTelesales.rejected, (state) => {
            state.reportTelesales = []
            state.loadingList = false
        })
    builder
        .addCase(callUpdateSlaSettingOfTelesales.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateSlaSettingOfTelesales.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateSlaSettingOfTelesales.rejected, (state) => {
            state.loadingUpdate = false
        })
})
