import { CountriesApi } from "@api/version2/CountryApi"
import { ICourier, ILocation } from "@domain/version2/Country"
import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    couriersSuggest: ICourier[]
    couriersSuggestAll: ICourier[]
    provincesSuggest: ILocation[]
    citiesSuggest: ILocation[]
    districtsSuggest: ILocation[]
    areasSuggest: ILocation[]
    countriesSuggest: ILocation[]
}

const initState: State = {
    couriersSuggest: [],
    couriersSuggestAll: [],
    citiesSuggest: [],
    provincesSuggest: [],
    districtsSuggest: [],
    areasSuggest: [],
    countriesSuggest: [],
}

export const getCourierSuggest = createAsyncThunk("COUNTRY.GET_COURIER", async (params: { vendorCode: string }, thunkApi) => {
    try {
        return await CountriesApi.getCouriersAutocompleteOfVendor(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getCourierSuggestAll = createAsyncThunk("COUNTRY.GET_COURIER_ALL", async (_, thunkApi) => {
    try {
        return await CountriesApi.getCouriersAutocomplete()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getCountriesSuggest = createAsyncThunk("COUNTRY.GET_COUNTRY", async (_, thunkApi) => {
    try {
        return await CountriesApi.getCountriesAutocomplete()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getProvinceSuggest = createAsyncThunk("COUNTRY.GET_PROVINCE", async (countryCode: string, thunkApi) => {
    try {
        return await CountriesApi.getProvincesAutocomplete(countryCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getCitySuggest = createAsyncThunk("COUNTRY.GET_CITY", async (parentCode: string, thunkApi) => {
    try {
        return await CountriesApi.getLocationAutocomplete(parentCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getDistrictSuggest = createAsyncThunk("COUNTRY.GET_DISTRICT", async (provinceCode: string, thunkApi) => {
    try {
        return await CountriesApi.getLocationAutocomplete(provinceCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getAreaSuggest = createAsyncThunk("COUNTRY.GET_AREA", async (districtCode: string, thunkApi) => {
    try {
        return await CountriesApi.getLocationAutocomplete(districtCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const resetDataCity = createAction<any>("COUNTRY.RESET_CITY")
export const resetDataDistrict = createAction<any>("COUNTRY.RESET_DISTRICT")
export const resetDataArea = createAction<any>("COUNTRY.RESET_AREA")

export const countriesReducer = createReducer(initState, (builder) => {
    builder
        .addCase(getCourierSuggest.pending, (state) => {
            state.couriersSuggest = []
        })
        .addCase(getCourierSuggest.fulfilled, (state, { payload }) => {
            state.couriersSuggest = payload.data ? payload.data : []
        })
        .addCase(getCourierSuggest.rejected, (state) => {
            state.couriersSuggest = []
        })
    builder
        .addCase(getCourierSuggestAll.pending, (state) => {
            state.couriersSuggestAll = []
        })
        .addCase(getCourierSuggestAll.fulfilled, (state, { payload }) => {
            state.couriersSuggestAll = payload.data ? payload.data : []
        })
        .addCase(getCourierSuggestAll.rejected, (state) => {
            state.couriersSuggestAll = []
        })
    builder
        .addCase(getCountriesSuggest.pending, (state) => {
            state.countriesSuggest = []
        })
        .addCase(getCountriesSuggest.fulfilled, (state, { payload }) => {
            state.countriesSuggest = payload?.data
        })
        .addCase(getCountriesSuggest.rejected, (state) => {
            state.countriesSuggest = []
        })
    builder
        .addCase(getCitySuggest.pending, (state) => {
            state.citiesSuggest = []
        })
        .addCase(getCitySuggest.fulfilled, (state, { payload }) => {
            state.citiesSuggest = payload?.data
        })
        .addCase(getCitySuggest.rejected, (state) => {
            state.citiesSuggest = []
        })
    builder
        .addCase(getProvinceSuggest.pending, (state) => {
            state.provincesSuggest = []
        })
        .addCase(getProvinceSuggest.fulfilled, (state, { payload }) => {
            state.provincesSuggest = payload?.data
        })
        .addCase(getProvinceSuggest.rejected, (state) => {
            state.provincesSuggest = []
        })
    builder
        .addCase(getDistrictSuggest.fulfilled, (state, { payload }) => {
            state.districtsSuggest = payload?.data
        })
        .addCase(getDistrictSuggest.rejected, (state) => {
            state.districtsSuggest = []
        })
    builder
        .addCase(getAreaSuggest.pending, (state) => {
            state.areasSuggest = []
        })
        .addCase(getAreaSuggest.fulfilled, (state, { payload }) => {
            state.areasSuggest = payload?.data
        })
        .addCase(getAreaSuggest.rejected, (state) => {
            state.areasSuggest = []
        })
    builder.addCase(resetDataDistrict, (state, { payload }) => {
        state.districtsSuggest = payload
    })
    builder.addCase(resetDataArea, (state, { payload }) => {
        state.areasSuggest = payload
    })
    builder.addCase(resetDataCity, (state, { payload }) => {
        state.citiesSuggest = payload
    })
})
