import React from "react"
import { Navigate } from "react-router-dom"
import lodash, { every, isEmpty } from "lodash"
import SecurityService from "@util/SecurityService"

interface Props {
    children?: any
    permissions?: string[]
}

export const AuthenticateRoute = (props: Props) => {
    if (!SecurityService.isLogged()) {
        if (!lodash.startsWith(window.location.pathname, "/login") || !lodash.startsWith(window.location.pathname, "/authentication")) {
            const redirectBackUrl: any = window.location.href
            return <Navigate to={`/login?redirectBackUrl=${btoa(redirectBackUrl)}`} />
        }

        return <Navigate to={"/login"} />
    }

    const component = props.children
    const checkPermissions: any = []
    props.permissions?.map((iPer) => {
        checkPermissions.push({
            iCan: !SecurityService.can(iPer),
        })
    })
    if (!isEmpty(props.permissions) && every(checkPermissions, (item) => item.iCan)) {
        return <Navigate to={"/403"} />
    }

    return component
}
