import { PAGINATION } from "@config/constant"
import { TELESALES_COPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeModalAddGroup } from "@reducer/version2/telesales-group.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Card, Row, Table, Pagination, Button } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { Link, useSearchParams } from "react-router-dom"
export const List: React.FC = () => {
    const dispatch = useAppDispatch()
    const total = useAppSelector((state) => state.telesalesGroup.pagination.total)
    const currentPage = useAppSelector((state) => state.telesalesGroup.pagination.currentPage)
    const totalPage = useAppSelector((state) => state.telesalesGroup.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.telesalesGroup.pagination.pageSize)
    const listGroups = useAppSelector((state) => state.telesalesGroup.listGroup)
    const loadingList = useAppSelector((state) => state.telesalesGroup.loadingList)
    const [searchParams, setSearchParams] = useSearchParams()

    const columns: ColumnsType<any> = [
        {
            title: trans("group.code"),
            dataIndex: "code",
            key: "code",
            width: 100,
            render: (code, row) =>
                SecurityService.can(TELESALES_COPE.GROUP_DETAIL_VIEW) ? (
                    <Link
                        to={`/team/group/${row.code}`}
                        className="mb-0">
                        {code}
                    </Link>
                ) : (
                    <p className="mb-0">{code}</p>
                ),
        },
        {
            title: trans("group.name"),
            dataIndex: "name",
            key: "name",
            width: 150,
        },
        {
            title: trans("telesales.title"),
            dataIndex: "quantityOfTelesales",
            key: "quantityOfTelesales",
            width: 100,
        },
        {
            title: trans("group.leader"),
            dataIndex: "admin",
            key: "admin",
            width: 100,
        },
        {
            title: trans("table.created_by"),
            dataIndex: "createdBy",
            key: "createdBy",
            width: 100,
        },
        {
            title: trans("table.created_at"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 150,
            render: (date: string) => (date ? formatDateFull(date) : ""),
        },
        // {
        //     title: trans("order.product"),
        //     dataIndex: "product",
        //     key: "product",
        // },
    ]

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            size: pageSize,
            page: !searchParams.has("size") || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        params.tab = "groups"
        setSearchParams(params)
    }

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(TELESALES_COPE.GROUP_CREATE) && (
                    <Button
                        type="primary"
                        className="mg-r-10 _filter_button_filter"
                        onClick={() => dispatch(changeModalAddGroup(true))}>
                        {trans("group.add")}
                    </Button>
                )
            }>
            <Table
                columns={columns}
                scroll={{
                    x: true,
                }}
                loading={loadingList}
                dataSource={listGroups}
                rowKey={(record) => record.id}
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
