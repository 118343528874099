import React from "react"

import { Result } from "antd"
import { trans } from "@resources/localization"
// import { useNavigate } from "react-router-dom"

export const AccessDenied = () => {
    // const navigate = useNavigate()
    return (
        <Result
            status="403"
            title="403"
            subTitle={trans("login.notPermission")}
            // extra={
            //     <Button
            //         danger
            //         type="primary"
            //         onClick={() => navigate(-2)}>
            //         {trans("login.go_back")}
            //     </Button>
            // }
        />
    )
}
