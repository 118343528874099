import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetListSkuComboAutocomplete } from "@reducer/version2/skuCombo.reducer"
import { callAddCombosToGroup } from "@reducer/version2/telesales-group-detail.reducer"
import { trans } from "@resources/localization"
import { AutoComplete, Button, Col, Form, message, Row, Space, Spin } from "antd"
import { debounce, find } from "lodash"
import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"

type Props = {
    setClose: () => void
}
export const AddSkuCombo: React.FC<Props> = ({ setClose }) => {
    const { id } = useParams()
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const combosSuggest = useAppSelector((state) => state.skuCombo.listSkuComboAutocomplete)
    const listCombosInGroup = useAppSelector((state) => state.telesalesGroupDetail.combosInGroup)
    const loadingAdd = useAppSelector((state) => state.telesalesGroupDetail.loadingAddCombo)

    useEffect(() => {
        dispatch(callGetListSkuComboAutocomplete({ keyword: "" }))
    }, [dispatch])

    const onSearchCombos = (searchText: string) => {
        if (searchText.trim().length >= 3) {
            dispatch(callGetListSkuComboAutocomplete({ keyword: searchText?.trim() }))
        } else {
            dispatch(callGetListSkuComboAutocomplete({ keyword: "" }))
        }
    }

    const options = useMemo(
        () =>
            combosSuggest
                ?.filter((el) => !find(listCombosInGroup, { comboCode: el.code }))
                ?.map((item) => ({
                    value: item?.name,
                    key: item?.code,
                })),
        [listCombosInGroup, combosSuggest]
    )

    const addProduct = (values: { comboCodes: string }) => {
        dispatch(
            callAddCombosToGroup({
                code: id || "",
                body: {
                    comboCodes: [values.comboCodes],
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                formRef.resetFields()
                dispatch(callGetListSkuComboAutocomplete({ keyword: "" }))
                setClose()
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <div className="w-72">
            <Form
                form={formRef}
                layout="vertical"
                onFinish={addProduct}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="comboCodes"
                            hidden></Form.Item>
                        <Form.Item
                            label={trans("skuCombo.name")}
                            name="skuComboName"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <AutoComplete
                                notFoundContent={<Spin />}
                                allowClear
                                onSearch={debounce(onSearchCombos, 1000)}
                                placeholder={trans("skuCombo.name_pl")}
                                options={options}
                                onSelect={(val: any, option: any) => {
                                    formRef.setFieldsValue({
                                        comboCodes: option?.key,
                                    })
                                }}
                                onBlur={() => {
                                    if (!formRef.getFieldValue("comboCodes")) {
                                        formRef.setFieldsValue({
                                            skuComboName: "",
                                        })
                                    }
                                }}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Space>
                        <Button
                            type="default"
                            loading={loadingAdd}
                            onClick={() => {
                                formRef.resetFields()
                                setClose()
                            }}>
                            {trans("button.cancel")}
                        </Button>
                        <Button
                            type="primary"
                            loading={loadingAdd}
                            htmlType="submit"
                            className="ml-2">
                            {trans("button.add")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}
