import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { SETTING_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Tabs } from "antd"
import { filter } from "lodash"
import React, { useEffect, useState } from "react"
import { CancelAssignment } from "./CancelAssignment"
import { SlaSetting } from "./SlaSetting"
export const Settings: React.FC = () => {
    const dispatch = useAppDispatch()
    const [current, setCurrentTab] = useState<string>('sla')
    useEffect(() => {
        dispatch(changePageTitleAction(trans("setting.title")))
        if(SecurityService.can(SETTING_SCOPE.SETTING_SLA_VIEW)){
            setCurrentTab('sla')
        }else(
            setCurrentTab('assign')
        )
    }, [dispatch])
    const items = [
        {
            label: trans("setting.sla"),
            key: "sla",
            children: <SlaSetting />,
            disabled: !SecurityService.can(SETTING_SCOPE.SETTING_SLA_VIEW),
        },
        {
            label: trans("setting.assign"),
            key: "assign",
            children: <CancelAssignment />,
            disabled: !SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_VIEW),
        },
    ]
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: trans("sidebar.dashboard"),
                        link: "/",
                    },
                    {
                        name: trans("setting.title"),
                        link: "/reports",
                    },
                ]}
            />
            <Tabs
                className="nav-space"
                onChange={(e) => setCurrentTab(e)}
                activeKey={current}
                items={filter(items, { disabled: false })}
            />
        </DefaultLayout>
    )
}
