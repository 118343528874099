import { PAGINATION } from "@config/constant"
import { ICallLog, ICallLogQuery } from "@domain/version2/CallStringee"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetCallLogs } from "@reducer/version2/call-stringee.reducer"
import { trans } from "@resources/localization"
import { capitalizeFirstLetter, formatDateFull } from "@util/Common"
import { Card, Pagination, Row, Table } from "antd"
import React, { useEffect, useState } from "react"
import { FilterHistory } from "./Filter"
type Props = {
    currentKey: string
}
export const HistoryCall: React.FC<Props> = ({ currentKey }) => {
    const dispatch = useAppDispatch()
    const total = useAppSelector((state) => state.callStringee.pagination.total)
    const currentPage = useAppSelector((state) => state.callStringee.pagination.currentPage)
    const totalPage = useAppSelector((state) => state.callStringee.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.callStringee.pagination.size)
    const contactDetail = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    const callSettings = useAppSelector((state) => state.callStringee.callLogs)
    const loading = useAppSelector((state) => state.callStringee.loading)
    const tokenStringee = useAppSelector((state) => state.callStringee.tokenStringee)
    const [searchParams, setSearchParams] = useState<ICallLogQuery>()

    useEffect(() => {
        if (currentKey === "history") {
            dispatch(
                callGetCallLogs({
                    ...searchParams,
                    campaign: contactDetail?.campaignDetail?.code,
                    to_alias: contactDetail?.nationalPhone,
                    tenant: contactDetail?.vendor,
                    size: searchParams?.size ? Number(searchParams?.size) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "assignedAt:DESC",
                })
            )
        }
    }, [dispatch, searchParams, currentKey])
    const handleChangePage = (newPage: number, pageSize: number) => {
        setSearchParams((old) => ({
            ...old,
            page: (searchParams?.size && pageSize !== searchParams?.size) ? PAGINATION.DEFAULT_PAGE : newPage,
            size: pageSize,
        }))
    }

    const columns = [
        {
            title: trans("vendor.username"),
            className: "bold-400",
            width: 120,
            dataIndex: "fromAlias",
            key: "fromAlias",
            algin: "left",
        },
        {
            title: trans("contact_detail.time_calling"),
            className: "bold-400",
            width: 200,
            dataIndex: "timestamp",
            key: "timestamp",
            algin: "left",
            render: (createdAt: string) => (createdAt ? formatDateFull(createdAt) : ""),
        },
        {
            title: trans("contact_detail.call_status"),
            className: "bold-400",
            width: 120,
            dataIndex: "callStatus",
            key: "callStatus",
            algin: "left",
            render: (status: string) => (status ? capitalizeFirstLetter(status) : ""),
        },
        {
            title: trans("contact_detail.record"),
            className: "bold-400",
            width: 200,
            dataIndex: "callId",
            key: "callId",
            algin: "left",
            render: (callId: string, record: ICallLog) => {
                if (record.callStatus === "answered") {
                    const urlSource = `https://api.stringee.com/v1/call/play/${callId}?access_token=${tokenStringee}`
                    return (
                        <>
                            <audio controls>
                                <source
                                    src={urlSource}
                                    type="audio/mp3"
                                />
                            </audio>
                        </>
                    )
                }
                return ""
            },
        },
        {
            title: trans("contact-detail.date"),
            className: "bold-400",
            width: 150,
            dataIndex: "timestamp",
            key: "createdAt",
            algin: "left",
            render: (createdAt: string) => (createdAt ? formatDateFull(createdAt) : ""),
        },
    ]
    return (
        <Card
            className="space-y"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
            <FilterHistory
                onFilter={(values: any) => {
                    setSearchParams((old) => ({
                        ...old,
                        ...values,
                    }))
                }}
            />
            <Table
                scroll={{
                    x: true,
                }}
                columns={columns}
                dataSource={callSettings}
                loading={loading}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
