import React, { useEffect } from "react"
import { trans } from "@resources/localization"
import { Card, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useAppSelector } from "@hook/useAppSelector"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetPropertiesOfCombo } from "@reducer/version2/skuCombo-detail.reducer"
import { IPropertyOfCombo } from "@domain/version2/SkuCombo"

export const PropertiesList: React.FC = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const listPropertiesOfCombo = useAppSelector((state) => state.skuComboDetail.propertiesOfCombo)

    useEffect(() => {
        if (id) dispatch(callGetPropertiesOfCombo({ comboCode: id }))
    }, [dispatch])

    const columns: ColumnsType<any> = [
        {
            title: trans("products.variant"),
            className: "bold-400",
            width: "33%",
            render: (record: IPropertyOfCombo) => {
                return (
                    <div className="">
                        <p>{record?.sku?.product?.name || ""}</p>
                        <p>{Object.values(record?.sku?.variant).toString().replace(",", "/") || ""}</p>
                    </div>
                )
            },
        },
        {
            title: trans("ads.sku"),
            className: "bold-400",
            dataIndex: "skuCode",
            width: "33%",
        },
        {
            title: trans("contact-detail.quantity"),
            className: "bold-400",
            dataIndex: "quantity",
            width: "33%",
        },
    ]

    return (
        <Card
            className="space-layout"
            title={trans("products.properties")}>
            <Table
                dataSource={listPropertiesOfCombo}
                rowKey={"id"}
                columns={columns}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
        </Card>
    )
}
