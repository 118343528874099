import CancelAssignApi from "@api/version2/CancelAssignApi"
import { PAGINATION } from "@config/constant"
import { ICancelAssignment, ICancelAssignRequest, IHistoryUpdate } from "@domain/version2/CancelAssign"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    cancelAssignments: ICancelAssignment[]
    loadingCancel: boolean
    loadingUpdate: boolean
    isVisibleHistory: boolean
    historyCancels: IHistoryUpdate[]
    pagination: {
        page: number
        pageSize: number
        total: number
        totalPage: number
    }
    loadingHistory: boolean
}
const initState: State = {
    cancelAssignments: [],
    loadingCancel: false,
    loadingUpdate: false,
    isVisibleHistory: false,
    historyCancels: [],
    pagination: {
        page: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loadingHistory: false
}
export const callGetCancelAssignment = createAsyncThunk("SETTING.CANCEL_ASSIGNMENT", async (_, thunkApi) => {
    try {
        return await CancelAssignApi.getCancelAssignment()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateCancelAssign = createAsyncThunk("SETTING.UPDATE_CANCEL", async (body: ICancelAssignRequest[], thunkApi) => {
    try {
        return await CancelAssignApi.patchCancelAssignment(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const changeVisibleModalHistory = createAction<boolean>("SETTING.VISIBLE_MODAL_HISTORY")

export const callGetHistoryCancel = createAsyncThunk(
    "SETTING.GET_HISTORY",
    async (params: { settingType: string; page: number; pageSize: number }, thunkApi) => {
        try {
            return await CancelAssignApi.getHistoryCancelAssignment(params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const cancelAssignReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetCancelAssignment.pending, (state) => {
            state.loadingCancel = true
            state.cancelAssignments = []
        })
        .addCase(callGetCancelAssignment.fulfilled, (state, { payload }) => {
            state.loadingCancel = false
            state.cancelAssignments = payload?.data
        })
        .addCase(callGetCancelAssignment.rejected, (state) => {
            state.loadingCancel = true
            state.cancelAssignments = []
        })
    builder
        .addCase(callUpdateCancelAssign.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateCancelAssign.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateCancelAssign.rejected, (state) => {
            state.loadingUpdate = false
        })
    builder.addCase(changeVisibleModalHistory, (state, action: PayloadAction<boolean>) => {
        state.isVisibleHistory = action.payload
    })
    builder
        .addCase(callGetHistoryCancel.pending, (state) => {
            state.historyCancels = []
            state.loadingHistory = true
        })
        .addCase(callGetHistoryCancel.fulfilled, (state, { payload }) => {
            state.historyCancels = payload?.data
            state.pagination.page = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingHistory = false
        })
        .addCase(callGetHistoryCancel.rejected, (state) => {
            state.historyCancels = []
            state.loadingHistory = false
        })
})
