import React, { useState } from "react"
import { Button, Card, Input, message, Pagination, Row, Select, Space, Table } from "antd"
import { trans } from "@resources/localization"
import { PAGINATION } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeVisibleDeliveryCaseImport } from "@reducer/version2/delivery-case.reducer"
import { callGetListDeliveryCase, callUpdateAssignee, callUpdateDeliveryCase } from "@reducer/version2/delivery-case.reducer"
import SecurityService from "@util/SecurityService"
import { DELIVERY_CASE_SCOPE, ORDER_SCOPE } from "@config/permission"
import { Link, useSearchParams } from "react-router-dom"
import { formatDateFull } from "@util/Common"
import { find } from "lodash"
import { IDeliveryCase } from "@domain/version2/DeliveryCase"
import { IDetailOrder } from "@domain/version2/OrderDetail"

export const TableDeliveryCase = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const size: number = useAppSelector((state) => state.deliveryCase.pagination.size)
    const current: number = useAppSelector((state) => state.deliveryCase.pagination.current)
    const total: number = useAppSelector((state) => state.deliveryCase.pagination.total)
    const totalPage: number = useAppSelector((state) => state.deliveryCase.pagination.totalPage)
    const loading = useAppSelector((state) => state.deliveryCase.loadingList)
    const listDeliveryCase = useAppSelector((state) => state.deliveryCase.listDeliveryCase)
    const deliveryCaseStatus = useAppSelector((state) => state.categoriesVersion2.deliveryCaseStatus)
    const countries = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const assignees = useAppSelector((state) => state.deliveryCase.deliveryCaseAssignees)
    const reasons = useAppSelector((state) => state.categoriesVersion2.deliveryCaseReason)
    const vendorSuggests = useAppSelector((state) => state.vendor.vendorSuggests)
    const loadingAssignee = useAppSelector((state) => state.deliveryCase.loadingAssignee)
    const loadingUpdate = useAppSelector((state) => state.deliveryCase.loadingUpdate)

    const [currentCode, setCurrentCode] = useState("")
    const updateDeliveryCase = (code: string, key: string, type: string) => {
        if (type === "assignee") {
            dispatch(
                callUpdateAssignee({
                    code,
                    body: {
                        assignee: key,
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    setCurrentCode("")
                    dispatch(
                        callGetListDeliveryCase({
                            ...Object.fromEntries(searchParams.entries()),
                            pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                            sort: "createdAt:DESC",
                        })
                    )
                    message.success(trans("message.success"))
                }
            })
        }
        if (["reason", "note", "trackingNumber", "status"].includes(type)) {
            dispatch(
                callUpdateDeliveryCase({
                    code,
                    body: {
                        ...(type === "reason" && { reason: key }),
                        ...(type === "note" && { note: key }),
                        ...(type === "trackingNumber" && { trackingNumber: key }),
                        ...(type === "status" && { status: key }),
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    setCurrentCode("")
                    dispatch(
                        callGetListDeliveryCase({
                            ...Object.fromEntries(searchParams.entries()),
                            pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                            sort: "createdAt:DESC",
                        })
                    )
                    message.success(trans("message.success"))
                }
            })
        }
    }
    const columns: any[] = [
        {
            title: trans("delivery_case.code"),
            className: "bold-400",
            width: 150,
            dataIndex: "code",
            key: "code",
            align: "left",
        },
        {
            title: trans("contact-detail.order_code"),
            className: "bold-400",
            width: 150,
            dataIndex: "order",
            key: "order",
            algin: "left",
            render: (order: string) =>
                SecurityService.can(ORDER_SCOPE.ORDER_DETAIL_VIEW) || SecurityService.can(ORDER_SCOPE.ORDER_DETAIL_VIEW_ALL) ? (
                    <Link to={`/orders/${order}`}>{order}</Link>
                ) : (
                    <p className="mb-0">{order}</p>
                ),
        },
        {
            title: trans("contact.country"),
            className: "bold-400",
            width: 120,
            dataIndex: "country",
            key: "country",
            align: "left",
            render: (country: string) => (country ? find(countries, { code: country })?.name : ""),
        },
        {
            title: trans("contact.vendor"),
            className: "bold-400",
            width: 100,
            dataIndex: "vendor",
            key: "vendor",
            align: "left",
            render: (vendor: string) => (vendor ? find(vendorSuggests, { code: vendor })?.name : ""),
        },
        {
            title: trans("delivery_case.assignee"),
            className: "bold-400",
            width: 150,
            dataIndex: "assignee",
            key: "assignee",
            align: "left",
            render: (assignee: string, record: IDeliveryCase) => (
                <>
                    {SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_ASSIGNEE) ? (
                        <Select
                            loading={currentCode === record.code && loadingAssignee}
                            onChange={(e) => {
                                setCurrentCode(record.code)
                                updateDeliveryCase(record.code, e, "assignee")
                            }}
                            defaultValue={assignee}
                            className="w-full">
                            {[{ name: trans("contact.unassign_to"), username: null, id: "123" }, ...assignees]?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.username}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        find(assignees, { username: assignee })?.name || ""
                    )}
                </>
            ),
        },
        {
            title: trans("order_status_cancelled.reason"),
            className: "bold-400",
            width: 150,
            dataIndex: "reason",
            key: "reason",
            align: "left",
            render: (reason: string, record: IDeliveryCase) => (
                <>
                    {SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_UPDATE) ? (
                        <Select
                            loading={currentCode === record.code && loadingUpdate}
                            onChange={(e) => {
                                setCurrentCode(record.code)
                                updateDeliveryCase(record.code, e, "reason")
                            }}
                            defaultValue={reason}
                            className="w-full">
                            {reasons?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.code}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        find(reasons, { code: reason })?.name || ""
                    )}
                </>
            ),
        },
        {
            title: trans("order_detail.note"),
            className: "bold-400",
            width: 150,
            dataIndex: "note",
            key: "note",
            align: "left",
            render: (note: string, record: IDeliveryCase) =>
                SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_UPDATE) ? (
                    <Input.TextArea
                        rows={1}
                        disabled={currentCode === record?.code && loadingUpdate}
                        defaultValue={note}
                        onBlur={(e) => {
                            setCurrentCode(record?.code)
                            updateDeliveryCase(record.code, e.target.value, "note")
                        }}
                    />
                ) : (
                    note
                ),
        },
        {
            title: trans("order_detail.tracking_number"),
            className: "bold-400",
            width: 150,
            dataIndex: ["orderDetail", "trackingNumber"],
            key: "trackingNumber",
            align: "left",
            render: (orderDetail: IDetailOrder, record: IDeliveryCase) =>
                !record?.orderDetail?.trackingNumber && SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_UPDATE) ? (
                    <Input
                        disabled={currentCode === record?.code && loadingUpdate}
                        defaultValue={record?.trackingNumber}
                        onBlur={(e) => {
                            setCurrentCode(record?.code)
                            updateDeliveryCase(record.code, e.target.value, "trackingNumber")
                        }}
                    />
                ) : (
                    record?.orderDetail?.trackingNumber || record?.trackingNumber
                ),
        },
        {
            title: trans("campaign.status"),
            className: "bold-400",
            width: 100,
            dataIndex: "status",
            key: "status",
            align: "left",
            render: (status: string, record: IDeliveryCase) => (
                <Select
                    loading={currentCode === record.code && loadingUpdate}
                    onChange={(e) => {
                        setCurrentCode(record.code)
                        updateDeliveryCase(record.code, e, "status")
                    }}
                    disabled={["DCS4", "DCS5"].includes(status) || !SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_UPDATE) || !record.reason}
                    defaultValue={status}
                    className="w-full">
                    {deliveryCaseStatus?.map((item) => (
                        <Select.Option
                            key={item?.id}
                            value={item?.code}>
                            {item?.name}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: trans("campaign.create_at"),
            className: "bold-400",
            width: 150,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "left",
            render: (date: string) => (date ? formatDateFull(date) : "---"),
        },
        {
            title: trans("campaign.create_by"),
            className: "bold-400",
            width: 100,
            dataIndex: "createdBy",
            key: "createdBy",
            align: "left",
        },
    ]
    const onHandleChange = (newPage: number, newSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !oldSearch.pageSize || Number(oldSearch.pageSize) === newSize ? newPage : PAGINATION.DEFAULT_CURRENT_PAGE,
            pageSize: newSize,
        }
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${current}/${totalPage}(${total})` : ""}
            extra={
                <Space>
                    {SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_IMPORT) && (
                        <Button
                            type="primary"
                            className="m-0 ml-2"
                            onClick={() => dispatch(changeVisibleDeliveryCaseImport(true))}
                            icon={<i className="fa-solid fa-cloud-arrow-up mr-2" />}>
                            {trans("delivery_case.import")}
                        </Button>
                    )}
                </Space>
            }>
            <Table
                rowKey={"id"}
                scroll={{
                    x: 1920,
                }}
                loading={loading}
                columns={columns}
                dataSource={listDeliveryCase}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    current={current}
                    pageSize={size}
                    total={total}
                    onChange={onHandleChange}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
