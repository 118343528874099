import { PAGINATION } from "@config/constant"
import { IChangedValueSla, IHistorySlaSetting } from "@domain/version2/SlaSetting"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetHistorySlaSetting, changeVisibleSlaHistory } from "@reducer/version2/slaSetting.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Col, List, Modal, Pagination, Row, Typography } from "antd"
import React, { useEffect } from "react"
export const HistorySlaSetting: React.FC = () => {
    const visibleHistory = useAppSelector((state) => state.settingSla.visibleSla)
    const histories = useAppSelector((state) => state.settingSla.historySlaSettings)
    const page = useAppSelector((state) => state.settingSla.pagination.page)
    const pageSize = useAppSelector((state) => state.settingSla.pagination.pageSize)
    const total = useAppSelector((state) => state.settingSla.pagination.total)
    const totalPage = useAppSelector((state) => state.settingSla.pagination.totalPage)
    const loadingHistory = useAppSelector((state) => state.settingSla.loadingHistory)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(
            callGetHistorySlaSetting({
                settingType: "sla_setting",
                page: PAGINATION.DEFAULT_CURRENT_PAGE,
                pageSize: 5,
            })
        )
    }, [dispatch])
    const onChangePage = (newPage: number, newSize: number) => {
        dispatch(
            callGetHistorySlaSetting({
                settingType: "sla_setting",
                page: newSize !== pageSize ? PAGINATION.DEFAULT_CURRENT_PAGE : newPage,
                pageSize: newSize,
            })
        )
    }

    return (
        <Modal
            width={790}
            open={visibleHistory}
            onCancel={() => dispatch(changeVisibleSlaHistory(false))}
            destroyOnClose
            maskClosable={false}
            footer={null}
            title={`${trans("setting.sla")} - ${trans("setting.update_history")}`}>
            <div>{total !== 0 ? `${trans("contact.page")} ${page}/${totalPage}(${total})` : ""}</div>
            <List
                loading={loadingHistory}
                itemLayout="vertical"
                size="large"
                pagination={false}
                dataSource={histories}
                renderItem={(item: IHistorySlaSetting) => (
                    <List.Item className="mt-2">
                        <Row
                            gutter={12}
                            className="mt-2">
                            <Col
                                xs={24}
                                sm={24}
                                md={12}>
                                <Typography.Text className="font-medium">{trans("setting.update_at")}:</Typography.Text>
                                <Typography.Text className="ml-2">{item?.createdAt ? formatDateFull(item?.createdAt || "") : ""}</Typography.Text>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}>
                                <Typography.Text className="font-medium">{trans("setting.update_by")}:</Typography.Text>
                                <Typography.Text className="ml-2">{item?.createdBy}</Typography.Text>
                            </Col>
                        </Row>
                        <div className="grid grid-cols-7 gap-x-4 bg-gray-200 p-2 mt-2">
                            <div className="col-span-1 font-medium">{trans("setting.rule_code")}</div>
                            <div className="col-span-1 font-medium">{trans("setting.rule_name")}</div>
                            <div className="col-span-1 font-medium">{trans("setting.max_of_stock")}</div>
                            <div className="col-span-1 font-medium">{trans("setting.contact_quantity")}</div>
                            <div className="col-span-1 font-medium">{trans("contact.created_at")}</div>
                            <div className="col-span-1 font-medium">{trans("order_detail.created_by")}</div>
                            <div className="col-span-1 font-medium">{trans("table.action")}</div>
                        </div>
                        {item?.values?.map((item: IChangedValueSla) => (
                            <div
                                key={item?.id}
                                className="grid grid-cols-7 gap-x-4 mt-2 border-b">
                                <div className="col-span-1">{item?.code}</div>
                                <div className="col-span-1">{item?.name}</div>
                                <div className="col-span-1 flex justify-end">{item?.maximumOfStock}</div>
                                <div className="col-span-1 flex justify-end">{item?.quantityContact}</div>
                                <div className="col-span-1">{item?.createdAt ? formatDateFull(item?.createdAt) : ""}</div>
                                <div className="col-span-1">{item?.createdBy || ""}</div>
                                <div className="col-span-1">{item?.isActive ? trans("user_list.active") : trans("user_list.inactive")}</div>
                            </div>
                        ))}
                    </List.Item>
                )}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={[1, 5, 10, 15]}
                    defaultCurrent={1}
                    current={page}
                    pageSize={pageSize}
                    total={total}
                    onChange={onChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Modal>
    )
}
