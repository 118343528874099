import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import { OrderApi } from "@api/version2/Order"

import { IDetailOrder, IOrderRequest, ItemOrderRequest, IUpdateOrderQuery } from "@domain/version2/OrderDetail"
import { IDeliveryCase } from "@domain/version2/DeliveryCase"

interface State {
    loadingDetail: boolean
    orderDetail: IDetailOrder
    loadingUpdateOrder: boolean
    isUpdateNote: boolean
    isUpdateShippingAddress: boolean
    loadingUpdateOrderItem: boolean
    loadingUpdateOrderStatus: boolean
    dataUpdateOrderItem: ItemOrderRequest[]
    dataOrderUpdate: IOrderRequest
    loadingUpdatePaid: boolean
    visibleFormReasonCancel: boolean
    listDeliveryCases: IDeliveryCase[]
    loadingListDeliveryCases: boolean
    loadingUpdateFinancialStatus: boolean
    isShowImport: boolean
    loadingImport: boolean
}

const initState: State = {
    loadingDetail: false,
    orderDetail: {} as IDetailOrder,
    loadingUpdateOrder: false,
    isUpdateNote: false,
    isUpdateShippingAddress: false,
    loadingUpdateOrderItem: false,
    loadingUpdateOrderStatus: false,
    dataUpdateOrderItem: [],
    dataOrderUpdate: {},
    loadingUpdatePaid: false,
    visibleFormReasonCancel: false,
    listDeliveryCases: [],
    loadingListDeliveryCases: false,
    loadingUpdateFinancialStatus: false,
    isShowImport: false,
    loadingImport: false
}

export const getOrderDetail = createAsyncThunk("ORDER_DETAIL", async (code: string, thunkApi) => {
    try {
        return await OrderApi.getOrderDetail(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const fetchUpdateOrder = createAsyncThunk("ORDER_DETAIL.UPDATE_ORDER", async (payload: { code: string; body: IOrderRequest }, thunkApi) => {
    try {
        return await OrderApi.updateOrder(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const fetchUpdateOrderItem = createAsyncThunk(
    "ORDER_DETAIL.UPDATE_ORDER_ITEM",
    async (payload: { code: string; body: { orderItems: ItemOrderRequest[], orderCombos: ItemOrderRequest[] } }, thunkApi) => {
        try {
            return await OrderApi.updateOrderItem(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const fetchUpdateOrderStatus = createAsyncThunk("ORDER_DETAIL.UPDATE_STATUS", async (payload: { code: string; body: IUpdateOrderQuery }, thunkApi) => {
    try {
        return await OrderApi.updateOrderStatus(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const fetchUpdateOrderPaid = createAsyncThunk("ORDER_DETAIL.UPDATE_PAID", async (payload: { code: string; body: { isPaid: boolean } }, thunkApi) => {
    try {
        return await OrderApi.updateOrderPaid(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetListDeliveryCasesOfOrder = createAsyncThunk("ORDER_DETAIL.GET_DELIVERY_CASES", async (payload: { orderCode: string }, thunkApi) => {
    try {
        return await OrderApi.getListDeliveryCasesOfOrder(payload.orderCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateOrderFinancialStatus = createAsyncThunk("ORDER_DETAIL.UPDATE_FINANCIAL_STATUS", async (payload:{orderCode: string, financial: string}, thunkApi) => {
    try {
        return await OrderApi.updateOrderFinancialStatus(payload.orderCode, {financial: payload.financial})
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callImportOrderFinancialStatus= createAsyncThunk("ORDER.IMPORT_FINANCIAL_STATUS", async (payload: { file: any }, thunkApi) => {
    try {
        return await OrderApi.importOrderFinancialStatus(payload.file)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const changeEditNoteOrder = createAction<boolean>("ORDER_DETAIL.CHANGE_EDIT_NOTE_ORDER")
export const changeEditShippingAddress = createAction<boolean>("ORDER_DETAIL.CHANGE_EDIT_SHIPPING_ADDRESS")
export const changeDataOrderUpdate = createAction<IOrderRequest>("ORDER_DETAIL.UPDATE_DATA")
export const changeDataUpdateOrderItem = createAction<ItemOrderRequest[] | undefined>("ORDER_DETAIL.UPDATE_DETAIL_DATA")
export const changeVisibleFormReasonCancel = createAction<boolean>("ORDER_DETAIL.FORM_REASON_CANCEL")
export const changeVisibleOrderImport = createAction<boolean>("ORDER_DETAIL.CHANGE_VISIBLE_IMPORT")


export const orderDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(getOrderDetail.pending, (state) => {
            state.loadingDetail = true
            state.orderDetail = {} as IDetailOrder
        })
        .addCase(getOrderDetail.fulfilled, (state, { payload }) => {
            state.orderDetail = payload?.data
            state.loadingDetail = false
        })
        .addCase(getOrderDetail.rejected, (state) => {
            state.loadingDetail = false
        })
    builder
        .addCase(fetchUpdateOrder.pending, (state) => {
            state.loadingUpdateOrder = true
        })
        .addCase(fetchUpdateOrder.fulfilled, (state) => {
            state.loadingUpdateOrder = false
        })
        .addCase(fetchUpdateOrder.rejected, (state) => {
            state.loadingUpdateOrder = false
        })
    builder
        .addCase(fetchUpdateOrderItem.pending, (state) => {
            state.loadingUpdateOrderItem = true
        })
        .addCase(fetchUpdateOrderItem.fulfilled, (state) => {
            state.loadingUpdateOrderItem = false
        })
        .addCase(fetchUpdateOrderItem.rejected, (state) => {
            state.loadingUpdateOrderItem = false
        })
    builder
        .addCase(fetchUpdateOrderStatus.pending, (state) => {
            state.loadingUpdateOrderStatus = true
        })
        .addCase(fetchUpdateOrderStatus.fulfilled, (state) => {
            state.loadingUpdateOrderStatus = false
        })
        .addCase(fetchUpdateOrderStatus.rejected, (state) => {
            state.loadingUpdateOrderStatus = false
        })
    builder
        .addCase(fetchUpdateOrderPaid.pending, (state) => {
            state.loadingUpdatePaid = true
        })
        .addCase(fetchUpdateOrderPaid.fulfilled, (state) => {
            state.loadingUpdatePaid = false
        })
        .addCase(fetchUpdateOrderPaid.rejected, (state) => {
            state.loadingUpdatePaid = false
        })

    builder.addCase(changeEditNoteOrder, (state, action: PayloadAction<boolean>) => {
        state.isUpdateNote = action.payload
    })
    builder.addCase(changeEditShippingAddress, (state, action: PayloadAction<boolean>) => {
        state.isUpdateShippingAddress = action.payload
    })
    builder.addCase(changeDataUpdateOrderItem, (state, action: PayloadAction<ItemOrderRequest[] | undefined>) => {
        state.dataUpdateOrderItem = action?.payload || []
    })
    builder.addCase(changeDataOrderUpdate, (state, action: PayloadAction<IOrderRequest>) => {
        state.dataOrderUpdate = {
            ...state.dataOrderUpdate,
            ...action.payload,
        }
    })

    builder.addCase(changeVisibleFormReasonCancel, (state, action) => {
        state.visibleFormReasonCancel = action.payload
    })

    builder
        .addCase(callGetListDeliveryCasesOfOrder.pending, (state) => {
            state.listDeliveryCases = []
            state.loadingListDeliveryCases = true
        })
        .addCase(callGetListDeliveryCasesOfOrder.fulfilled, (state, { payload }) => {
            state.listDeliveryCases = payload?.data
            state.loadingListDeliveryCases = false
        })
        .addCase(callGetListDeliveryCasesOfOrder.rejected, (state) => {
            state.loadingListDeliveryCases = false
        })
    builder
        .addCase(callUpdateOrderFinancialStatus.pending, (state) => {
            state.loadingUpdateFinancialStatus = true
        })
        .addCase(callUpdateOrderFinancialStatus.fulfilled, (state) => {
            state.loadingUpdateFinancialStatus = false
        })
        .addCase(callUpdateOrderFinancialStatus.rejected, (state) => {
            state.loadingUpdateFinancialStatus = false
        })
    builder
        .addCase(changeVisibleOrderImport, (state, action: PayloadAction<boolean>) => {
            state.isShowImport = action.payload
        })
    builder
        .addCase(callImportOrderFinancialStatus.pending, (state) => {
            state.loadingImport = true
        })
        .addCase(callImportOrderFinancialStatus.fulfilled, (state) => {
            state.loadingImport = false
        })
        .addCase(callImportOrderFinancialStatus.rejected, (state) => {
            state.loadingImport = false
        })
})
