import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

import IUser from "@domain/version2/User"
import UserApi from "@api/version2/UserApi"

interface State {
    pageTitle: string
    currentUser?: IUser
}

const initState: State = {
    pageTitle: "Common",
}

export const changePageTitleAction = createAction<string>("CHANGE_PAGE_TITLE")

export const fetchCurrentUser = createAsyncThunk("users/current", async (_, thunkApi) => {
    try {
        return await UserApi.getCurrentUser()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const commonReducer = createReducer(initState, (builder) => {
    builder
        .addCase(changePageTitleAction, (state, action: PayloadAction<string>) => {
            state.pageTitle = action.payload
        })
        .addCase(fetchCurrentUser.rejected, (state, action) => {
            console.log("error", action.payload)
        })
})
