import { ILeader, ITelesales, ITelesalesQuery } from "@domain/version2/Telesales"
import apiClient from "@util/ApiClient"

export class TelesalesApi {
    static getListTelesales(params: ITelesalesQuery): Promise<{ data: ITelesales[]; headers: { [key: string]: any } }> {
        return apiClient.get(`telesales`, { params })
    }

    static createTelesales(payload: { username: string }): Promise<any> {
        return apiClient.post(`telesales`, payload)
    }

    static getListLeaderSuggest(params: { username?: string; inSameGroupWith?: string }): Promise<{ data: ILeader[] }> {
        return apiClient.get(`telesales/autocomplete`, { params })
    }

    static updateSlaSettingOfTelesales(id: string, body: { slaSettingCode: string }): Promise<{ data: ILeader[] }> {
        return apiClient.put(`telesales/${id}/sla-setting`, body)
    }
}
