import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import { ICallLog, ICallLogQuery } from "@domain/version2/CallStringee"
import CallStringeeService from "@api/version2/StringeeApi"

interface State {
    callLogs: Array<ICallLog>
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    loading: boolean
    tokenStringee: string
}

const initState: State = {
    callLogs: [],
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loading: false,
    tokenStringee: "",
}

export const callGetCallLogs = createAsyncThunk("CALL_STRINGEE.GET_LIST", async (params: ICallLogQuery, thunkApi) => {
    try {
        return await CallStringeeService.getListLogs(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const setTokenStringee = createAction<any>("CALL_STRINGEE.SET_TOKEN")

export const callStringeeReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetCallLogs.pending, (state) => {
            state.loading = true
            state.callLogs = []
        })
        .addCase(callGetCallLogs.fulfilled, (state, action) => {
            state.callLogs = action.payload.data ? action.payload.data : []
            state.pagination.currentPage = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loading = false
        })
        .addCase(callGetCallLogs.rejected, (state) => {
            state.loading = false
        })
    builder.addCase(setTokenStringee, (state, action: PayloadAction<string>) => {
        state.tokenStringee = action.payload
    })
})
