import React, { PropsWithChildren, useEffect, useState } from "react"

import { Layout, Spin } from "antd"

import { Header } from "@component/Layout/Header"
import SideBar from "@component/Layout/Sidebar"

import IUser from "@domain/version2/User"

import SecurityService from "@util/SecurityService"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"

const { Content, Footer } = Layout

interface Props extends PropsWithChildren<any> {
    loading?: boolean
}

export const DefaultLayout = (props: Props) => {
    const [collapsed, setCollapsed] = useState(false)
    const [user] = useState<IUser>(SecurityService.getUser())
    const title = useAppSelector((state) => state.common.pageTitle)

    useEffect(() => {
        document.title = title
    }, [title])

    const toggle = () => {
        setCollapsed(!collapsed)
    }

    const onCollapsed = (collapsed: boolean) => {
        setCollapsed(collapsed)
    }

    return (
        <Layout className={"main-layout"}>
            <SideBar
                collapsed={collapsed}
                onCollapsed={onCollapsed}
            />
            <Layout>
                <Header
                    collapsed={collapsed}
                    toggle={toggle}
                    user={user}
                    onCollapsed={onCollapsed}
                />
                <Content className={`main-content h-100pc ${!collapsed ? "collapsed-more-site" : "collapsed-less-site"}`}>
                    <div className={props.loading ? "h-100pc" : "main-inner h-100pc"}>
                        <Spin
                            tip={trans("message.loading")}
                            spinning={props.loading}>
                            {props.children}
                        </Spin>
                    </div>
                </Content>
                <Footer className="footer">
                    Một sản phẩm của <b>Vela One</b>
                </Footer>
            </Layout>
        </Layout>
    )
}

export default DefaultLayout
