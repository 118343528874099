import React, { useEffect } from "react"
import { trans } from "@resources/localization"
import { Card, Pagination, Row, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { PAGINATION } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetVendorsOfProduct } from "@reducer/version2/product-detail"

export const VendorList: React.FC = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const total = useAppSelector((state) => state.productDetail.pagination.total)
    const currentPage = useAppSelector((state) => state.productDetail.pagination.current)
    const totalPage = useAppSelector((state) => state.productDetail.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.productDetail.pagination.size)
    const listVendorsOfProduct = useAppSelector((state) => state.productDetail.listVendorsOfProduct)
    const vendorsOfProduct = listVendorsOfProduct?.map((item) => item.vendor)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            callGetVendorsOfProduct({
                code: id,
                params: {
                    ...params,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                },
            })
        )
    }, [dispatch, searchParams])

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }

    const countryList = [
        { key: "VNM", title: "Viet Nam" },
        { key: "MMR", title: "Myanmar" },
        { key: "IDN", title: "Indonesia" },
        { key: "THA", title: "Thailand" },
        { key: "PHL", title: "Philippines" },
        { key: "MYS", title: "Malaysia" },
    ]

    const columns: ColumnsType<any> = [
        {
            title: trans("vendor.code"),
            className: "bold-400",
            dataIndex: "code",
        },
        {
            title: trans("vendor.name"),
            className: "bold-400",
            dataIndex: "name",
        },
        {
            title: trans("vendor.admin"),
            className: "bold-400",
            dataIndex: "createdBy",
        },
        {
            title: trans("vendor.country"),
            className: "bold-400",
            dataIndex: "countryCode",
            render: (countryCode: string) => countryList.find((item) => item.key === countryCode)?.title,
        },
    ]

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
            <Table
                dataSource={vendorsOfProduct}
                rowKey={"id"}
                columns={columns}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
