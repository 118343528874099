import { Button, Col, Input, Row } from "antd"
import React from "react"
import { Form } from "antd"
import { trans } from "@resources/localization"
type Props = {
    onFilter: (data: { from_alias: string }) => void
}
export const FilterHistory: React.FC<Props> = ({ onFilter }) => {
    const [formRef] = Form.useForm()
    return (
        <Form
            form={formRef}
            onFinish={onFilter}
            layout="vertical">
            <Row gutter={12}>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item name="from_alias">
                        <Input allowClear placeholder={trans("user_list.search_username")} />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="mr-3">
                        {trans("button.filter")}
                    </Button>
                    <Button
                        type="default"
                        onClick={() => {
                            formRef.resetFields()
                            onFilter({ from_alias: "" })
                        }}>
                        {trans("contact.clear")}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}
