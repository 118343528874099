import { COUNTRY_CODE } from "@config/constant"
import { ILocation } from "@domain/version2/Country"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, resetDataArea, resetDataCity, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { trans } from "@resources/localization"
import { Col, Form, Input, Row, Select, Empty } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect } from "react"
type Props = {
    formRef: any
}
export const Address: React.FC<Props> = ({ formRef }) => {
    const dispatch: any = useAppDispatch()
    const contactDetail = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    const provinces = useAppSelector((state) => state.countriesVersion2.provincesSuggest)
    const districts = useAppSelector((state) => state.countriesVersion2?.districtsSuggest)
    const areas = useAppSelector((state) => state.countriesVersion2?.areasSuggest)
    const cities = useAppSelector((state) => state.countriesVersion2?.citiesSuggest)

    useEffect(() => {
        formRef.setFieldsValue({
            province: contactDetail?.province?.code,
            district: contactDetail?.district?.code,
            city: contactDetail?.city?.code,
            ward: contactDetail?.ward,
            address: contactDetail?.address,
        })
    }, [contactDetail])

    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name="province"
                    label={trans("contact-detail.province")}>
                    <Select
                        placeholder={trans("order_list.district_placeholder")}
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        optionFilterProp="children"
                        notFoundContent={<Empty />}
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        }}
                        onChange={(e) => {
                            dispatch(resetDataArea([]))
                            dispatch(resetDataDistrict([]))
                            dispatch(resetDataCity([]))
                            formRef.setFieldsValue({
                                city: "",
                                district: "",
                                ward: "",
                            })
                            if (e) {
                                dispatch(getCitySuggest(e))
                            }
                        }}>
                        {provinces?.map((item: ILocation) => (
                            <Select.Option
                                key={item?.id}
                                value={item?.code}>
                                {item?.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
                <Col span={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.province !== next.province}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                name="city"
                                label={contactDetail?.countryCode === COUNTRY_CODE.VIETNAM ? trans("contact-detail.district") : trans("order_list.district")}>
                                <Select
                                    placeholder={contactDetail?.countryCode === COUNTRY_CODE.VIETNAM ? trans("contact-detail.district_pl") : trans("order_list.city_placeholder")}
                                    showSearch
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    notFoundContent={<Empty />}
                                    disabled={!getFieldValue("province")}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    onChange={(e) => {
                                        dispatch(resetDataArea([]))
                                        dispatch(resetDataDistrict([]))
                                        formRef.setFieldsValue({
                                            district: "",
                                            ward: "",
                                        })
                                        if (e) {
                                            dispatch(getDistrictSuggest(e))
                                        }
                                    }}>
                                    {cities?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>

            <Col span={24}>
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) => pre.city !== next.city}>
                    {({ getFieldValue }) => (
                        <Form.Item
                            name="district"
                            label={contactDetail?.countryCode === COUNTRY_CODE.VIETNAM ? trans("contact_activity.ward") : trans("contact-detail.district")}>
                            <Select
                                placeholder={contactDetail?.countryCode === COUNTRY_CODE.VIETNAM ? trans('order_list.ward_placeholder'): trans("contact-detail.district_pl")}
                                showSearch
                                getPopupContainer={(trigger) => trigger.parentNode}
                                notFoundContent={<Empty />}
                                disabled={
                                    !getFieldValue("city")
                                }
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                onChange={(e) => {
                                    dispatch(resetDataArea([]))
                                    formRef.setFieldsValue({
                                        ward: "",
                                    })
                                    if (e) {
                                        dispatch(getAreaSuggest(e))
                                    }
                                }}>
                                {districts?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form.Item>
            </Col>

            {!isEmpty(areas) ? (
                <Col span={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.district !== next.district}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                name="ward"
                                label={trans("contact-detail.area")}>
                                <Select
                                    placeholder={trans("placeholder.select_area")}
                                    showSearch
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    disabled={!getFieldValue("district")}
                                    optionFilterProp="children"
                                    notFoundContent={<Empty />}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}>
                                    {areas?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
            ) : (
                <Col span={24}>
                    <Form.Item
                        name="ward"
                        label={trans("contact-detail.area")}>
                        <Input
                            placeholder={trans("contact-detail.area")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    ward: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
            )}
            <Col span={24}>
                <Form.Item
                    name="address"
                    label={trans("contact-detail.address_detail")}>
                    <Input.TextArea
                        rows={4}
                        onBlur={(e) => {
                            formRef.setFieldsValue({
                                address: e.target.value.trim(),
                            })
                        }}
                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}
