import { IProduct } from "@domain/version2/Product"
import { ISkuComboOfTelesaleGroup } from "@domain/version2/SkuCombo"
import { IGroupRequest, IGroupTelesales, IGroupTelesalesQuery, ITelesalesNotInGroup } from "@domain/version2/Telesales"
import { IProductInGroup, ITelesalesInGroup, ITheSameTelesales } from "@domain/version2/TelesalesGroup"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export class TelesalesGroupApi {
    static getListGroupTelesales(params: IGroupTelesalesQuery): Promise<{ data: IGroupTelesales[]; headers: { [key: string]: any } }> {
        return apiClient.get(`telesale-groups`, { params })
    }

    static createTelesalesGroup(payload: IGroupRequest): Promise<any> {
        return apiClient.post(`telesale-groups`, payload)
    }

    static getTelesalesGroupsSuggest(params:{query?: string, hasTelesales?: string}): Promise<{ data: IGroupTelesales[] }> {
        return apiClient.get(`telesale-groups/autocomplete`, {params })
    }

    static updateNameTelesalesGroup(code: string, body: { new_name: string }): Promise<any> {
        return apiClient.patch(`telesale-groups/${code}/name`, body)
    }

    static getDetailTelesaleGroup(code: string): Promise<any> {
        return apiClient.get(`telesale-groups/${code}`)
    }

    static getTelesalesInGroup(
        code: string,
        params: { page: number; pageSize: number; sort: string }
    ): Promise<{ data: ITelesalesInGroup[]; headers: { [key: string]: any } }> {
        return apiClient.get(`telesale-groups/${code}/telesales`, { params })
    }

    static deleteTelesaleOfGroup(code: string, body: { telesales: Array<string> }): Promise<any> {
        return apiClient.delete(`telesale-groups/${code}/telesales`, { data: body })
    }
    static getTelesalesNotInGroup(code: string, params: { keyword: string }): Promise<{ data: ITelesalesNotInGroup[] }> {
        return apiClient.get(`telesale-groups/${code}/telesales-not-in`, { params })
    }

    static addTelesalesToGroup(code: string, body: { telesales: Array<string> }): Promise<any> {
        return apiClient.patch(`telesale-groups/${code}/telesales`, body)
    }

    static getProductSuggest(code: string, params: { keyword: string }): Promise<{ data: IProduct[] }> {
        return apiClient.get(`telesale-groups/${code}/products-not-in`, { params })
    }

    static addProductToGroup(code: string, body: { productCodes: string[] }): Promise<any> {
        return apiClient.patch(`telesale-groups/${code}/products`, body)
    }

    static getProductInGroup(code: string, params: { page: number; pageSize: number }): Promise<{ data: { data: IProductInGroup[]; [key: string]: any } }> {
        return apiClient.get(`telesale-groups/${code}/products`, { params })
    }

    static removeProductFromGroup(code: string | undefined, body: { productCodes: string[] }): Promise<any> {
        return apiClient.delete(`telesale-groups/${code}/products`, {
            data: body,
        })
    }

    static getAllTelesalesGroup(): Promise<{ data: IGroupTelesales[] }> {
        return apiClient.get(`telesale-groups/all`)
    }

    static getTheSameTelesalesInGroups(code: string): Promise<{ data: ITheSameTelesales[] }> {
        return apiClient.get(`telesale-groups/${code}/telesales/autocomplete`)
    }

    static getListComboInGroup(code: string, params: { page: number; pageSize: number }): Promise<AxiosResponse<ISkuComboOfTelesaleGroup[]>> {
        return apiClient.get(`telesale-groups/${code}/combos`, { params })
    }

    static deleteComboOfGroup(code: string, body: { comboCodes: string[] }): Promise<AxiosResponse<any>> {
        return apiClient.delete(`telesale-groups/${code}/combos`, { data: body })
    }

    static updateListComboInGroup(code: string, body: { comboCodes: string[] }): Promise<AxiosResponse<any>> {
        return apiClient.patch(`telesale-groups/${code}/combos`, body)
    }
}
