import React, { useEffect, useState } from "react"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { useSearchParams } from "react-router-dom"
import moment from "moment"
import { FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { forEach, isEmpty, omitBy } from "lodash"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetUserAutocomplete } from "@reducer/version2/user.reducer"

export const Filter = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [formRef] = Form.useForm()
    const [isShowMore, setIsShowMore] = useState<boolean>(false)
    const deliveryCaseStatus = useAppSelector((state) => state.categoriesVersion2.deliveryCaseStatus)
    const countries = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const assignees = useAppSelector((state) => state.deliveryCase.deliveryCaseAssignees)
    const reasons = useAppSelector((state) => state.categoriesVersion2.deliveryCaseReason)
    const vendorSuggests = useAppSelector((state) => state.vendor.vendorSuggests)
    const userSuggests = useAppSelector((state) => state.user.listUserAutocomplete)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("createdAtFrom") &&
                    searchParams.has("createdAtTo") && {
                        createdAt: [moment(oldSearch?.createdFrom), moment(oldSearch?.createdTo)],
                    }),
                ...(searchParams.has("status") && {
                    status: oldSearch?.status?.split(","),
                }),
                ...(searchParams.has("reason") && {
                    reason: oldSearch?.reason?.split(","),
                }),
                ...(searchParams.has("country") && {
                    country: oldSearch?.country?.split(","),
                }),
                ...(searchParams.has("assignee") && {
                    assignee: oldSearch?.assignee?.split(","),
                }),
                ...(searchParams.has("vendor") && {
                    vendor: oldSearch?.vendor?.split(","),
                }),
                ...(searchParams.has("createdBy") && {
                    createdBy: oldSearch?.createdBy?.split(","),
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const onFilter = (values: any) => {
        const oldSearch = Object.fromEntries(searchParams.entries())

        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                createdAtFrom: values?.createdAt ? moment(values?.createdAt[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
                createdAtTo: values?.createdAt ? moment(values?.createdAt[1].format(FORMAT_DATE_END)).toISOString() : undefined,
                status: values?.status?.join(",") || undefined,
                reason: values?.reason?.join(",") || undefined,
                country: values?.country?.join(",") || undefined,
                assignee: values?.assignee?.join(",") || undefined,
                vendor: values?.vendor?.join(",") || undefined,
                createdBy: values?.createdBy?.join(",") || undefined,
                trackingNumber: values?.trackingNumber ? values.trackingNumber.trim() : undefined,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "user":
                    dispatch(callGetUserAutocomplete(searchText.trim()))
                    break

                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "user":
                    dispatch(callGetUserAutocomplete(""))
                    break

                default:
                    break
            }
        }
    }
    return (
        <Card className="space-layout">
            <Form
                onFinish={onFilter}
                layout="vertical"
                form={formRef}>
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="order"
                            label={trans("contact-detail.order_code")}>
                            <Input
                                allowClear
                                suffix={<i className={"fas fa-search"} />}
                                placeholder={trans("delivery_case.order_pl")}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="country"
                            label={trans("contact.country")}>
                            <Select
                                placeholder={trans("order_list.country")}
                                mode="multiple">
                                {countries?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="assignee"
                            label={trans("delivery_case.assignee")}>
                            <Select
                                placeholder={trans("delivery_case.asignee_pl")}
                                mode="multiple">
                                {assignees?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.username}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {isShowMore && (
                        <>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="code"
                                    label={trans("delivery_case.code")}>
                                    <Input
                                        suffix={<i className={"fas fa-search"} />}
                                        placeholder={trans("delivery_case.code_pl")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="createdAt"
                                    label={trans("order_list.created_at")}>
                                    <DatePicker.RangePicker
                                        format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                        placeholder={[trans("order_list.from"), trans("order_list.to")]}
                                        className={"_filter_created_at w-full"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="status"
                                    label={trans("order_list.status")}>
                                    <Select
                                        placeholder={trans("order_list.select_status")}
                                        mode="multiple">
                                        {deliveryCaseStatus?.map((item) => (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="vendor"
                                    label={trans("contact.vendor")}>
                                    <Select
                                        placeholder={trans("dashboard.select_vendor")}
                                        mode="multiple">
                                        {vendorSuggests?.map((item) => (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="reason"
                                    label={trans("order_status_cancelled.reason")}>
                                    <Select
                                        placeholder={trans("delivery_case.reason_pl")}
                                        mode="multiple">
                                        {reasons?.map((item) => (
                                            <Select.Option
                                                key={item?.code}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="trackingNumber"
                                    label={trans("order_detail.tracking_number")}>
                                    <Input
                                        suffix={<i className={"fas fa-search"} />}
                                        placeholder={trans("delivery_case.tracking_number_pl")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    name="createdBy"
                                    label={trans("campaign.create_by")}>
                                    <Select
                                        showSearch
                                        allowClear
                                        onBlur={() => onSearchAutocomplete("", "user")}
                                        onSearch={(e) => {
                                            onSearchAutocomplete(e, "user")
                                        }}
                                        placeholder={trans("order_list.created_by_placeholder")}
                                        mode="multiple">
                                        {userSuggests?.map((item) => (
                                            <Select.Option
                                                key={item?.username}
                                                value={item?.username}>
                                                {item?.username}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    <Col
                        span={24}
                        className={`form-filter-control filter-button`}>
                        <Button
                            type="primary"
                            className="mg-r-10 _filter_button_filter"
                            htmlType="submit">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            className="mg-r-10 _filter_button_clear"
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({})
                            }}>
                            {trans("contact.clear")}
                        </Button>
                        <Button
                            type="link"
                            onClick={() => setIsShowMore((prev) => !prev)}>
                            {isShowMore ? trans("contact.filter_less") : trans("contact.filter_more")}
                            <i className={`ml-2 fa-solid ${isShowMore ? "fa-chevron-up" : "fa-chevron-down"}`} />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
