import React, { useEffect, useState } from "react"
import { useAppSelector } from "@hook/useAppSelector"

import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { trans } from "@resources/localization"

import { useSearchParams } from "react-router-dom"
import { ICallStatus, IContactStatus } from "@domain/version2/Categories"
import { forEach, isEmpty, omitBy } from "lodash"
import moment from "moment"
import { CALL_OPERATIONS, FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { ICampaign } from "@domain/version2/Campaign"
import { ILocation } from "@domain/version2/Country"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetCampaigns } from "@reducer/version2/contact.reducer"
import { callGetListLeaderSuggest } from "@reducer/version2/telesales-group.reducer"
import { callGetProductAutocomplete } from "@reducer/version2/product.reducer"
import { IVendor } from "@domain/version2/Vendor"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"

const Option = Select.Option

export const ContactFilter = () => {
    const [isShowMore, setIsShowMore] = useState<boolean>(false)
    const contactStatus: Array<IContactStatus> = useAppSelector((state) => state.categoriesVersion2.contactStatus)
    const listCampaigns = useAppSelector((state) => state.contactVersion2.listCampaigns)
    const callStatus: Array<ICallStatus> = useAppSelector((state) => state.categoriesVersion2.callStatus)
    const countriesSuggest = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const assignees = useAppSelector((state) => state.telesalesGroup.listLeader)
    const productAutocomplete = useAppSelector((state) => state.product.productSuggest)
    const vendorSuggest = useAppSelector((state) => state.vendor.vendorSuggests)
    const [formRef] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("createdAtFrom") &&
                    searchParams.has("createdAtTo") && {
                        createdAt: [moment(oldSearch?.createdAtFrom), moment(oldSearch?.createdAtTo)],
                    }),
                ...(searchParams.has("assignedAtFrom") &&
                    searchParams.has("assignedAtTo") && {
                        assignedAt: [moment(oldSearch?.assignedAtFrom), moment(oldSearch?.assignedAtTo)],
                    }),
                ...(searchParams.has("lastCallFrom") &&
                    searchParams.has("lastCallTo") && {
                        lastCall: [moment(oldSearch?.lastCallFrom), moment(oldSearch?.lastCallTo)],
                    }),
                ...(searchParams.has("contactStatuses") && {
                    contactStatuses: oldSearch.contactStatuses.split(",")?.map((item) => Number(item)),
                }),
                ...(searchParams.has("callStatuses") && {
                    callStatuses: oldSearch.callStatuses.split(",")?.map((item) => Number(item)),
                }),
                ...(searchParams.has("campaign") && {
                    campaign: oldSearch.campaign.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("assignee") && {
                    assignee: oldSearch.assignee.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("productCode") && {
                    productCode: oldSearch.productCode.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("vendors") && {
                    vendors: oldSearch.vendors.split(",")?.map((item) => item),
                }),
                ...(searchParams.has("isSpam") && { isSpam: oldSearch?.isSpam === "true" }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const handleChangeFilter = (values: any) => {
        const oldSearch = Object.fromEntries(searchParams.entries())

        oldSearch?.totalCallTo && delete oldSearch.totalCallTo
        oldSearch?.totalCallFrom && delete oldSearch.totalCallFrom

        let params: any = {
            ...oldSearch,
            totalCallTo: values.call === "equals" ? values.totalCallFrom : undefined,
            ...values,
            createdAtFrom: values?.createdAt ? moment(values?.createdAt[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
            createdAtTo: values?.createdAt ? moment(values?.createdAt[1].format(FORMAT_DATE_END)).toISOString() : undefined,
            assignedAtFrom: values?.assignedAt ? moment(values?.assignedAt[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
            assignedAtTo: values.assignedAt ? moment(values?.assignedAt[1].format(FORMAT_DATE_END)).toISOString() : undefined,
            lastCallFrom: values?.lastCall ? moment(values?.lastCall[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
            lastCallTo: values?.lastCall ? moment(values.lastCall[1].format(FORMAT_DATE_END)).toISOString() : undefined,
            contactStatuses: values.contactStatuses ? values.contactStatuses.join(",") : undefined,
            callStatuses: values.callStatuses ? values.callStatuses.join(",") : undefined,
            campaign: values.campaign ? values.campaign.join(",") : undefined,
            countryCode: values.countryCode ? values.countryCode.join(",") : undefined,
            assignee: values.assignee ? values.assignee.join(",") : undefined,
            productCode: values.productCode ? values.productCode.join(",") : undefined,
            vendors: values.vendors ? values.vendors.join(",") : undefined,
        }
        delete params.createdAt
        delete params.assignedAt
        delete params.lastCall
        params = forEach(params, (v: any, k: string) => {
            if (typeof v === "string") v = v.trim()
            params[k] = v
        })
        params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
        setSearchParams(params)
    }

    const handleResetFilter = () => {
        formRef.resetFields()
        setSearchParams({})
    }
    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "campaign":
                    dispatch(
                        callGetCampaigns({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                case "user":
                    dispatch(
                        callGetListLeaderSuggest({
                            username: searchText.trim(),
                        })
                    )
                    break
                case "product":
                    dispatch(
                        callGetProductAutocomplete({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "campaign":
                    dispatch(
                        callGetCampaigns({
                            keyword: "",
                        })
                    )
                    break
                case "user":
                    dispatch(
                        callGetListLeaderSuggest({
                            username: "",
                        })
                    )
                    break
                case "product":
                    dispatch(
                        callGetProductAutocomplete({
                            keyword: "",
                        })
                    )
                    break
                default:
                    break
            }
        }
    }

    return (
        <Card className="space-layout">
            <Form
                layout="vertical"
                onFinish={handleChangeFilter}
                form={formRef}>
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("contact.fullname_phone_number")}
                            name="keyword">
                            <Input
                                placeholder={trans("contact.fullname_phone_number")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("contact.campaign")}
                            name="campaign">
                            <Select
                                allowClear
                                placeholder={trans("contact.select_campaign")}
                                showSearch
                                onBlur={() => onSearchAutocomplete("", "campaign")}
                                onSearch={(e) => {
                                    onSearchAutocomplete(e, "campaign")
                                }}
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                }}
                                mode="multiple">
                                {listCampaigns?.map((item: ICampaign) => {
                                    return (
                                        <Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("contact.created_at")}
                            name="createdAt">
                            <DatePicker.RangePicker
                                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                placeholder={[trans("contact.from"), trans("contact.to")]}
                            />
                        </Form.Item>
                    </Col>

                    {isShowMore ? (
                        <>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.assigned_at")}
                                    name="assignedAt">
                                    <DatePicker.RangePicker
                                        format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                        placeholder={[trans("contact.from"), trans("contact.to")]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.callTime")}
                                    name="lastCall">
                                    <DatePicker.RangePicker
                                        format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                        placeholder={[trans("contact.from"), trans("contact.to")]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.stage")}
                                    name="contactStatuses">
                                    <Select
                                        placeholder={trans("contact.stage")}
                                        mode="multiple"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}>
                                        {contactStatus &&
                                            contactStatus.map((item: IContactStatus) => {
                                                return (
                                                    <Option
                                                        key={item.code}
                                                        value={item.code}>
                                                        {item?.description}
                                                    </Option>
                                                )
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("sidebar.call_status")}
                                    name="callStatuses">
                                    <Select
                                        placeholder={trans("sidebar.call_status")}
                                        mode="multiple"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}>
                                        {callStatus &&
                                            callStatus.map((item: ICallStatus) => {
                                                return (
                                                    <Option
                                                        key={item.id}
                                                        value={item.code}>
                                                        {item?.name || ""}
                                                    </Option>
                                                )
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.country")}
                                    name="countryCode">
                                    <Select
                                        placeholder={trans("contact.country")}
                                        mode="multiple"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}>
                                        {countriesSuggest &&
                                            countriesSuggest?.map((item: ILocation) => {
                                                return (
                                                    <Option
                                                        key={item?.code}
                                                        value={item?.code}>
                                                        {item.name}
                                                    </Option>
                                                )
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {(SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_GROUPVIEW) || SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_VIEW_ALL)) && (
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={8}>
                                    <Form.Item
                                        label={trans("contact-detail.assigned_to")}
                                        name="assignee">
                                        <Select
                                            allowClear
                                            placeholder={trans("contact.select_assign_to")}
                                            showSearch
                                            onBlur={() => onSearchAutocomplete("", "user")}
                                            onSearch={(e) => {
                                                onSearchAutocomplete(e, "user")
                                            }}
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => {
                                                return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                            }}
                                            filterSort={(optionA: any, optionB: any) => {
                                                return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                            }}
                                            mode="multiple">
                                            {assignees?.map((item) => {
                                                return (
                                                    <Option
                                                        key={item?.id}
                                                        value={item?.username}>
                                                        {item?.username}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.vendor")}
                                    name="vendors">
                                    <Select
                                        allowClear
                                        placeholder={trans("payment_statement.vendor_filter")}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}
                                        mode="multiple">
                                        {vendorSuggest?.map((item: IVendor) => {
                                            return (
                                                <Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.vendor")}
                                    name="vendors">
                                    <Select
                                        allowClear
                                        placeholder={trans("payment_statement.vendor_filter")}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}
                                        mode="multiple">
                                        {vendorSuggest?.map((item: IVendor) => {
                                            return (
                                                <Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact-detail.product")}
                                    name="productCode">
                                    <Select
                                        allowClear
                                        placeholder={trans("contact-detail.product_pl")}
                                        showSearch
                                        onBlur={() => onSearchAutocomplete("", "product")}
                                        onSearch={(e) => {
                                            onSearchAutocomplete(e, "product")
                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                        }}
                                        mode="multiple">
                                        {productAutocomplete?.map((item) => {
                                            return (
                                                <Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}>
                                <Form.Item
                                    label={trans("contact.calls")}
                                    name="call">
                                    <Select
                                        allowClear
                                        placeholder={trans("contact.calls")}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                        }}
                                        onChange={() => {
                                            formRef.setFieldsValue({
                                                totalCallFrom: "",
                                                totalCallTo: "",
                                            })
                                        }}>
                                        {CALL_OPERATIONS.map((item) => (
                                            <Select.Option
                                                key={item.value}
                                                value={item.value}>
                                                {trans(item?.key)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(pre, next) => pre.call !== next.call}>
                                    {({ getFieldValue }) => {
                                        if (getFieldValue("call") === "bt") {
                                            return (
                                                <Row>
                                                    <Col span={11}>
                                                        <Form.Item
                                                            className="w-full p-0 m-0"
                                                            name="totalCallFrom">
                                                            <InputNumber
                                                                min={0}
                                                                parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        span={2}
                                                        className="flex items-start justify-center">
                                                        ~
                                                    </Col>
                                                    <Col span={11}>
                                                        <Form.Item
                                                            className="w-full p-0 m-0"
                                                            name="totalCallTo">
                                                            <InputNumber
                                                                min={0}
                                                                parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            )
                                        } else if (getFieldValue("call")) {
                                            return (
                                                <Form.Item
                                                    label={" "}
                                                    name={getFieldValue("call") === "lte" ? "totalCallTo" : "totalCallFrom"}>
                                                    <InputNumber
                                                        min={0}
                                                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                    />
                                                </Form.Item>
                                            )
                                        }
                                    }}
                                </Form.Item>
                            </Col>
                            {(SecurityService.can(CONTACT_SCOPE.CONTACT_LIST_GROUP_VIEW) || SecurityService.can(CONTACT_SCOPE.CONTACT_LIST_VIEW_ALL)) && (
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={8}>
                                    <Form.Item
                                        label={trans("contact.remark")}
                                        name="isSpam">
                                        <Select
                                            allowClear
                                            placeholder={trans("contact.remark")}>
                                            <Select.Option
                                                key={1}
                                                value={true}>
                                                {trans("contact.spam")}
                                            </Select.Option>
                                            <Select.Option
                                                key={2}
                                                value={false}>
                                                {trans("contact.blanks")}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                        </>
                    ) : (
                        ""
                    )}

                    <Col
                        span={24}
                        xs={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="mr-3">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            onClick={handleResetFilter}>
                            {trans("contact.clear")}
                        </Button>
                        <Button
                            type="link"
                            onClick={() => setIsShowMore((prev) => !prev)}>
                            {isShowMore ? trans("contact.filter_less") : trans("contact.filter_more")}
                            <i className={`ml-2 fa-solid ${isShowMore ? "fa-chevron-up" : "fa-chevron-down"}`} />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
