import { PAGINATION } from "@config/constant"
import { trans } from "@resources/localization"
import { Row, Table, Pagination, Card, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import { formatDateFull, shortenText } from "@util/Common"
import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { COMBO_SCOPE } from "@config/permission"
import { isEmpty, map } from "lodash"

export const List: React.FC = () => {
    const total = useAppSelector((state) => state.skuCombo.pagination.total)
    const currentPage = useAppSelector((state) => state.skuCombo.pagination.current)
    const totalPage = useAppSelector((state) => state.skuCombo.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.skuCombo.pagination.size)
    const listSkuCombo = useAppSelector((state) => state.skuCombo.listSkuCombo)
    const loading = useAppSelector((state) => state.skuCombo.loadingListCombo)

    const [searchParams, setSearchParams] = useSearchParams()

    const columns: ColumnsType<any> = [
        {
            title: trans("skuCombo.code"),
            className: "bold-400",
            dataIndex: "code",
            render: (code: string) =>
                SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_VIEW || SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_VIEW_ALL)) ? (
                    <Link to={`/skuCombo/${code}`}>{code}</Link>
                ) : (
                    code
                ),
            width: "15%",
        },
        {
            title: trans("skuCombo.name"),
            dataIndex: "name",
            className: "bold-400",
            width: "15%",
        },
        {
            title: trans("product.categories"),
            className: "bold-400",
            width: "15%",
            render: (record: any) => <p className="mb-0">{record?.category?.name || ""}</p>,
        },
        {
            title: trans("table.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (value) => (value ? formatDateFull(value) : "---"),
            width: "15%",
        },
        {
            title: trans("table.created_by"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: "15%",
        },
        {
            title: trans("telesales.group_name"),
            className: "bold-400",
            dataIndex: "telesalesGroups",
            render: (groups) =>
                !isEmpty(groups) ? <Tooltip title={map(groups, "name")?.join(",")}>{shortenText(map(groups, "name")?.join(",") || "", 16)}</Tooltip> : "",
            width: "25%",
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize: pageSize,
            page: !searchParams.has("size") || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }

        params.tab = "skuCombo"
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
            <Table
                scroll={{
                    x: true,
                }}
                columns={columns}
                dataSource={listSkuCombo}
                rowKey={(record) => record.id}
                pagination={false}
                loading={loading}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
