import { Modal, Typography } from "antd"
import React, { useState } from "react"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { FormImport } from "./FormImport"
import { TableError } from "./TableError"
import { changeVisibleOrderImport } from "@reducer/version2/order-detail.reducer"

export const ImportOrderFinancialStatus: React.FC = () => {
    const [isUpload, setIsUpload] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const visibleImport = useAppSelector((state) => state.orderDetailVersion2.isShowImport)
    const [dataResponse, setDataResponse] = useState({})

    return (
        <Modal
            destroyOnClose
            title={
                !isUpload ? (
                    trans("order.import_financial")
                ) : (
                    <Typography.Text type="danger">{trans("order_list.import_tracking_number_fail_list_title")}</Typography.Text>
                )
            }
            open={visibleImport}
            width={770}
            footer={null}
            onCancel={() => {
                setIsUpload(false)
                dispatch(changeVisibleOrderImport(false))
            }}>
            {!isUpload ? (
                <FormImport
                    setIsUpload={setIsUpload}
                    setDataResponse={setDataResponse}
                />
            ) : (
                <TableError dataResponse={dataResponse} />
            )}
        </Modal>
    )
}
