import apiClient from "@util/ApiClient"

import { IGroupTelesalesProduct, IProductDetailTelesaleResponse, IProductGroup, IProductProperties, IProductQuery, IProductSuggest, IProductTelesales, IProductVendors } from "@domain/version2/Product"
import { IGroupTelesales, IGroupTelesalesInProductDetail } from "@domain/version2/Telesales"
import { AxiosResponse } from "axios"

export class ProductApi {
    static getListProduct(params: IProductQuery): Promise<{
        data: IProductTelesales[]
        headers: { [key: string]: any }
    }> {
        return apiClient.get(`products`, { params })
    }
    static getAllProductGroup(): Promise<{ data: IProductGroup[] }> {
        return apiClient.get(`products/product-groups`)
    }
    static addGroupTelesales(code: string, body: { groupCodes: string[] }): Promise<any> {
        return apiClient.patch(`products/${code}/group-telesales`, body)
    }
    static getGroupsTelesales(
        code: string | undefined,
        params: { page?: number; pageSize?: number }
    ): Promise<{ data: IGroupTelesalesInProductDetail[]; headers: { [key: string]: any } }> {
        return apiClient.get(`products/${code}/group-telesales`, { params })
    }
    static removeGroupTelesales(code: string | undefined, body: { groupCodes: string[] }): Promise<any> {
        return apiClient.delete(`products/${code}/group-telesales`, {
            data: body,
        })
    }
    static getProductDetails(code: string | undefined): Promise<IProductDetailTelesaleResponse> {
        return apiClient.get(`products/${code}`)
    }

    static getPropertiesOfProduct(
        code: string | undefined,
        params: { page?: number; pageSize?: number }
    ): Promise<{ data: { data: IProductProperties[]; page: number; pageCount: number; size: number; total: number } }> {
        return apiClient.get(`products/${code}/properties`, { params })
    }

    static getVendorsOfProduct(
        code: string | undefined,
        params: { page?: number; pageSize?: number }
    ): Promise<{ data: { data: IProductVendors[]; page: number; pageCount: number; size: number; total: number } }> {
        return apiClient.get(`products/${code}/vendors`, { params })
    }
    static getTelesalesGroupsSuggest(code: string, params: { keyword: string }): Promise<{ data: IGroupTelesales[] }> {
        return apiClient.get(`products/${code}/group-telesales-not-in`, {
            params,
        })
    }
    static getTelesalesGroupInProduct(code: string): Promise<AxiosResponse<IGroupTelesalesProduct[]>> {
        return apiClient.get(`products/${code}/group-telesales/autocomplete`)
    }
    static getProductSuggest(params: {keyword: string}): Promise<AxiosResponse<IProductSuggest[]>> {
        return apiClient.get(`products/autocomplete`, {params})
    }

}
