import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, Input, InputNumber, message, Row, Space, Switch, Typography } from "antd"
import { isEmpty, isEqual, last } from "lodash"
import React, { useEffect, useState } from "react"
import SecurityService from "@util/SecurityService"
import { SETTING_SCOPE } from "@config/permission"
import { ISlaSetting, ISlaSettingRequest } from "@domain/version2/SlaSetting"
import { callCreateSlaSetting, callGetSlaSetting, callUpdateSlaSetting, changeVisibleSlaHistory } from "@reducer/version2/slaSetting.reducer"
import { formatDateFull } from "@util/Common"
import { HistorySlaSetting } from "./HistorySlaSetting"

export const SlaSetting: React.FC = () => {
    const slaSettings = useAppSelector((state) => state.settingSla.slaSettings)
    const loadingUpdate = useAppSelector((state) => state.settingSla.loadingUpdate)
    const loadingSla = useAppSelector((state) => state.settingSla.loadingSla)
    const visibleSla = useAppSelector((state) => state.settingSla.visibleSla)
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const [oldData, setOldData] = useState<any>([])
    useEffect(() => {
        dispatch(callGetSlaSetting())
    }, [dispatch])
    useEffect(() => {
        if (!isEmpty(slaSettings)) {
            const firstOldData = slaSettings?.map((item: ISlaSetting) => ({
                name: item?.name || "",
                maximumOfStock: item?.maximumOfStock || "",
                quantityContact: item?.quantityContact || "",
                isActive: item?.isActive || "",
                code: item?.code || "",
                createdAt: item?.createdAt || "",
                createdBy: item?.createdBy || "",
            }))
            formRef.setFieldsValue({
                slaSetting: firstOldData,
            })
            setOldData(firstOldData)
        } else {
            setOldData([])
            formRef.setFieldsValue({
                slaSetting: [],
            })
        }
    }, [slaSettings])
    const onUpdateSlaSetting = (values: any) => {
        const updateRequest = !isEmpty(values?.slaSetting) ? values?.slaSetting?.filter((iFil: ISlaSettingRequest) => iFil?.code) : []
        const createRequest = !isEmpty(values?.slaSetting) ? values?.slaSetting?.filter((iFil: ISlaSettingRequest) => !iFil?.code) : []
        if (!isEmpty(createRequest)) {
            const createDate = createRequest?.map((item: ISlaSettingRequest) => ({
                name: item?.name,
                maximumOfStock: item?.maximumOfStock,
                quantityContact: item?.quantityContact,
                isActive: item?.isActive,
            }))
            dispatch(callCreateSlaSetting(createDate)).then((result: any) => {
                if (isEmpty(updateRequest)) {
                    if (result?.payload?.status === 201) {
                        message.success(trans("message.success"))
                        dispatch(callGetSlaSetting())
                    } else {
                        message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                    }
                }
            })
        }
        if (!isEmpty(updateRequest)) {
            const updateDate = updateRequest?.map((item: ISlaSettingRequest) => ({
                name: item?.name,
                maximumOfStock: item?.maximumOfStock,
                quantityContact: item?.quantityContact,
                isActive: item?.isActive,
                code: item?.code,
            }))
            dispatch(callUpdateSlaSetting([...updateDate])).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(callGetSlaSetting())
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    return (
        <>
            <Card className="space-layout">
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        md={8}>
                        <Typography.Text className="font-medium">{trans("setting.last_update")}:</Typography.Text>
                        <Typography.Text className="ml-2">
                            {last(slaSettings)?.updatedAt ? formatDateFull(last(slaSettings)?.updatedAt || "") : ""}
                        </Typography.Text>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={8}>
                        <Typography.Text className="font-medium">{trans("setting.update_by")}:</Typography.Text>
                        <Typography.Text className="ml-2">{last(slaSettings)?.updatedBy}</Typography.Text>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={8}>
                        <p
                            className="text-blue-400 m-0 cursor-pointer font-medium"
                            onClick={() => dispatch(changeVisibleSlaHistory(true))}>
                            {trans("setting.update_history")}
                        </p>
                    </Col>
                </Row>
            </Card>
            <Card
                className="space-layout mt-4"
                loading={loadingSla}>
                <div className="grid grid-cols-8 gap-x-2 px-4">
                    <div className="col-span-1 font-medium">{trans("setting.rule_code")}</div>
                    <div className="col-span-1 font-medium">{trans("setting.rule_name")}</div>
                    <div className="col-span-1 font-medium">{trans("setting.max_of_stock")}</div>
                    <div className="col-span-1 font-medium">{trans("setting.contact_quantity")}</div>
                    <div className="col-span-1 font-medium">{trans("contact.created_at")}</div>
                    <div className="col-span-1 font-medium">{trans("order_detail.created_by")}</div>
                    <div className="col-span-1 font-medium">{trans("campaign.status")}</div>
                    <div className="col-span-1 font-medium"></div>
                </div>
                <Form
                    form={formRef}
                    onFinish={onUpdateSlaSetting}>
                    <Form.List name="slaSetting">
                        {(fields, { remove }) => (
                            <>
                                <div className="mt-4">
                                    {fields.map(({ name, ...restField }) => (
                                        <div
                                            key={name}
                                            className="grid grid-cols-8 gap-x-2 px-4 overflow-x-auto">
                                            <div className="col-span-1">
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.slaSetting !== next.slaSetting}>
                                                    {({ getFieldValue }) => <Typography.Text>{getFieldValue(["slaSetting", name, "code"])}</Typography.Text>}
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "name"]}
                                                    rules={[{ required: true, message: trans("message.required") }]}>
                                                    <Input
                                                        placeholder={trans("setting.rule_name")}
                                                        disabled={!SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE)}
                                                        onBlur={(e) => {
                                                            const slaSetting = formRef.getFieldValue("slaSetting")
                                                            slaSetting[name] = {
                                                                ...slaSetting[name],
                                                                name: e.target.value.trim(),
                                                            }
                                                            formRef.setFieldsValue({
                                                                slaSetting,
                                                            })
                                                        }}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "maximumOfStock"]}
                                                    rules={[{ required: true, message: trans("message.required") }]}>
                                                    <InputNumber
                                                        placeholder={trans("setting.max_of_stock")}
                                                        disabled={!SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE)}
                                                        min={1}
                                                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "quantityContact"]}
                                                    rules={[{ required: true, message: trans("message.required") }]}>
                                                    <InputNumber
                                                        placeholder={trans("setting.contact_quantity")}
                                                        disabled={!SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE)}
                                                        min={1}
                                                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1">
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.slaSetting !== next.slaSetting}>
                                                    {({ getFieldValue }) => (
                                                        <Typography.Text>
                                                            {getFieldValue(["slaSetting", name, "createdAt"])
                                                                ? formatDateFull(getFieldValue(["slaSetting", name, "createdAt"]) || "")
                                                                : ""}
                                                        </Typography.Text>
                                                    )}
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1">
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.slaSetting !== next.slaSetting}>
                                                    {({ getFieldValue }) => (
                                                        <Typography.Text>{getFieldValue(["slaSetting", name, "createdBy"])}</Typography.Text>
                                                    )}
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1 flex">
                                                {formRef.getFieldValue(["slaSetting", name, "code"]) !== "SLA000" && (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "isActive"]}
                                                        valuePropName="checked">
                                                        <Switch disabled={!SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE)} />
                                                    </Form.Item>
                                                )}
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.slaSetting !== next.slaSetting}>
                                                    {({ getFieldValue }) => (
                                                        <Typography.Text className="mt-1.5 ml-2">
                                                            {getFieldValue(["slaSetting", name, "isActive"])
                                                                ? trans("user_list.active")
                                                                : trans("user_list.inactive")}
                                                        </Typography.Text>
                                                    )}
                                                </Form.Item>
                                            </div>
                                            <div className="col-span-1">
                                                <Space>
                                                    {SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE) && (
                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(pre, next) => pre.slaSetting !== next.slaSetting}>
                                                            {({ getFieldValue }) =>
                                                                !getFieldValue(["slaSetting", name, "code"]) && (
                                                                    <Button
                                                                        type="link"
                                                                        icon={<i className="fa-light fa-trash"></i>}
                                                                        onClick={() => {
                                                                            remove(name)
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        </Form.Item>
                                                    )}

                                                    {fields.length - 1 === name && SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE) && (
                                                        <Button
                                                            className="w-full mx-2 mb-2"
                                                            type="default"
                                                            onClick={() => {
                                                                const slaSettings = formRef.getFieldValue("slaSetting")?.slice()
                                                                slaSettings.push({
                                                                    name: "",
                                                                    maximumOfStock: "",
                                                                    quantityContact: "",
                                                                    isActive: true,
                                                                    code: "",
                                                                    createdAt: "",
                                                                    createdBy: "",
                                                                })
                                                                formRef.setFieldsValue({
                                                                    slaSetting: slaSettings,
                                                                })
                                                            }}>
                                                            {trans("contact-detail.add")}
                                                        </Button>
                                                    )}
                                                </Space>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {isEmpty(fields) && SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE) && (
                                    <Row justify="end">
                                        <Button
                                            className="mx-2 mb-2 mt-4"
                                            type="default"
                                            onClick={() => {
                                                const slaSettings = formRef.getFieldValue("slaSetting")?.slice()
                                                slaSettings.push({
                                                    name: "",
                                                    maximumOfStock: "",
                                                    quantityContact: "",
                                                    isActive: true,
                                                    code: "",
                                                    createdAt: "",
                                                    createdBy: "",
                                                })
                                                formRef.setFieldsValue({
                                                    slaSetting: slaSettings,
                                                })
                                            }}>
                                            {trans("contact-detail.add")}
                                        </Button>
                                    </Row>
                                )}
                            </>
                        )}
                    </Form.List>
                    <Row justify="end">
                        <Space>
                            <Button
                                type="default"
                                loading={loadingUpdate}
                                onClick={() => {
                                    if (!isEmpty(slaSettings)) {
                                        formRef.setFieldsValue({
                                            slaSetting: slaSettings?.map((item: ISlaSetting) => ({
                                                ...item,
                                            })),
                                        })
                                    } else {
                                        formRef.resetFields()
                                    }
                                }}
                                disabled={!SecurityService.can(SETTING_SCOPE.SETTING_SLA_CREATE) && !SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE)}>
                                {trans("button.cancel")}
                            </Button>

                            <Form.Item
                                noStyle
                                shouldUpdate={(pre, next) => pre.slaSetting !== next.slaSetting}>
                                {({ getFieldValue }) => (
                                    <Button
                                        type="primary"
                                        loading={loadingUpdate}
                                        disabled={
                                            (!SecurityService.can(SETTING_SCOPE.SETTING_SLA_CREATE) &&
                                                !SecurityService.can(SETTING_SCOPE.SETTING_SLA_UPDATE)) ||
                                            isEqual(oldData, getFieldValue("slaSetting"))
                                        }
                                        htmlType="submit">
                                        {trans("button.save")}
                                    </Button>
                                )}
                            </Form.Item>
                        </Space>
                    </Row>
                </Form>
            </Card>
            {visibleSla && <HistorySlaSetting />}
        </>
    )
}
