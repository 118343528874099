import { IDeliveryCase, IDeliveryCaseAssignee, IDeliveryCaseForm, IDeliveryCaseQuery } from "@domain/version2/DeliveryCase"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios";

export default class DeliveryCaseApi {
    static getDeliveryCases(params: IDeliveryCaseQuery): Promise<AxiosResponse<IDeliveryCase[]>> {
        return apiClient.get("delivery-cases", {
            params,
        })
    }

    static importDeliveryCases(file: File) {
        const formData: any = new FormData()
        formData.append("file", file)
        return apiClient.post(`delivery-cases/upload`, formData)
    }
    static getDeliveryCaseAssignee(): Promise<AxiosResponse<IDeliveryCaseAssignee[]>> {
        return apiClient.get("delivery-cases/assignees")
    }
    static updateAssignee(code: string, body: { assignee: string }) {
        return apiClient.patch(`delivery-cases/${code}/assignee`, body)
    }
    static updateDeliveryCase(code: string, body: IDeliveryCaseForm) {
        return apiClient.patch(`delivery-cases/${code}`, body)
    }
}
