import React, { useEffect } from "react"

import { useDispatch } from "react-redux"

import DefaultLayout from "@component/Layout/Default"

import { trans } from "@resources/localization"

import { changePageTitleAction } from "@reducer/common.reducer"

export const Dashboard = (props: any) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.dashboard")))
    }, [dispatch])

    return (
        <DefaultLayout
            {...props}
            loading={false}
            title={trans("sidebar.dashboard")}>
            <div className="white-box">
                <div>Bảng điều khiển</div>

                <h1 className="text-3xl font-bold underline">Hello world!</h1>
            </div>
        </DefaultLayout>
    )
}
