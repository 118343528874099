import React, { useEffect, useState } from "react"

import { useParams } from "react-router-dom"

import { message, Tabs } from "antd"

import { useAppDispatch } from "@hook/useAppDispatch"

import { changePageTitleAction } from "@reducer/common.reducer"
import {
    getOrderDetail,
    fetchUpdateOrder,
    changeDataOrderUpdate,
    changeEditNoteOrder,
    changeEditShippingAddress,
    callGetListDeliveryCasesOfOrder,
} from "@reducer/version2/order-detail.reducer"

import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"

import { trans } from "@resources/localization"

import { Summary } from "./Summary"
import { OrderDetailHeader } from "./Header"
import { useAppSelector } from "@hook/useAppSelector"
import { filter, isEmpty } from "lodash"
import { getAreaSuggest, getCitySuggest, getCourierSuggest, getDistrictSuggest, getProvinceSuggest } from "@reducer/version2/countries.reducer"
import AddReasonCancel from "./AddReasonCancel"
import { callGetDeliveryCaseReason, callGetDeliveryCaseStatus, callGetOrderStatus } from "@reducer/version2/categories.reducer"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import { DeliveryCase } from "./DeliveryCase"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { callGetAssignee } from "@reducer/version2/delivery-case.reducer"
import { callGetFinancialStatus } from "@reducer/version2/financialStatus.reducer"

export const OrderDetail = () => {
    const { id } = useParams()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loading = useAppSelector((state) => state.orderDetailVersion2.loadingDetail)
    const dataOrderUpdate = useAppSelector((state) => state?.orderDetailVersion2?.dataOrderUpdate)
    const dispatch = useAppDispatch()
    const [statusShow, setStatusShow] = useState<number>(0)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("order.product_detail")))
        dispatch(callGetOrderStatus())
        dispatch(changeEditShippingAddress(false))
        detailOrder?.vendorCode && dispatch(getCourierSuggest({ vendorCode: detailOrder?.vendorCode }))
        dispatch(callGetDeliveryCaseReason())
        dispatch(callGetDeliveryCaseStatus())
        dispatch(callGetFinancialStatus())
        if (id) {
            dispatch(callGetListDeliveryCasesOfOrder({ orderCode: id || "" }))
            dispatch(getOrderDetail(id || "")).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
        }
        dispatch(callGetAssignee())
    }, [dispatch, id])
    useEffect(() => {
        if (!isEmpty(detailOrder)) {
            dispatch(
                getCourierSuggest({
                    vendorCode: detailOrder?.vendorCode || "",
                })
            )
            dispatch(getProvinceSuggest(detailOrder?.country?.code || ""))
            if (detailOrder?.province?.code) {
                dispatch(getCitySuggest(detailOrder?.province?.code || ""))
            }
            if (detailOrder?.city?.code) {
                dispatch(getDistrictSuggest(detailOrder?.city?.code || ""))
            }
            if (detailOrder?.district?.code) {
                dispatch(getAreaSuggest(detailOrder?.district?.code))
            }
        }
    }, [detailOrder])
    useEffect(() => {
        if (!isEmpty(detailOrder)) {
            dispatch(
                changeDataOrderUpdate({
                    customerName: detailOrder?.customerName,
                    customerPhone: detailOrder?.customerPhone,
                    courierService: detailOrder?.courierServiceCode,
                    country: detailOrder?.country?.name,
                    province: detailOrder?.province?.code,
                    city: detailOrder?.city?.code,
                    district: detailOrder?.district?.code,
                    ward: detailOrder?.ward,
                    address: detailOrder?.address,
                    postalCode: detailOrder?.postalCode,
                    totalPayment: detailOrder?.totalPayment,
                    deliveryFee: detailOrder?.deliveryFee,
                    note: detailOrder?.note,
                })
            )
        }
    }, [dispatch, detailOrder])

    const onUpdateOrder = (key: string, value: any) => {
        dispatch(
            fetchUpdateOrder({
                code: detailOrder?.code || "",
                body: {
                    [key]: value,
                    ...(key === "totalPayment" && {
                        totalPayment: dataOrderUpdate?.totalPayment || 0,
                    }),
                    ...(key === "deliveryFee" && {
                        deliveryFee: dataOrderUpdate?.deliveryFee || 0,
                    }),
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(getOrderDetail(id || ""))
                dispatch(changeEditNoteOrder(false))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    const items = [
        { label: trans("order_detail.summary"), key: "summary", children: <Summary onUpdateOrder={onUpdateOrder} />, disabled: false },
        {
            label: trans("delivery_case.title"),
            key: "deliveryCase",
            children: <DeliveryCase />,
            disabled: !SecurityService.can(ORDER_SCOPE.ORDER_DELIVERY_CASE_VIEW),
        },
    ]

    return (
        <DefaultLayout loading={loading}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "sidebar.dashboard",
                                link: "/",
                            },
                            {
                                name: "order.page_title",
                                link: "/orders",
                            },
                            {
                                name: detailOrder?.code || "",
                                link: "",
                            },
                        ]}
                    />
                    <OrderDetailHeader />
                    <AddReasonCancel />
                    <div className="mx-3">
                        <Tabs
                            defaultActiveKey="summary"
                            items={filter(items, { disabled: false })}
                            className="nav-space"></Tabs>
                    </div>
                </>
            )}
        </DefaultLayout>
    )
}
