import { PAGINATION } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Row, Table, Pagination, Tooltip, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import { formatDateFull, shortenText } from "@util/Common"
import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import { isEmpty, map } from "lodash"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE } from "@config/permission"

export const List: React.FC = () => {
    const total = useAppSelector((state) => state.product.pagination.total)
    const currentPage = useAppSelector((state) => state.product.pagination.current)
    const totalPage = useAppSelector((state) => state.product.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.product.pagination.size)
    const listProduct = useAppSelector((state) => state.product.listProduct)
    const [searchParams, setSearchParams] = useSearchParams()

    const columns: ColumnsType<any> = [
        {
            title: trans("product_list.code"),
            className: "bold-400",
            dataIndex: "code",
            render: (code: string) =>
                SecurityService.can(PRODUCT_SCOPE.PRODUCT_VIEW_ALL) || SecurityService.can(PRODUCT_SCOPE.PRODUCT_VIEW) ? (
                    <Link to={`/product/${code}`}>{code}</Link>
                ) : (
                    code
                ),
            width: 120,
        },
        {
            title: trans("product_list.name"),
            className: "bold-400",
            dataIndex: "name",
            width: 150,
            render: (name: string) => (name.length > 100 ? <Tooltip title={name}>{shortenText(name, 30)}</Tooltip> : name),
        },
        {
            title: trans("products.group"),
            className: "bold-400",
            dataIndex: "productGroup",
            render: (value) => (value ? value?.name : ""),
            width: 150,
        },
        {
            title: trans("table.created_by"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: 100,
        },
        {
            title: trans("table.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (value) => (value ? formatDateFull(value) : "---"),
            width: 120,
        },
        {
            title: trans("telesales.group_name"),
            className: "bold-400",
            dataIndex: "telesaleGroups",
            render: (groups) =>
                !isEmpty(groups) ? <Tooltip title={map(groups, "name")?.join(",")}>{shortenText(map(groups, "name")?.join(",") || "", 16)}</Tooltip> : "",
            width: 150,
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            size: pageSize,
            page: !searchParams.has("size") || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }

        params.tab = "products"
        setSearchParams(params)
    }

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
            <Table
                scroll={{
                    x: true,
                }}
                columns={columns}
                dataSource={listProduct}
                rowKey={(record) => record.id}
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}