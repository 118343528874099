import { trans } from "@resources/localization"
import { AutoComplete, Button, Form, message, Spin } from "antd"
import FormItem from "antd/lib/form/FormItem"
import React, { useEffect } from "react"
import { convertNonAccentVietnamese } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import {
    callAddTelesalesToGroup,
    callDetailTelesalesGroup,
    callGetTelesalesInGroup,
    callListTelesalesNotInGroup,
} from "@reducer/version2/telesales-group-detail.reducer"
import { useParams, useSearchParams } from "react-router-dom"
import { debounce, find, isEmpty } from "lodash"
import { useAppSelector } from "@hook/useAppSelector"
import { PAGINATION } from "@config/constant"

const AddTelesale = (prop: any) => {
    const dispatch = useAppDispatch()
    const [formRef] = Form.useForm()
    const groupCode = useParams().id as string
    const [searchParams] = useSearchParams()
    const listTelesalesNotInGroup = useAppSelector((state) => state.telesalesGroupDetail.listTelesalesNotInGroup)
    const loadingAddTelesales = useAppSelector((state) => state.telesalesGroupDetail.loadingAdd)
    const options = listTelesalesNotInGroup?.map((item) => ({
        value: item?.username,
    }))
    useEffect(() => {
        dispatch(
            callListTelesalesNotInGroup({
                code: groupCode,
                params: {
                    keyword: "",
                },
            })
        )
    }, [dispatch])

    const onSearchTelesales = (searchText: string) => {
        if (searchText.length >= 3) {
            const userName = convertNonAccentVietnamese(searchText)
            dispatch(
                callListTelesalesNotInGroup({
                    code: groupCode,
                    params: {
                        keyword: userName,
                    },
                })
            )
        } else {
            dispatch(
                callListTelesalesNotInGroup({
                    code: groupCode,
                    params: {
                        keyword: "",
                    },
                })
            )
        }
    }

    const onAddTelesale = (values: { username: string }) => {
        dispatch(
            callAddTelesalesToGroup({
                code: groupCode,
                body: {
                    telesales: [values.username],
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 202) {
                message.success(trans("message.success"))
                dispatch(callDetailTelesalesGroup({ groupCode }))
                dispatch(
                    callGetTelesalesInGroup({
                        code: groupCode,
                        params: {
                            ...Object.fromEntries(searchParams.entries()),
                            page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                            pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                            sort: "createdAt:desc",
                        },
                    })
                )
                dispatch(
                    callListTelesalesNotInGroup({
                        code: groupCode,
                        params: {
                            keyword: "",
                        },
                    })
                )
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })

        formRef.resetFields()
        prop.close()
    }

    return (
        <div className="w-72">
            <Form
                form={formRef}
                onFinish={onAddTelesale}
                layout="vertical">
                <FormItem
                    name="username"
                    label={trans("telesales.name")}
                    rules={[{ required: true, message: trans("message.required") }]}>
                    <AutoComplete
                        notFoundContent={<Spin />}
                        className="w-full"
                        onSearch={debounce(onSearchTelesales, 1000)}
                        options={options}
                        placeholder={trans("telesales.name")}
                        onBlur={(e: any) => {
                            if (isEmpty(find(options, { value: e?.target?.value }))) {
                                formRef.setFieldsValue({
                                    username: "",
                                })
                            }
                        }}
                    />
                </FormItem>
                <div className="w-full flex justify-end">
                    <Button
                        className="mr-2"
                        type="ghost"
                        loading={loadingAddTelesales}
                        onClick={() => {
                            formRef.resetFields()
                            prop?.close()
                        }}>
                        {trans("button.cancel")}
                    </Button>
                    <Button
                        htmlType="submit"
                        loading={loadingAddTelesales}
                        type="primary">
                        {trans("button.add")}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default AddTelesale
