import { IContactAds, IPricePolicy } from "@domain/version2/ContactDetail"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetDetailSkuCombo } from "@reducer/version2/skuCombo-detail.reducer"
import { trans } from "@resources/localization"
import { Col, Form, Row, Select } from "antd"
import { find, isEmpty } from "lodash"
import React from "react"
type Props = {
    formRef: any
}
export const AdsCreate: React.FC<Props> = ({ formRef }) => {
    const dispatch = useAppDispatch()
    const contactAds = useAppSelector((state) => state.contactDetailVersion2.listContactAds)
    console.log("contactAds", contactAds)
    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name="ads"
                    label={trans("order_detail.ads")}
                    rules={[
                        {
                            required: true,
                            message: trans("message.required"),
                        },
                    ]}>
                    <Select
                        showSearch
                        placeholder={trans("contact-detail.validate_ad")}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(e) => {
                            const itemSelectAds = find(contactAds, { code: e })
                            const currentPricePolicy: IPricePolicy[] = find(contactAds, { code: e })?.pricePolicies || []
                            if (itemSelectAds?.campaign?.productCode && !isEmpty(currentPricePolicy)) {
                                formRef.setFieldsValue({
                                    pricePolicies: [
                                        {
                                            property: null,
                                            unitPrice: "",
                                            quantity: "",
                                            price: "",
                                        },
                                    ],
                                })
                            }
                            if (itemSelectAds?.campaign?.comboCode) {
                                dispatch(callGetDetailSkuCombo(itemSelectAds?.campaign?.comboCode))
                                formRef.setFieldsValue({
                                    combos: [
                                        {
                                            sku: itemSelectAds?.campaign?.comboCode,
                                            unitPrice: "",
                                            quantity: "",
                                            price: "",
                                        },
                                    ],
                                })
                            }
                        }}>
                        {contactAds?.map((item: IContactAds) => (
                            <Select.Option
                                key={item.id}
                                value={item.code}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )
}
