import apiClient from "@util/ApiClient"
import { IContactQuery, IContactResponse } from "@domain/version2/Contact"
import { IContactEventRequest } from "@domain/version2/ContactEvent"
import { IActivityHistory, IContactAds, IContactDetail, IContactUpdateRequest, IEvent } from "@domain/version2/ContactDetail"
import { IOrder } from "@domain/version2/Order"

export default class ContactApi {
    static getContacts(params: IContactQuery): Promise<IContactResponse> {
        return apiClient.get("contacts", { params })
    }
    static assignContacts(): Promise<any> {
        return apiClient.get("contacts/get-contacts/more")
    }

    static getContactDetail(contactId: string): Promise<{ data: IContactDetail }> {
        return apiClient.get(`contacts/${contactId}`)
    }

    static updateContactDetail(contactId: string, body: IContactUpdateRequest): Promise<any> {
        return apiClient.patch(`contacts/${contactId}`, body)
    }

    static getContactAds(contactId: string): Promise<{ data: IContactAds[] }> {
        return apiClient.get(`contacts/${contactId}/ads`)
    }

    static addContactEvent(id: string, body: IContactEventRequest): Promise<any> {
        return apiClient.post(`contacts/${id}/events`, body)
    }

    static getContactEvents(contactId: string): Promise<{ data: IEvent[] }> {
        return apiClient.get(`contacts/${contactId}/events`, {
            params: {
                sort: "createdAt:desc",
            },
        })
    }
    static getAdsOfContact(contactId: string): Promise<{ data: IContactAds[] }> {
        return apiClient.get(`contacts/${contactId}/ads`)
    }

    static getOrdersOfContactInPeriod(contactId: string): Promise<{ data: IOrder[] }> {
        return apiClient.get(`contacts/${contactId}/orders`)
    }

    static exportContacts(params: IContactQuery): Promise<any> {
        return apiClient.post("contacts/export", params)
    }

    static getActivityHistory(id: string): Promise<{ data: IActivityHistory[] }> {
        return apiClient.get(`contacts/${id}/activities`)
    }

    static resetAssignment(contactId: string) {
        return apiClient.patch(`contacts/${contactId}/cancel-assignment`, { contactId })
    }

    static assignContactToTelesales(code: string, body: { assignee: string }) {
        return apiClient.patch(`contacts/${code}/assignment`, body)
    }
}
