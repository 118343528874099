import React, { useEffect } from "react"
import { trans } from "@resources/localization"
import { Card, Pagination, Row, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { PAGINATION } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetPropertiesOfProduct } from "@reducer/version2/product-detail"

export const PropertiesList: React.FC = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const total = useAppSelector((state) => state.productDetail.pagination.total)
    const currentPage = useAppSelector((state) => state.productDetail.pagination.current)
    const totalPage = useAppSelector((state) => state.productDetail.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.productDetail.pagination.size)
    const listPropertiesOfProduct = useAppSelector((state) => state.productDetail.listPropertiesOfProduct)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            callGetPropertiesOfProduct({
                code: id,
                params: {
                    ...params,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "sku:asc",
                },
            })
        )
    }, [dispatch, searchParams])

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }

    const columns: ColumnsType<any> = [
        {
            title: trans("products.variant"),
            className: "bold-400",
            dataIndex: "variant",
            render: (variant) => Object.values(variant).toString().replace(",", "/"),
        },
        {
            title: trans("ads.sku"),
            className: "bold-400",
            dataIndex: "sku",
        },
    ]

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
            <Table
                dataSource={listPropertiesOfProduct}
                rowKey={"id"}
                columns={columns}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
