import React, { useMemo } from "react"
import { trans } from "@resources/localization"
import { Button, Card, Col, Row, Tag, Timeline } from "antd"
import { find, isEmpty } from "lodash"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeAddEventFormVisible } from "@reducer/version2/contact-detail.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { Link } from "react-router-dom"
import { IEvent } from "@domain/version2/ContactDetail"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"

export const Event = () => {
    const dispatch = useAppDispatch()
    const listEvents = useAppSelector((state) => state.contactDetailVersion2.listEvents)
    const loading = useAppSelector((state) => state.contactDetailVersion2.loadingEvents)
    const statusEvents = useAppSelector((state) => state.categoriesVersion2.contactEventStatus)
    const listAds = useAppSelector((state) => state.contactDetailVersion2.listContactAds)
    const newListEvents: any = useMemo(
        () =>
            !isEmpty(listEvents)
                ? listEvents?.map((item) => ({
                      ...item,
                      iStatus: find(statusEvents, { code: item?.status }),
                  }))
                : [],
        [listEvents, statusEvents]
    )
    const buttonAddEvent = SecurityService.can(CONTACT_SCOPE.CONTACT_ADD_EVENT) && (
        <Button
            type="default"
            onClick={() => {
                dispatch(changeAddEventFormVisible(true))
            }}
            icon={<i className={"fas fa-plus mr-2"} />}
            className="_btn_add_event">
            {trans("contact-detail.add_event")}
        </Button>
    )

    return (
        <Card
            title={trans("contact-detail.activity")}
            extra={buttonAddEvent}>
            <Timeline
                className="overflow-x-hidden overflow-y-auto max-h-80"
                pending={loading}>
                {newListEvents.map((item: IEvent, index: any) => (
                    <Row
                        gutter={20}
                        className="mt-2"
                        key={index}>
                        <Col span={5}>
                            <div className="text-black">{formatDateFull(item?.createdAt ? item?.createdAt : "")}</div>
                        </Col>
                        <Col span={19}>
                            <Timeline.Item>
                                <Tag color={item?.iStatus?.bgColor || ""}>{item?.iStatus?.name || ""}</Tag>
                                <div className="text-stone-800">{find(listAds, { code: item?.ad })?.name || ""}</div>
                                {item?.order && (
                                    <Link
                                        className="block"
                                        to={`/orders/${item?.order}`}>
                                        {item?.order}
                                    </Link>
                                )}
                                {item?.orderNote ||
                                    (!item?.reason && item?.note && <div className="italic text-gray-400">{item?.orderNote || item?.note}</div>)}
                                {item?.scheduleDate && (
                                    <div className="text-gray-400">
                                        {trans("contact-detail.appointment")}: {formatDateFull(item?.scheduleDate)}
                                    </div>
                                )}
                                <div className="italic text-gray-400">
                                    {item?.reason ? trans(`order_status_cancelled.${item?.reason}`) : ""}
                                    {item?.reason && item?.note && <span>: {item?.note}</span>}
                                </div>
                            </Timeline.Item>
                        </Col>
                    </Row>
                ))}
            </Timeline>
        </Card>
    )
}
