import React, { useEffect } from "react"
import { Col, InputNumber, Row, Spin } from "antd"
import lodash from "lodash"
import { convertNumberToCurrency, renderVariant } from "@util/Common"
import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeAdSkuAttributeAction, getAdSkusAction } from "@reducer/version2/contact-detail.reducer"
import { trans } from "@resources/localization"

interface Props {
    adCode: string
}

export const AdSkuList = (props: Props) => {
    const dispatch = useAppDispatch()
    const adSkus = useAppSelector((state) => state.contactDetailVersion2.adSkus)
    const adSkusLoading = useAppSelector((state) => state.contactDetailVersion2.adSkusLoading)

    useEffect(() => {
        if (props.adCode) {
            dispatch(
                getAdSkusAction({
                    adCode: props.adCode,
                })
            )
        }
    }, [props.adCode])

    const handleChangeSkuAttribute = (sku: string, attrKey: string, attrValue: any) => {
        dispatch(
            changeAdSkuAttributeAction({
                sku,
                attrKey,
                attrValue,
            })
        )
    }

    if (!adSkus.length) {
        return null
    }

    return (
        <Spin spinning={adSkusLoading}>
            <h2 className={"text-xl mt-2 bg-gray-200 px-2 py-2 rounded"}>{trans("contact-detail.table-cross-sku.heading")}</h2>
            <Row
                gutter={15}
                className={"mb-2"}>
                <Col span={6}>
                    <span className={"text-sm font-bold px-2"}>{trans("contact-detail.table-cross-sku.column.product")}</span>
                </Col>
                <Col span={6}>
                    <span className={"text-sm font-bold px-2"}>{trans("contact-detail.table-cross-sku.column.quantity")}</span>
                </Col>
                <Col span={6}>
                    <span className={"text-sm font-bold px-2"}>{trans("contact-detail.table-cross-sku.column.unit_price")}</span>
                </Col>
                <Col span={5}>
                    <span className={"text-sm font-bold px-2"}>{trans("contact-detail.table-cross-sku.column.total_amount")}</span>
                </Col>
            </Row>
            {adSkus.map((row, i) => (
                <Row
                    key={i}
                    gutter={15}
                    className={"border-b-[1px] border-gray-50 mb-4"}>
                    <Col span={6}>
                        <div className={"p-2 text-[12px]"}>
                            <div>Product: {lodash.get(row, "sku.product.name")}</div>
                            <div>
                                Variant: {lodash.get(row, "sku.sku")} ({renderVariant(lodash.get(row, "sku.variant"))})
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <span className={"w-full block rounded px-2 py-[5px] border-[1px] border-gray-200 bg-gray-100"}>
                            <InputNumber
                                value={lodash.get(row, "quantity")}
                                onChange={(value) => {
                                    handleChangeSkuAttribute(lodash.get(row, "sku.sku"), "quantity", value)
                                }}
                            />
                        </span>
                    </Col>
                    <Col span={7}>
                        <span className={"w-full block rounded px-2 py-[5px] border-[1px] border-gray-200 bg-gray-100"}>
                            {convertNumberToCurrency(lodash.get(row, "price")) || 0}
                        </span>
                    </Col>
                    <Col span={5}>
                        <span className={"w-full block rounded px-2 py-[5px] border-[1px] border-gray-200 bg-gray-100"}>
                            {convertNumberToCurrency((lodash.get(row, "quantity") || 0) * (lodash.get(row, "price") || 0)) || 0}
                        </span>
                    </Col>
                </Row>
            ))}
        </Spin>
    )
}
