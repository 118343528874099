import { trans } from "@resources/localization"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import React, { useEffect } from "react"
import DefaultLayout from "@component/Layout/Default"
import { Filter } from "./Filter"
import { List } from "./List"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { fetchOrders } from "@reducer/version2/order.reducer"
import { useSearchParams } from "react-router-dom"
import { PAGINATION } from "@config/constant"
import { callGetOrderStatus } from "@reducer/version2/categories.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetProductAutocomplete } from "@reducer/version2/product.reducer"
import { getCountriesSuggest, getCourierSuggestAll } from "@reducer/version2/countries.reducer"
import { callGetVendorSuggest } from "@reducer/version2/vendor.reducer"
import { callGetUserAutocomplete } from "@reducer/version2/user.reducer"
import { ImportOrderFinancialStatus } from "./ImportFinancial"
import { callGetFinancialStatus } from "@reducer/version2/financialStatus.reducer"

export const Order = () => {
    const dispatch: any = useAppDispatch()
    const [searchParams] = useSearchParams()
    const loading = useAppSelector((state) => state.deliveryCase.loadingList)
    const visibleImport = useAppSelector((state) => state.orderDetailVersion2.isShowImport)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("order.page_title")))
        dispatch(callGetOrderStatus())
        dispatch(getCountriesSuggest())
        dispatch(callGetVendorSuggest({}))
        dispatch(getCourierSuggestAll())
        dispatch(callGetUserAutocomplete(""))
        dispatch(callGetProductAutocomplete({ keyword: "" }))
        dispatch(callGetFinancialStatus())
    }, [dispatch])

    useEffect(() => {
        dispatch(
            fetchOrders({
                ...Object.fromEntries(searchParams.entries()),
                pageSize: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                sort: "createdAt:DESC",
            })
        )
    }, [dispatch, searchParams])

    return (
        <DefaultLayout loading={loading}>
            <div className="contact-page">
                <HeaderAntBreadCrumb
                    breadCrumbs={[
                        {
                            name: trans("sidebar.dashboard"),
                            link: "/",
                        },
                        {
                            name: trans("order.list"),
                            link: "/orders",
                        },
                    ]}
                />
                <Filter />
                <List />
                {visibleImport && <ImportOrderFinancialStatus />}
            </div>
        </DefaultLayout>
    )
}
