import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import ContactApi from "@api/version2/Contact"
import IContact, { IContactQuery } from "@domain/version2/Contact"
import CampaignApi from "@api/version2/Campaign"

interface State {
    contacts: Array<IContact>
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    loading: boolean
    loadingAssign: boolean
    listCampaigns: any
    loadingExport: boolean
}

const initState: State = {
    contacts: [],
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loading: false,
    loadingAssign: false,
    listCampaigns: [],
    loadingExport: false,
}

export const callGetContacts = createAsyncThunk("contacts", async (contactQuery: IContactQuery, thunkApi) => {
    try {
        return await ContactApi.getContacts(contactQuery)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetCampaigns = createAsyncThunk("campaign", async (params:{keyword: string}, thunkApi) => {
    try {
        return await CampaignApi.getListCampaign(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callAssignContacts = createAsyncThunk("CONTACT_ASSIGN", async (_, thunkApi) => {
    try {
        return await ContactApi.assignContacts()
    } catch (error: any) {
        return thunkApi.rejectWithValue(error)
    }
})

export const exportContacts = createAsyncThunk("CONTACT.EXPORT", async (payload: IContactQuery, thunkApi) => {
    try {
        return await ContactApi.exportContacts(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const contactReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetContacts.pending, (state) => {
            state.loading = true
            state.contacts = []
        })
        .addCase(callGetContacts.fulfilled, (state, action) => {
            state.contacts = action.payload.data ? action.payload.data : []
            state.pagination.currentPage = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loading = false
        })
        .addCase(callGetContacts.rejected, (state) => {
            state.loading = false
        })

    builder
        .addCase(callAssignContacts.pending, (state) => {
            state.loadingAssign = true
        })
        .addCase(callAssignContacts.fulfilled, (state) => {
            state.loadingAssign = false
        })
        .addCase(callAssignContacts.rejected, (state) => {
            state.loadingAssign = false
        })

    builder
        .addCase(callGetCampaigns.pending, (state) => {
            state.listCampaigns = []
        })
        .addCase(callGetCampaigns.fulfilled, (state, { payload }) => {
            state.listCampaigns = payload.data || []
        })
        .addCase(callGetCampaigns.rejected, (state) => {
            state.listCampaigns = []
        })
    builder
        .addCase(exportContacts.pending, (state) => {
            state.loadingExport = true
        })
        .addCase(exportContacts.fulfilled, (state) => {
            state.loadingExport = false
        })
        .addCase(exportContacts.rejected, (state) => {
            state.loadingExport = false
        })
})
