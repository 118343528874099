import { CONTACT_EVENT_REASON } from "@config/constant"
import { trans } from "@resources/localization"
import { Col, Form, Input, Row, Select } from "antd"
import React from "react"
export const ReasonAndOther: React.FC = () => {
    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    label={trans("order_detail.reason")}
                    name="reason"
                    rules={[
                        {
                            required: true,
                            message: trans("message.required")
                        },
                    ]}>
                    <Select
                        placeholder={trans("contact-detail.cancel_reason_pl")}
                        showSearch
                       >
                        {CONTACT_EVENT_REASON.map((x) => (
                            <Select.Option
                                key={x.key}
                                value={x.key}>
                                {trans(`order_status_cancelled.${x.key}`)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) => pre.reason !== next.reason}>
                    {({ getFieldValue }) =>
                        getFieldValue("reason") === "OTHER" && (
                            <Form.Item
                                label={trans("contact_detail.content")}
                                name='noteOther'
                                rules={[
                                    {
                                        required: true,
                                        message: trans("message.required")
                                    },
                                ]}>
                                <Input.TextArea
                                    rows={4}
                                    placeholder={trans("contact-detail.content_pl")}
                                />
                            </Form.Item>
                        )
                    }
                </Form.Item>
            </Col>
        </Row>
    )
}
