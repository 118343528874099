import React, { useEffect } from "react"
import { useAppDispatch } from "@hook/useAppDispatch"
import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { trans } from "@resources/localization"
import { changePageTitleAction } from "@reducer/common.reducer"
import { ContactTable } from "@screen/version2/Contact/Table"
import { ContactFilter } from "@screen/version2/Contact/Filter"
import { CONTACT_BREADCRUMB } from "@config/constant"
import { useSearchParams } from "react-router-dom"
import { callGetCampaigns, callGetContacts } from "@reducer/version2/contact.reducer"
import { callGetCallStatus, callGetContactEventStatus, callGetContactStatus } from "@reducer/version2/categories.reducer"
import { getCountriesSuggest } from "@reducer/version2/countries.reducer"
import { callGetProductAutocomplete } from "@reducer/version2/product.reducer"
import { callGetListLeaderSuggest } from "@reducer/version2/telesales-group.reducer"
import { callGetVendorSuggest } from "@reducer/version2/vendor.reducer"

export const Contact = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("contact.title")))
        dispatch(callGetContactStatus())
        dispatch(callGetCampaigns({ keyword: "" }))
        dispatch(callGetCallStatus())
        dispatch(callGetContactEventStatus())
        dispatch(getCountriesSuggest())
        dispatch(callGetProductAutocomplete({ keyword: "" }))
        dispatch(callGetVendorSuggest({}))
        dispatch(callGetListLeaderSuggest({ username: "" }))
    }, [dispatch])

    useEffect(() => {
        const params: any = Object.fromEntries(searchParams.entries())
        dispatch(
            callGetContacts({
                ...params,
                pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : 100,
                sort: "assignedAt:DESC",
            })
        )
    }, [dispatch, searchParams])

    return (
        <>
            <DefaultLayout loading={false}>
                <HeaderAntBreadCrumb breadCrumbs={CONTACT_BREADCRUMB} />
                <ContactFilter />
                <ContactTable />
            </DefaultLayout>
        </>
    )
}
