import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

import { PAGINATION } from "@config/constant"
import { ProductApi } from "@api/version2/Product"
import { IProductGroup, IProductQuery, IProductSuggest, IProductTelesales } from "@domain/version2/Product"
import { IGroupTelesales } from "@domain/version2/Telesales"
import { TelesalesGroupApi } from "@api/version2/TelesalesGroupApi"

interface State {
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
    listProduct: IProductTelesales[]
    loadingList: boolean
    listAllProductGroup: IProductGroup[]
    listAllTelesalesGroup: IGroupTelesales[]
    productSuggest: IProductSuggest[]
}

const initState: State = {
    pagination: {
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listProduct: [],
    loadingList: false,
    listAllProductGroup: [],
    listAllTelesalesGroup: [],
    productSuggest: []
}

export const fetchListProduct = createAsyncThunk("PRODUCT.FETCH_LIST", async (params: IProductQuery, thunkApi) => {
    try {
        return await ProductApi.getListProduct(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetAllProductGroup = createAsyncThunk("PRODUCT.GET_ALL_PRODUCT_GROUP", async (_, thunkApi) => {
    try {
        return await ProductApi.getAllProductGroup()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetAllTelesalesGroup = createAsyncThunk("PRODUCT.GET_ALL_TELESALES_GROUP", async (_, thunkApi) => {
    try {
        return await TelesalesGroupApi.getAllTelesalesGroup()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetProductAutocomplete = createAsyncThunk("PRODUCT.GET_LIST_SUGGEST", async (params: {keyword: string}, thunkApi) => {
    try {
        return await ProductApi.getProductSuggest(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const productReducer = createReducer(initState, (builder) => {
    builder
        .addCase(fetchListProduct.pending, (state) => {
            state.loadingList = true
            state.listProduct = []
        })
        .addCase(fetchListProduct.fulfilled, (state, action) => {
            state.listProduct = action.payload.data ? action.payload.data : []
            state.pagination.current = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(fetchListProduct.rejected, (state) => {
            state.loadingList = false
            state.listProduct = []
        })
    builder
        .addCase(callGetAllProductGroup.pending, (state) => {
            state.listAllProductGroup = []
        })
        .addCase(callGetAllProductGroup.rejected, (state) => {
            state.listAllProductGroup = []
        })
        .addCase(callGetAllProductGroup.fulfilled, (state, action) => {
            state.listAllProductGroup = action.payload.data
        })
    builder
        .addCase(callGetAllTelesalesGroup.pending, (state) => {
            state.listAllTelesalesGroup = []
        })
        .addCase(callGetAllTelesalesGroup.rejected, (state) => {
            state.listAllTelesalesGroup = []
        })
        .addCase(callGetAllTelesalesGroup.fulfilled, (state, action) => {
            state.listAllTelesalesGroup = action.payload.data
        })
    builder
        .addCase(callGetProductAutocomplete.pending, (state) => {
            state.productSuggest = []
        })
        .addCase(callGetProductAutocomplete.rejected, (state) => {
            state.productSuggest = []
        })
        .addCase(callGetProductAutocomplete.fulfilled, (state, {payload}) => {
            state.productSuggest = payload?.data || []
        })
})
