import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

import { ITelesales, ITelesalesQuery } from "@domain/version2/Telesales"
import { TelesalesApi } from "@api/version2/TelesalesApi"
import { PAGINATION } from "@config/constant"

interface State {
    pagination: {
        total: number
        currentPage: number
        totalPage: number
        pageSize: number
    }
    listTelesales: ITelesales[]
    loadingTelesales: boolean
    loadingCreate: boolean
}

const initState: State = {
    pagination: {
        total: 0,
        currentPage: 0,
        totalPage: 0,
        pageSize: 0,
    },
    listTelesales: [],
    loadingTelesales: false,
    loadingCreate: false,
}

export const callGetListTelesales = createAsyncThunk("TELESALES.GET_LIST", async (params: ITelesalesQuery, thunkApi) => {
    try {
        return await TelesalesApi.getListTelesales(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callCreateTelesales = createAsyncThunk("TELESALES.CREATE", async (payload: { username: string }, thunkApi) => {
    try {
        return await TelesalesApi.createTelesales(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const telesalesReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetListTelesales.pending, (state) => {
            state.loadingTelesales = true
        })
        .addCase(callGetListTelesales.rejected, (state) => {
            state.loadingTelesales = false
        })
        .addCase(callGetListTelesales.fulfilled, (state, { payload }) => {
            state.loadingTelesales = false
            state.listTelesales = payload.data
            state.pagination.currentPage = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
        })
    builder
        .addCase(callCreateTelesales.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreateTelesales.rejected, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreateTelesales.fulfilled, (state) => {
            state.loadingCreate = false
        })
})
