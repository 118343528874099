import SecurityService from "@util/SecurityService"
import React from "react"
import { trans } from "../resources/localization"
import { COMBO_SCOPE, CONTACT_SCOPE, DELIVERY_CASE_SCOPE, ORDER_SCOPE, PRODUCT_SCOPE, REPORT_SCOPE, SETTING_SCOPE, TELESALES_COPE } from "./permission"

export interface IMenuItem {
    key: string
    title: string
    icon: JSX.Element
    url: string
    show: boolean
    children?: Array<IMenuItem>
}

export const menuItems: IMenuItem[] = [
    {
        key: "dashboard",
        title: trans("sidebar.dashboard"),
        icon: <i className="fas fa-home" />,
        url: "/",
        show: true,
    },
    {
        key: "product",
        title: trans("sidebar.product"),
        icon: <i className="fa-solid fa-cube" />,
        url: "/product",
        show:
            SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW_ALL) ||
            SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW) ||
            SecurityService.can(COMBO_SCOPE.COMBO_LIST_VIEW_ALL) ||
            SecurityService.can(COMBO_SCOPE.COMBO_LIST_VIEW),
    },
    {
        key: "team",
        title: trans("telesales.team"),
        icon: <i className="fa-solid fa-users"></i>,
        url: "/team",
        show: SecurityService.can(TELESALES_COPE.GROUP_VIEW_LIST) || SecurityService.can(TELESALES_COPE.TELESALES_VIEW_LIST),
    },
    //v2
    {
        key: "contact",
        title: trans("contact.title"),
        icon: <i className="fa-solid fa-address-card" />,
        url: "/contacts",
        show: SecurityService.can(CONTACT_SCOPE.CONTACT_LIST_VIEW) || SecurityService.can(CONTACT_SCOPE.CONTACT_LIST_VIEW_ALL),
    },
    {
        key: "order",
        title: trans("order.page_title"),
        icon: <i className="fa-solid fa-cart-shopping" />,
        url: "/orders",
        show: SecurityService.can(ORDER_SCOPE.ORDER_LIST_VIEW) || SecurityService.can(ORDER_SCOPE.ORDER_LIST_VIEW_ALL),
    },
    {
        key: "deliveryCase",
        title: trans("delivery_case.title"),
        icon: <i className="fa-solid fa-truck"></i>,
        url: "/delivery-case",
        show: SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_VIEW) || SecurityService.can(DELIVERY_CASE_SCOPE.DELIVERY_CASE_VIEW_ALL),
    },
    {
        key: "report",
        title: trans("report.title"),
        icon: <i className="fa-sharp fa-solid fa-file-chart-column"></i>,
        url: "/reports",
        show:
            SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW) ||
            SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW_ALL) ||
            SecurityService.can(REPORT_SCOPE.SLA_REPORT_VIEW) ||
            SecurityService.can(REPORT_SCOPE.SLA_REPORT_VIEW_ALL),
    },
    {
        key: "setting",
        title: trans("setting.title"),
        icon: <i className="fa-solid fa-gear"></i>,
        url: "/settings",
        show: SecurityService.can(SETTING_SCOPE.SETTING_CANCEL_ASSIGNMENT_VIEW) || SecurityService.can(SETTING_SCOPE.SETTING_SLA_VIEW),
    },
]
