import { trans } from "@resources/localization"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import React, { useEffect } from "react"
import DefaultLayout from "@component/Layout/Default"
import { Filter } from "./Filter"
import { TableDeliveryCase } from "./List"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { ImportDeliveryCase } from "./Import"
import { useSearchParams } from "react-router-dom"
import { callGetAssignee, callGetListDeliveryCase } from "@reducer/version2/delivery-case.reducer"
import { PAGINATION } from "@config/constant"
import { callGetDeliveryCaseReason, callGetDeliveryCaseStatus } from "@reducer/version2/categories.reducer"
import { getCountriesSuggest } from "@reducer/version2/countries.reducer"
import { callGetVendorSuggest } from "@reducer/version2/vendor.reducer"
import { callGetUserAutocomplete } from "@reducer/version2/user.reducer"

export const DeliveryCase = () => {
    const dispatch: any = useAppDispatch()
    const [searchParams] = useSearchParams()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("delivery_case.title")))
        dispatch(callGetDeliveryCaseStatus())
        dispatch(getCountriesSuggest())
        dispatch(callGetAssignee())
        dispatch(callGetDeliveryCaseReason())
        dispatch(callGetVendorSuggest({}))
        dispatch(callGetUserAutocomplete(''))
    }, [dispatch])
    useEffect(()=>{
        dispatch(callGetListDeliveryCase({
            ...Object.fromEntries(searchParams.entries()),
            pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
            sort: "createdAt:DESC",
        }))
    },[searchParams, dispatch])
    return (
        <DefaultLayout loading={false}>
            <div className="contact-page">
                <HeaderAntBreadCrumb
                    breadCrumbs={[
                        {
                            name: trans("sidebar.dashboard"),
                            link: "/",
                        },
                        {
                            name: trans("delivery_case.title"),
                            link: "/delivery-case",
                        },
                    ]}
                />
                <Filter />
                <TableDeliveryCase />
                <ImportDeliveryCase />
            </div>
        </DefaultLayout>
    )
}
