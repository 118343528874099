import apiClient from "@util/ApiClient"
import { ICombo, IGroupOfCombo, IPropertyOfCombo, ISkuComboDetail, ISkuComboParams, ISkuComboResponse } from "@domain/version2/SkuCombo"
import { AxiosResponse } from "axios"
export default class SkuComboApi {
    static getDetailSkuCombo(code: string): Promise<AxiosResponse<ISkuComboDetail>> {
        return apiClient.get(`combos/${code}`)
    }

    static getListComboAutocomplete(params: { keyword: string }): Promise<AxiosResponse<ICombo[]>> {
        return apiClient.get(`combos/autocomplete`, { params })
    }

    static getListCombo(params: ISkuComboParams): Promise<AxiosResponse<ISkuComboResponse[]>> {
        return apiClient.get(`combos`, { params })
    }

    static getPropertiesOfCombo(comboCode: string): Promise<AxiosResponse<IPropertyOfCombo[]>> {
        return apiClient.get(`combos/${comboCode}/properties`)
    }

    static getTelesalesGroupOfCombo(comboCode: string, params: { page: number; pageSize: number }): Promise<AxiosResponse<IGroupOfCombo[]>> {
        return apiClient.get(`combos/${comboCode}/telesales-groups`, { params })
    }

    static updateListTelesalesGroupOfCombo(comboCode: string, body: { groupCodes: string[] }): Promise<AxiosResponse<any>> {
        return apiClient.patch(`combos/${comboCode}/telesales-group`, body)
    }

    static deleteTelesalesGroupOfCombo(comboCode: string, body: { groupCodes: string[] }): Promise<AxiosResponse<any>> {
        return apiClient.delete(`combos/${comboCode}/telesales-group`, { data: body })
    }
}
