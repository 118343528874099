import { ICallLog, ICallLogQuery } from "@domain/version2/CallStringee";
import apiClient from "@util/ApiClient"

export default class CallStringeeService {
    static getAccessToken(params: { from_alias: string; tenant: string }): Promise<any> {
        return apiClient.get("calls/access-token", {
            params,
        })
    }
    static getListNumber(params: { tenant: string }): Promise<any> {
        return apiClient.get("calls/numbers", {
            params,
        })
    }
    static getListLogs(params: ICallLogQuery): Promise<{data: ICallLog[], headers: { [key: string]: any }}> {
        return apiClient.get("calls/logs", {
            params,
        })
    }
}
