import React, { useEffect } from "react"
import { Button, Card, Col, DatePicker, Form, Row, Select } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { useSearchParams } from "react-router-dom"
import { forEach, isEmpty, omitBy } from "lodash"
import moment from "moment"
import { FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { IReportQuery } from "@domain/version2/Report"
import { useAppDispatch } from "@hook/useAppDispatch"
import SecurityService from "@util/SecurityService"
import { callGetListLeaderSuggest, callGetTelesalesGroupsSuggest } from "@reducer/version2/telesales-group.reducer"
import { REPORT_SCOPE } from "@config/permission"

interface Props {
    currentTab: string
}

export const FilterReport: React.FC<Props> = ({ currentTab }) => {
    const [formRef] = Form.useForm()
    const countries = useAppSelector((state) => state.countriesVersion2.countriesSuggest)
    const telesalesGroups = useAppSelector((state) => state.telesalesGroup.telesalesGroupsSuggest)
    const telesalesSuggest = useAppSelector((state) => state.telesalesGroup.listLeader)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const userInfo = SecurityService.getUser()
    useEffect(() => {
        if(currentTab){
            dispatch(
                callGetTelesalesGroupsSuggest({
                    ...(!SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW_ALL) && currentTab === "call" && { hasTelesales: userInfo?.sub }),
                    ...(!SecurityService.can(REPORT_SCOPE.SLA_REPORT_VIEW_ALL) && currentTab === "telesales" && { hasTelesales: userInfo?.sub }),
                })
            )
            dispatch(
                callGetListLeaderSuggest({
                    username: "",
                    ...(!SecurityService.can(REPORT_SCOPE.CALL_REPORT_VIEW_ALL) && currentTab === "call" && { inSameGroupWith: userInfo?.sub }),
                    ...(!SecurityService.can(REPORT_SCOPE.SLA_REPORT_VIEW_ALL) && currentTab === "telesales" && { inSameGroupWith: userInfo?.sub }),
                })
            )
        }
    }, [currentTab])
    useEffect(() => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("countryCodes") && {
                    countryCodes: oldSearch?.countryCodes?.split(","),
                }),
                ...(searchParams.has("telesalesGroupCodes") && {
                    telesalesGroupCodes: oldSearch?.telesalesGroupCodes?.split(","),
                }),
                ...(searchParams.has("telesales") && {
                    telesales: oldSearch?.telesales?.split(","),
                }),
                ...(searchParams.has("fromDate") &&
                    searchParams.has("toDate") && {
                        createdAt: [moment(oldSearch?.fromDate), moment(oldSearch?.toDate)],
                    }),
            })
        }
    }, [dispatch, searchParams])
    const onFilterReport = (values: IReportQuery) => {
        setSearchParams(() => {
            let params: any = {
                ...values,
                tab: currentTab,
                fromDate: values?.createdAt ? moment(values?.createdAt[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
                toDate: values?.createdAt ? moment(values?.createdAt[1].format(FORMAT_DATE_END)).toISOString() : undefined,
                countryCodes: values?.countryCodes?.join(",") || undefined,
                telesalesGroupCodes: values?.telesalesGroupCodes?.join(",") || undefined,
                telesales: values?.telesales?.join(",") || undefined,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    return (
        <Card className="space-layout custom-bottom">
            <Form
                layout="vertical"
                onFinish={onFilterReport}
                form={formRef}>
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="countryCodes"
                            label={trans("contact.country")}>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder={trans("order_list.country")}>
                                {countries?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="telesalesGroupCodes"
                            label={trans("telesales.group")}>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder={trans("telesales.group_name_pl")}>
                                {telesalesGroups?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="telesales"
                            label={trans("telesales.title")}>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder={trans("contact.select_telesale")}>
                                {telesalesSuggest?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.username}>
                                        {item?.username}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            name="createdAt"
                            label={trans("order_list.created_at")}>
                            <DatePicker.RangePicker
                                allowClear={false}
                                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                placeholder={[trans("order_list.from"), trans("order_list.to")]}
                                className={"_filter_created_at w-full"}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col
                        span={24}
                        className={`form-filter-control filter-button`}>
                        <Button
                            type="primary"
                            className="mg-r-10 _filter_button_filter"
                            htmlType="submit">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            className="mg-r-10 _filter_button_clear"
                            onClick={() => {
                                formRef.resetFields()
                                formRef.setFieldsValue({
                                    telesales: [userInfo?.sub],
                                    createdAt: [moment().subtract(30, "days"), moment()],
                                })
                                const params = {
                                    fromDate: moment(moment().subtract(30, "days").format(FORMAT_DATE_BEGIN)).toISOString(),
                                    toDate: moment(moment().format(FORMAT_DATE_END)).toISOString(),
                                    telesales: userInfo?.sub,
                                    tab: currentTab,
                                }
                                setSearchParams(params)
                            }}>
                            {trans("contact.clear")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
