import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { InfoProduct } from "./InfoProduct"
import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import { VendorList } from "./VendorList"
import { PropertiesList } from "./PropertiesList"
import { ListGroups } from "./Groups"
import { useParams } from "react-router-dom"
import { callGetDetailProduct } from "@reducer/version2/product-detail"
import { AccessDenied } from "@component/AccessDenied"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE } from "@config/permission"
import { filter } from "lodash"
import { Notfound } from "@component/Notfound"

export const DetailProduct: React.FC = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const [statusShow, setStatusShow] = useState<number>(0)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("contact-detail.product_detail")))
    }, [dispatch])

    useEffect(() => {
        if (id) {
            dispatch(callGetDetailProduct(id)).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
        }
    }, [dispatch])

    const LIST_TAB = [
        { label: trans("telesales.group"), key: "group", children: <ListGroups />, disabled: !SecurityService.can(PRODUCT_SCOPE.PRODUCT_GROUP_VIEW) },
        { label: trans("sidebar.vendor"), key: "vendor", children: <VendorList />, disabled: false },
        { label: trans("products.properties"), key: "properties", children: <PropertiesList />, disabled: false },
    ]

    const PRODUCT_BREADCRUMB = [
        { name: trans("sidebar.dashboard"), link: "/" },
        { name: trans("products.product"), link: "/product" },
        { name: "contact-detail.product_detail" },
    ]

    return (
        <DefaultLayout loading={false}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb breadCrumbs={PRODUCT_BREADCRUMB} />
                    <InfoProduct />
                    <Tabs
                        className="nav-space nav-space-x"
                        defaultActiveKey={SecurityService.can(PRODUCT_SCOPE.PRODUCT_GROUP_VIEW) ? "group" : "vendor"}
                        items={filter(LIST_TAB, { disabled: false })}
                    />
                </>
            )}
        </DefaultLayout>
    )
}
