import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import ContactApi from "@api/version2/Contact"
import { IActivityHistory, IContactAds, IContactDetail, IContactUpdateRequest, IEvent } from "@domain/version2/ContactDetail"
import { IContactEventRequest } from "@domain/version2/ContactEvent"
import { IOrder } from "@domain/version2/Order"
import AdApi from "@api/version2/AdApi"

interface State {
    contactDetail: IContactDetail
    loading: boolean
    loadingUpdate: boolean
    listContactAds: IContactAds[]
    isShowAddEventForm: boolean
    listEvents: IEvent[]
    loadingEvents: boolean
    loadingCreateEvent: boolean
    adsOfContact: IContactAds[]
    listOrdersOfContact: IOrder[]
    activityHistories: IActivityHistory[]
    loadingActivity: boolean
    adSkus: Array<any>
    adSkusLoading: boolean
    loadingCancelAssignment: boolean
    loadingAssign: boolean
}

const initState: State = {
    contactDetail: {},
    loading: false,
    loadingUpdate: false,
    listContactAds: [],
    isShowAddEventForm: false,
    listEvents: [],
    loadingEvents: false,
    loadingCreateEvent: false,
    adsOfContact: [],
    listOrdersOfContact: [],
    activityHistories: [],
    loadingActivity: false,
    adSkus: [],
    adSkusLoading: false,
    loadingCancelAssignment: false,
    loadingAssign: false,
}

export const callGetContactDetail = createAsyncThunk("CONTACT_DETAIL", async (contactId: string, thunkApi) => {
    try {
        return await ContactApi.getContactDetail(contactId)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateInfoContact = createAsyncThunk(
    "CONTACT_DETAIL.UPDATE",
    async (payload: { contactId: string; body: IContactUpdateRequest }, thunkApi) => {
        try {
            return await ContactApi.updateContactDetail(payload.contactId, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callGetContactAds = createAsyncThunk("CONTACT_DETAIL.CONTACT_ADS", async (id: string, thunkApi) => {
    try {
        return await ContactApi.getContactAds(id)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetContactEvents = createAsyncThunk("CONTACT_DETAIL.GET_EVENT", async (id: string, thunkApi) => {
    try {
        return await ContactApi.getContactEvents(id)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreateContactEvent = createAsyncThunk("CONTACT_DETAIL.CREATE_EVENT", async (payload: { id: string; body: IContactEventRequest }, thunkApi) => {
    try {
        return await ContactApi.addContactEvent(payload.id, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callAdsOfContact = createAsyncThunk("CONTACT_DETAIL.GET_ADS", async (id: string, thunkApi) => {
    try {
        return await ContactApi.getAdsOfContact(id)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const changeAddEventFormVisible = createAction<boolean>("CONTACT_DETAIL.CHANGE_ADD_EVENT_FORM_VISIBLE")

export const callGetListOrdersOfContactInPeriod = createAsyncThunk("CONTACT_DETAIL.GET_LIST_ORDERS", async (contactId: string, thunkApi) => {
    try {
        return await ContactApi.getOrdersOfContactInPeriod(contactId)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetActivityHistory = createAsyncThunk("CONTACT_DETAIL.GET_ACTIVITY_HISTORY", async (contactId: string, thunkApi) => {
    try {
        return await ContactApi.getActivityHistory(contactId)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callResetAssignment = createAsyncThunk("CONTACT_DETAIL.RESET_ASSIGNMENT", async (contactId: string, thunkApi) => {
    try {
        return await ContactApi.resetAssignment(contactId)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callAssignContactToTelesales = createAsyncThunk(
    "CONTACT_DETAIL.ASSIGN_TO",
    async (payload: { code: string; body: { assignee: string } }, thunkApi) => {
        try {
            return await ContactApi.assignContactToTelesales(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const getAdSkusAction = createAsyncThunk("CONTACT_DETAIL.GET_AD_SKUS", async (payload: any, thunkApi) => {
    try {
        return await AdApi.getSkus(payload.adCode, {})
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const changeAdSkuAttributeAction = createAction<{ sku: string; attrKey: string; attrValue: any }>("CONTACT_DETAIL.CHANGE_AD_SKU_ATTRIBUTE")

export const contactDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetContactDetail.pending, (state) => {
            state.loading = true
            state.contactDetail = {}
        })
        .addCase(callGetContactDetail.fulfilled, (state, { payload }) => {
            state.contactDetail = payload.data || {}
            state.loading = false
        })
        .addCase(callGetContactDetail.rejected, (state) => {
            state.loading = false
        })
    builder
        .addCase(callUpdateInfoContact.pending, (state) => {
            state.loadingUpdate = true
            state.contactDetail = {}
        })
        .addCase(callUpdateInfoContact.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateInfoContact.rejected, (state) => {
            state.loadingUpdate = false
        })
    builder
        .addCase(callGetContactAds.pending, (state) => {
            state.listContactAds = []
        })
        .addCase(callGetContactAds.fulfilled, (state, { payload }) => {
            state.listContactAds = payload.data
        })
        .addCase(callGetContactAds.rejected, (state) => {
            state.listContactAds = []
        })
    builder
        .addCase(callGetContactEvents.pending, (state) => {
            state.loadingEvents = true
            state.listEvents = []
        })
        .addCase(callGetContactEvents.fulfilled, (state, { payload }) => {
            state.loadingEvents = false
            state.listEvents = payload.data
        })
        .addCase(callGetContactEvents.rejected, (state) => {
            state.loadingEvents = false
            state.listEvents = []
        })
    builder
        .addCase(callCreateContactEvent.pending, (state) => {
            state.loadingCreateEvent = true
        })
        .addCase(callCreateContactEvent.fulfilled, (state) => {
            state.loadingCreateEvent = false
        })
        .addCase(callCreateContactEvent.rejected, (state) => {
            state.loadingCreateEvent = false
        })
    builder
        .addCase(callAdsOfContact.pending, (state) => {
            state.adsOfContact = []
        })
        .addCase(callAdsOfContact.fulfilled, (state, { payload }) => {
            state.adsOfContact = payload?.data
        })
        .addCase(callAdsOfContact.rejected, (state) => {
            state.adsOfContact = []
        })
    builder.addCase(changeAddEventFormVisible, (state, action: PayloadAction<boolean>) => {
        state.isShowAddEventForm = action.payload
    })

    builder
        .addCase(callGetListOrdersOfContactInPeriod.pending, (state) => {
            state.listOrdersOfContact = []
        })
        .addCase(callGetListOrdersOfContactInPeriod.fulfilled, (state, { payload }) => {
            state.listOrdersOfContact = payload?.data
        })
        .addCase(callGetListOrdersOfContactInPeriod.rejected, (state) => {
            state.listOrdersOfContact = []
        })
    builder
        .addCase(callGetActivityHistory.pending, (state) => {
            state.loadingActivity = true
            state.activityHistories = []
        })
        .addCase(callGetActivityHistory.fulfilled, (state, { payload }) => {
            state.loadingActivity = false
            state.activityHistories = payload?.data
        })
        .addCase(callGetActivityHistory.rejected, (state) => {
            state.loadingActivity = false
            state.activityHistories = []
        })
    builder
        .addCase(callResetAssignment.pending, (state) => {
            state.loadingCancelAssignment = true
        })
        .addCase(callResetAssignment.fulfilled, (state) => {
            state.loadingCancelAssignment = false
        })
        .addCase(callResetAssignment.rejected, (state) => {
            state.loadingCancelAssignment = false
        })
    builder
        .addCase(callAssignContactToTelesales.pending, (state) => {
            state.loadingAssign = true
        })
        .addCase(callAssignContactToTelesales.fulfilled, (state) => {
            state.loadingAssign = false
        })
        .addCase(callAssignContactToTelesales.rejected, (state) => {
            state.loadingAssign = false
        })

    builder
        .addCase(getAdSkusAction.pending, (state) => {
            state.adSkusLoading = true
            state.adSkus = []
        })
        .addCase(getAdSkusAction.fulfilled, (state, { payload }) => {
            state.adSkusLoading = false
            state.adSkus = payload?.data
        })
        .addCase(getAdSkusAction.rejected, (state) => {
            state.adSkusLoading = false
            state.adSkus = []
        })

    builder.addCase(changeAdSkuAttributeAction, (state, action) => {
        state.adSkus = state.adSkus.map((x) => {
            if (x.sku?.sku === action.payload.sku) {
                x[action.payload.attrKey] = action.payload.attrValue
            }

            return x
        })
    })
})
