import React, { useEffect } from "react"

import { Button, Col, Form, Input, message, Modal, Row, Space } from "antd"

import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import { trans } from "@resources/localization"
import { callGetCallStatus, callGetOrderPaymentMethods } from "@reducer/version2/categories.reducer"
import { GeneralForm } from "./GeneralCreate"
import { AdsCreate } from "./AdsCreate"
import { ReasonAndOther } from "./ReasonAndOther"
import { AppointmentDate } from "./AppointmentDate"
import { AddressCreate } from "./AddressCreate"
import { PricePolicy } from "./PricePolicy"
import { PaymentMethod } from "./PaymentMethod"
import lodash, { find, first, isEmpty } from "lodash"
import {
    callCreateContactEvent,
    callGetContactAds,
    callGetContactDetail,
    callGetContactEvents,
    callGetListOrdersOfContactInPeriod,
    changeAddEventFormVisible,
} from "@reducer/version2/contact-detail.reducer"
import { getCountriesSuggest, getCourierSuggest, getProvinceSuggest, resetDataArea, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { TablePricePolicy } from "../TablePricePolicy"
import { IPricePolicy } from "@domain/version2/ContactDetail"
import moment from "moment"
import { LIST_CURRENCY } from "@config/constant"
import { AddListSkuCombo } from "./AddListSkuCombo"
import { ListComboSku } from "../ListComboSku"

export const FormAddEvent = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const isShowAddEventForm = useAppSelector((state) => state.contactDetailVersion2.isShowAddEventForm)
    const detailContact = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    const listAds = useAppSelector((state) => state.contactDetailVersion2.listContactAds)
    const loadingAddEvent = useAppSelector((state) => state.contactDetailVersion2.loadingCreateEvent)
    const adSkus = useAppSelector((state) => state.contactDetailVersion2.adSkus)
    const paymentMethods = useAppSelector((state) => state.categoriesVersion2.orderPaymentMethods)

    useEffect(() => {
        dispatch(callGetCallStatus())
        dispatch(getCountriesSuggest())
        dispatch(callGetOrderPaymentMethods())
    }, [dispatch])

    useEffect(() => {
        if (!isEmpty(detailContact)) {
            dispatch(callGetContactAds(detailContact?.id || ""))
            dispatch(
                getCourierSuggest({
                    vendorCode: detailContact?.vendor,
                })
            )
            dispatch(getProvinceSuggest(detailContact?.country?.code || ""))
        }
    }, [detailContact])

    const renderInfo = (key: string, value: any) => {
        return (
            <div>
                <p className="font-medium m-0">{key}:</p>
                <p>{value}</p>
            </div>
        )
    }
    const prePaidAmountRegex = (value?: any) => (`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","))

    const popupPaymentConfirm = (values: any) => {
        const method = values?.method
        return (
            <>
                <hr className="mb-3" />
                <Row gutter={20}>
                    <Col span={8}>
                        {renderInfo(trans("order_payment.method"), values?.method ? find(paymentMethods, { code: values.method })?.name : "")}
                    </Col>
                    {["pre_paid_by_cash", "pre_paid_by_bank_transfer"].indexOf(method) >= 0 && (
                        <>
                            <Col span={8}>{renderInfo(trans("order_payment.pre_paid_amount"), prePaidAmountRegex(values.prePaidAmount))}</Col>
                            <Col span={8}>
                                {renderInfo(
                                    trans("order_payment.date"),
                                    values?.paymentTime ? moment(values?.paymentTime).format("DD/MM/YYYY") : undefined
                                )}
                            </Col>
                            {["pre_paid_by_bank_transfer"].indexOf(method) >= 0 && (
                                <>
                                    <Col span={16}>{renderInfo(trans("order_payment.bank"), values.bankName)}</Col>
                                    <Col span={8}>{renderInfo(trans("order_payment.bank_account_number"), values.bankAccount)}</Col>
                                </>
                            )}
                            <Col span={24}>{renderInfo(trans("order_payment.note"), values.notePayment)}</Col>
                        </>
                    )}
                </Row>
            </>
        )
    }
    const onCreateContactEvent = (values: any) => {
        Modal.confirm({
            title: <span className="text-red-500">{trans("order_payment.confirmation")}</span>,
            icon: false,
            content: popupPaymentConfirm(values),
            width: 600,
            onOk() {
                const dataRequest: any = {
                    status: values?.status || undefined,
                    note: values?.noteOther || values?.note,
                    scheduleDate: values?.scheduleDate ? moment(values?.scheduleDate).toISOString() : undefined,
                    ad: values?.ads || undefined,
                    end: values?.end || false,
                    reason: values?.reason || undefined,
                    ...(["reservation", "agree"]?.includes(values?.status) && {
                        orderData: {
                            address: values?.address || undefined,
                            note: values?.note || undefined,
                            postalCode: values?.postalCode || undefined,
                            items: !isEmpty(values?.pricePolicies)
                                ? values?.pricePolicies?.map((item: any) => ({
                                      sku: item?.property,
                                      price: Number(item?.unitPrice),
                                      quantity: Number(item?.quantity),
                                  }))
                                : [],
                            crossOrderItems: adSkus?.map((x: any) => {
                                return {
                                    sku: lodash.get(x, "sku.sku"),
                                    price: lodash.get(x, "price"),
                                    quantity: lodash.get(x, "quantity"),
                                }
                            }),
                            combos:
                                values?.combos?.map((item: any) => ({
                                    sku: item?.sku,
                                    price: item?.unitPrice,
                                    quantity: item?.quantity,
                                })) || [],
                            country: detailContact?.country?.code || undefined,
                            province: values?.provinceCode || undefined,
                            city: values?.cityCode || undefined,
                            district: values?.districtCode || undefined,
                            ward: values?.wardCode || undefined,
                            courierService: values?.courier || undefined,
                            deliveryDate: values?.deliveryDate ? moment(values?.deliveryDate).toISOString() : undefined,
                            deliveryFee: Number(values?.deliveryFee) || undefined,
                            totalPayment: Number(values?.totalPayment) || undefined,
                            confirmations: {
                                method: values?.method || undefined,
                                prePaidAmount: Number(values?.prePaidAmount) || undefined,
                                bankName: values?.bankName || undefined,
                                bankAccount: values?.bankAccount || undefined,
                                paymentTime: values?.paymentTime ? moment(values?.paymentTime).toISOString() : undefined,
                                note: values?.notePayment || undefined,
                            },
                        },
                    }),
                }
                dispatch(
                    callCreateContactEvent({
                        id: detailContact?.id || "",
                        body: dataRequest,
                    })
                ).then((result: any) => {
                    if (result?.payload?.status === 201) {
                        message.success(trans("message.success"))
                        formRef.resetFields()
                        dispatch(resetDataDistrict([]))
                        dispatch(resetDataArea([]))
                        dispatch(callGetContactDetail(detailContact?.id || ""))
                        dispatch(callGetContactEvents(detailContact?.id || ""))
                        dispatch(callGetListOrdersOfContactInPeriod(detailContact?.id || ""))
                        dispatch(changeAddEventFormVisible(false))
                    } else {
                        message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                    }
                })
            },
        })
    }

    return (
        <Modal
            width={860}
            title={trans("contactDetail.addEventModalTitle")}
            open={isShowAddEventForm}
            onCancel={() => {
                formRef.resetFields()
                dispatch(resetDataDistrict([]))
                dispatch(resetDataArea([]))
                dispatch(changeAddEventFormVisible(false))
            }}
            footer={null}
            destroyOnClose={true}>
            <Form
                layout="vertical"
                form={formRef}
                onFinish={onCreateContactEvent}>
                <GeneralForm formRef={formRef} />
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) => pre.status !== next.status}>
                    {({ getFieldValue }) => {
                        const currentStatus = getFieldValue("status")
                        return (
                            <>
                                {["declined", "consider", "agree", "reservation"].indexOf(currentStatus) >= 0 && (
                                    <>
                                        <AdsCreate formRef={formRef} />
                                        {["declined"].indexOf(currentStatus) >= 0 && <ReasonAndOther />}
                                    </>
                                )}
                                {["consider", "not_answer", "answer_but_busy"].indexOf(currentStatus) >= 0 && <AppointmentDate />}
                                {!(["declined"].indexOf(currentStatus) >= 0) && (
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label={trans("contact-detail.event_note")}
                                                name="note">
                                                <Input.TextArea
                                                    rows={4}
                                                    placeholder={trans("contact-detail.event_note")}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                {["agree", "reservation"].indexOf(currentStatus) >= 0 && (
                                    <>
                                        <AddressCreate formRef={formRef} />
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(pre, next) => pre.ads !== next.ads}>
                                            {({ getFieldValue }) => {
                                                const itemAds = find(listAds, { code: getFieldValue("ads") })
                                                console.log("itemAds", itemAds)
                                                const currentPrice: IPricePolicy[] = find(listAds, { code: getFieldValue("ads") })?.pricePolicies || []
                                                if (itemAds?.campaign?.productCode) {
                                                    return (
                                                        <>
                                                            <TablePricePolicy
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                                dataTable={currentPrice}
                                                            />
                                                            <PricePolicy
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                                listSkus={currentPrice}
                                                                form={formRef}
                                                            />
                                                        </>
                                                    )
                                                }
                                                if (itemAds?.campaign?.comboCode) {
                                                    return (
                                                        <>
                                                            <ListComboSku
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                                listPricePolicy={first(currentPrice)?.wholeSalePrice}
                                                            />
                                                            <AddListSkuCombo
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                            />
                                                        </>
                                                    )
                                                }
                                                return null
                                            }}
                                        </Form.Item>
                                        <PaymentMethod formRef={formRef} />
                                    </>
                                )}
                            </>
                        )
                    }}
                </Form.Item>
                <Row
                    justify="end"
                    className="mt-3">
                    <Space>
                        <Button
                            type="default"
                            loading={loadingAddEvent}
                            onClick={() => {
                                formRef.resetFields()
                                dispatch(resetDataDistrict([]))
                                dispatch(resetDataArea([]))
                                dispatch(changeAddEventFormVisible(false))
                            }}>
                            {trans("setting.cancel")}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={loadingAddEvent}
                            loading={loadingAddEvent}>
                            {trans("setting.save")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
